<template>
  <div class="row col q-pt-none q-pl-none column">
    <div class="row q-mx-xs">
      <job-offers-filter
          ref="filterComponent"
          class="col-8" @filter-values="receiveFilterOffersEvent"
          @observed="filterObserved"
      ></job-offers-filter>
    </div>
    <div class="row col q-pb-none">
      <div class="q-pl-xs q-mb-sm q-pr-none q-pt-none q-pb-none col row q-scrollarea" style="overflow-y: auto">
        <div v-if="!isReady || noResultFound" class="col row column q-mr-sm">
          <q-card v-if="noResultFound" style="border-radius: 9px" class="q-mb-md q-mt-xs">
            <q-card-section class="row q-pl-sm q-py-sm q-pr-sm">
              <div class="col-1 q-mr-lg">
                <q-skeleton square style="border-radius: 7px;height: 70px;width:70px" type="QAvatar"
                            class="text-center q-pl-none">
                  <q-spinner-ios
                      class="q-mt-sm q-mr-xs"
                      color="primary"
                      size="50px"
                  />
                </q-skeleton>
              </div>
              <div class="col q-mt-md">
                <my-text style="font-size: 13px" class="text-grey-9 q-ma-none">
                  Nie znaleziono ofert dla podanych kryteriów - sprawdź resztę naszych
                  <q-chip style="padding-top: 3px; border-radius: 7px"
                          class="q-ml-none q-px-sm q-pt-sm q-pl-xs text-primary"
                          clickable
                          flat
                          color="white"
                          label="ogłoszeń"
                          @click="noResultFound = false"
                  >
                  </q-chip>
                  lub
                  <q-chip @click="showFilterDialog()" no-caps style="padding-top: 3px; border-radius: 7px;"
                          class="q-ml-none q-px-sm q-pt-sm q-pl-xs text-primary"
                          clickable
                          flat
                          color="white" label="zmień kryteria">
                  </q-chip>
                </my-text>
              </div>

            </q-card-section>
            <div>
              <q-separator></q-separator>
            </div>
            <div class="row justify-between">
              <q-card-actions align="left" class="q-gutter-md">
                <q-skeleton type="QBtn" width="65px" height="25px"/>
                <q-skeleton type="QBtn" width="65px" height="25px"/>
                <q-skeleton type="QBtn" width="65px" height="25px"/>
                <q-skeleton type="QBtn" width="65px" height="25px"/>
                <q-skeleton type="QBtn" width="65px" height="25px"/>
                <q-skeleton type="QBtn" width="65px" height="25px"/>
              </q-card-actions>
              <q-card-actions align="left" class="q-gutter-md">
                <q-skeleton type="QBtn" width="65px" height="25px"/>
              </q-card-actions>
            </div>
          </q-card>
          <card-skeleton class="q-mr-xs q-ml-none q-mt-sm col row column"></card-skeleton>
          <card-skeleton class="q-mr-xs q-ml-none q-mt-sm col row column"></card-skeleton>
          <card-skeleton class="q-mr-xs q-ml-none q-mt-sm col row column"></card-skeleton>
          <card-skeleton class="q-mr-xs q-ml-none q-mt-sm col row column"></card-skeleton>
          <card-skeleton class="q-mr-xs q-ml-none q-mt-sm col row column"></card-skeleton>
          <card-skeleton class="q-mr-xs q-ml-none q-mt-sm col row column"></card-skeleton>
        </div>
        <div v-else ref="scrollTargetRef" class="col row column q-mt-xs q-pr-xs" style="overflow-y: auto;">
          <q-infinite-scroll v-if="isReady" :key="resetCounter" ref="infinitiveScroll"
                             @load="fetchOffers" :debounce="100" :offset="0"
                             :scroll-target="scrollTargetRef"
                             style="overflow-y: auto"
                             class="q-mr-none q-ml-none q-mt-none col row column">
            <div v-for="jobOffer in offers" :key="jobOffer.id" class="q-mr-xs"
                 :scroll-target="scrollTargetRef">
              <mobile-offer-overview
                  style="max-height: 100px"
                  :job-offer="jobOffer"
                  :is-fav="isFavForLoggedUser(jobOffer.id)"
                  :is-selected="!offerFromUrlNotFound && selectedJobOffer.id === jobOffer.id && shouldShowHighlightedOverview"
                  @jobOffer:id="value => updateCurrentJobOffer(value)"
                  @fav:remove="id => removeFav(id)"
                  @fav:add="id => addFav(id)"
                  class="bg-white">
              </mobile-offer-overview>
            </div>
          </q-infinite-scroll>
        </div>
      </div>

      <q-dialog maximized v-model=jobOfferDetailsDialog>
        <div
            class="q-mt-xs q-pb-sm col row column q-mx-xs full-height"
            style="max-width: 100%">
          <job-offer-details-skeleton
              class="q-px-md col"
              v-if="isLoading || noResultFound"
          >
          </job-offer-details-skeleton>
          <mobile-job-offer-details
              v-else-if="!offerFromUrlNotFound"
              id="jobOfferDetailsRef"
              @closeJobOfferDetailsDialog="jobOfferDetailsDialog.value = false"
              ref="jobOfferDetailsRef"
              class="col row column"
              :show-apply="true"
              :padding-top="0"
              :job-offer="selectedJobOffer">
          </mobile-job-offer-details>
          <q-card v-else class="q-mx-md q-mt-xs" style="border-radius: 9px">
            <q-card-section>
              <q-icon color="red-5" class="q-mr-md" size="lg" name="fa-solid fa-triangle-exclamation"></q-icon>
              <span style="font-size: 15px" class="text-grey-9">Ofert wygasła lub została usunięta. Wybierz inną ofertę z listy.</span>
            </q-card-section>
          </q-card>
        </div>
      </q-dialog>
    </div>
  </div>
</template>


<script setup>
import {onMounted, ref,} from 'vue';
import JobOfferService from "@/services/JobOfferService";
import JobOffersFilter from "@/components/common/job-offers/job-offers-filter/JobOffersFilter";
import ApplicantObservedOffers from "@/services/applicant/ApplicantObservedOffers";

const filterComponent = ref(null)
const shouldShowHighlightedOverview = ref(true)
const isLoading = ref(true)
const scrollTargetRef = ref(null)
const offers = ref([])
const currentNumberOfElements = ref(null)
const totalNumberOfElements = ref(null)
const infinitiveScroll = ref();
const currentPage = ref(0);
const resetCounter = ref(0);
const noResultFound = ref(false)
const sorting = ref('DEFAULT')
const isOnlyObserved = ref(false)
const offerFromUrlNotFound = ref(false)
const jobOfferDetailsDialog = ref(false)
onMounted(() => {
  initComponentData();
  fetchOffers()
})
import {useRouter} from 'vue-router';

const router = useRouter()

function filterObserved(isObserved) {
  resetInfiniteScroll()
  isOnlyObserved.value = isObserved
}

function fetchOffers(page, done) {
  if (globalParams.value === null) {
    let params = new URLSearchParams({})
    params.append('page', 0)
    //TODO - test maximum pagge size that do not affect performance
    params.append('size', 100000)
    params.append('sort', sorting.value)
    globalParams.value = params
  }
  //TODO fix fetching - inifinitive loop
  if ((currentNumberOfElements.value === null || currentNumberOfElements.value < totalNumberOfElements.value) && totalNumberOfElements.value !== 0) {
    globalParams.value.delete('page')
    globalParams.value.append('page', currentPage.value)
    JobOfferService.getAllJobOffers(globalParams.value).then(response => {
      return response.json().then((data) => {
        if (data.content.length === 0) {
          isLoading.value = true
          if (noResultFound.value === false) {
            noResultFound.value = true;
            receiveFilterOffersEvent([], [], [], [], [], 0, 40000, [], [], [], 'DEFAULT', [])
          }
          done()
        } else {
          if (isOnlyObserved.value) {
            totalNumberOfElements.value = observedOffersId.value.length
            offers.value.push(...data.content.filter(obj => observedOffersId.value.map(obj => obj.id).includes(obj.id)));
          } else {
            totalNumberOfElements.value = data.totalElements;
            offers.value.push(...data.content)
          }
          currentNumberOfElements.value = offers.value.length;
          currentPage.value = currentPage.value + 1;
          if (isOfferIdFromUrl()) {
            selectedJobOffer.value = findJobOfferById(router.currentRoute.value.params.id);
            const offerIndex = offers.value.findIndex(offer => offer.id === selectedJobOffer.value.id);
            if (offerIndex !== -1) {
              offers.value.splice(offerIndex, 1);
            }
            offers.value.unshift(selectedJobOffer.value);
            OfferCounterService.increaseOfferView(selectedJobOffer.value.id);
          } else {
            selectedJobOffer.value = offers.value[0]
          }
          isLoading.value = false
          isReady.value = true;

          done();
        }
      }).catch((err) => {
        console.log(err);
      })
    })
  } else {
    done();
    isLoading.value = false
  }


}

function clearJobOffers() {
  offers.value = []
  currentPage.value = 0;
}

const globalParams = ref(null)

function receiveFilterOffersEvent(jobPositions,
                                  skills,
                                  seniorityLevels,
                                  operatingModes,
                                  contractTypes,
                                  salaryFrom,
                                  salaryTo,
                                  locations,
                                  languages,
                                  industries,
                                  sorting,
                                  workTypes
) {
  let params = new URLSearchParams({})
  params.append('page', 0)
  params.append('size', 50)
  if (sorting !== 'Obserwowane') {
    params.append('sort', sorting)
  }
  if (skills) {
    params.append('skills', concatenateWithComma(skills));
  }

  if (workTypes) {
    params.append('workTypes', concatenateWithComma(workTypes))
  }

  if (jobPositions) {
    params.append('jobPositions', concatenateWithComma(jobPositions));
  }

  if (seniorityLevels) {
    params.append('seniorityLevels', concatenateWithComma(seniorityLevels));
  }

  if (contractTypes) {
    params.append('contractTypes', concatenateWithComma(contractTypes));
  }

  if (locations) {
    params.append('cities', concatenateWithComma(locations));
  }

  if (languages) {
    params.append('languages', concatenateWithComma(languages));
  }

  if (salaryFrom != null) {
    params.append('salaryFrom', salaryFrom);
  }

  if (salaryTo != null) {
    params.append('salaryTo', salaryTo);
  }

  if (operatingModes) {
    params.append('operatingModes', concatenateWithComma(operatingModes))
  }

  if (industries) {
    params.append('organizationIndustries', concatenateWithComma(industries))
  }
  globalParams.value = params
  currentNumberOfElements.value = null
  resetInfiniteScroll()
}

function concatenateWithComma(arr) {
  return arr.join(",");
}

function resetInfiniteScroll() {
  // noResultFound.value = false;
  resetCounter.value = resetCounter.value + 1;
  // Reset the infinite scroll state
  isLoading.value = true;
  if (infinitiveScroll.value) {
    infinitiveScroll.value.reset();
  }
  // Clear the offers and other related variables
  clearJobOffers();
  currentNumberOfElements.value = null;
  totalNumberOfElements.value = null;
  // Fetch the initial set of offers
  currentPage.value = 0
}

const selectedJobOffer = ref(null);
const jobOfferDetailsRef = ref(null)

function updateCurrentJobOffer(value) {
  shouldShowHighlightedOverview.value = true
  offerFromUrlNotFound.value = false
  selectedJobOffer.value = findJobOfferById(value);
  jobOfferDetailsDialog.value = true
}

function findJobOfferById(id) {
  return offers.value.find(job => job.id === id);
}

const isLoggedUser = ref(false)
const observedOffersId = ref([])
import cookieService from "@/services/common/CookieService";

const isReady = ref(false)

import AuthenticationService from "@/services/AuthenticationService";
import CardSkeleton from "@/components/common/job-offers/skeletons/JobOfferOverviewSkeleton";
import MobileOfferOverview from "@/components/mobile/MobileOfferOverview";
import JobOfferDetailsSkeleton from "@/components/common/job-offers/skeletons/JobOfferDetailsSkeleton";
import MobileJobOfferDetails from "@/components/mobile/MobileJobOfferDetails";
import OfferCounterService from "@/services/offer/OfferCounterService";
// import JobOfferDetailsSkeleton from "@/components/common/job-offers/skeletons/JobOfferDetailsSkeleton";

function initComponentData() {
  isReady.value = false;
  AuthenticationService.getAuthenticatedUser().then(response => {
    if (response.ok) {
      response.json().then(user => {
        isLoggedUser.value = true;
        ApplicantObservedOffers.getObservedOffers(user.id)
            .then(response => {
              if (response.status === 200) {
                response.json().then(body => {
                  observedOffersId.value = body.map(item => ({id: item.id}));
                })
              }
            });
      });
    } else {
      initObservedCookieIfNeeded();
    }
  });
}


function isOfferIdFromUrl() {
  let id = router.currentRoute.value.params.id;
  return id !== undefined && id !== null && isNotBlank(id);
}

function isNotBlank(str) {
  if (str === null || str === undefined) {
    return false;
  }

  // Remove leading and trailing spaces using trim()
  const trimmedStr = str.trim();

  // Check if the resulting string is empty or not
  return trimmedStr !== '';
}

function initObservedCookieIfNeeded() {
  let observedOffersIdCookie = cookieService.getCookieValue('observed-offers-id')
  if (observedOffersIdCookie !== null && !isLoggedUser.value) {
    const arrayOfValues = observedOffersIdCookie.split(',');
    observedOffersId.value = arrayOfValues.map(id => ({id: id}))
  }
}

function isFavForLoggedUser(jobOfferId) {
  //todo here is infinitive loop
  return observedOffersId.value.some(object => object.id === jobOfferId);
}

function removeFav(jobOfferId) {
  observedOffersId.value = observedOffersId.value.filter(object => object.id !== jobOfferId);
  let observedOffersIdCookie = cookieService.getCookieValue('observed-offers-id')
  if (observedOffersIdCookie !== null && !isLoggedUser.value) {
    const arrayOfValues = observedOffersIdCookie.split(',');
    const filteredArray = arrayOfValues.filter(id => id !== jobOfferId);
    const newValuesString = filteredArray.join(',');
    cookieService.setCookie("observed-offers-id", newValuesString, 30);
  }
}

function addFav(jobOfferId) {
  let foundOffer = offers.value.find(offer => offer.id === jobOfferId);
  observedOffersId.value.push({id: foundOffer.id});
  if (!isLoggedUser.value) {
    let observedOffersIdCookie = cookieService.getCookieValue('observed-offers-id')
    if (observedOffersIdCookie !== null && observedOffersIdCookie.trim() !== '') {
      cookieService.setCookie("observed-offers-id", observedOffersIdCookie + ',' + jobOfferId, 30)
    } else {
      cookieService.setCookie("observed-offers-id", jobOfferId, 30)
    }
  }
}

function showFilterDialog() {
  noResultFound.value = false;
  filterComponent.value.showFilterDialog()
}

</script>

<style lang="scss" scoped>
.apexcharts-tooltip {
  background-color: #80207b;
  color: $secondary;
  font-size: 12px;
}

.apexcharts-tooltip-title {
  background-color: #80207b;
  color: white;
  font-size: 12px;
}

.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background: $secondary !important;
}

.input-select {
  width: 150px;
  margin: 0;
  padding: 0
}

.q-field__native, .q-field__prefix, .q-field__suffix, .q-field__input {
  color: $secondary !important;
}

.q-field--auto-height.q-field--dense .q-field__control, .q-field--auto-height.q-field--dense .q-field__native {
  max-height: 34px;
  min-height: 10px;
  border-radius: 8px;
}


::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-button {
  background: #ffffff
}

::-webkit-scrollbar-track-piece {
  background: #ffffff
}

::-webkit-scrollbar-thumb {
  background: #8e959a
}

</style>

