<template>
  <div id="scrollArea" ref="scrollArea" :style="{ paddingTop: paddingTop}"
       class="q-scrollarea col row q-px-xs full-width column" style="overflow-y: auto;min-height: 600px">
    <div class="full-width" :class="justifyContent" style="backdrop-filter: blur(10px);">
      <intro-card
          :title="props.jobOffer.title"
          :contracts="getContracts()"
          :company-name="props.jobOffer.organization.name"
          :logo="props.jobOffer.organization.logoUrl"
          :logo-in-base64="props.logoInBase64"
          :web-page-url="props.jobOffer.organization.webPageUrl"
          :instagram-page-url="props.jobOffer.organization.instagramPageUrl"
          :facebook-page-url="props.jobOffer.organization.facebookPageUrl"
          :job-position="props.jobOffer.jobPosition"
          :seniority-level-name="props.jobOffer.seniority"
          :operating-modes="props.jobOffer.operatingModes"
          :work-types="props.jobOffer.workTypes"
          :id="props.jobOffer.id"
          :color-branding-available="props.jobOffer.colorBrandingAvailable"
          :branding="props.jobOffer.organization.branding"
      >
      </intro-card>
      <requirements-card class="q-pt-md" :job="props.jobOffer" :skills="props.jobOffer.skills"/>
      <description class="q-pt-md" :description="props.jobOffer.description"></description>
      <organization-description
          v-if="props.jobOffer.organization.description"
          class="q-pt-md"
          :description="props.jobOffer.organization.description">
      </organization-description>
      <similar-offers v-if="showApply"
                      class="q-pt-md"
                      :job-offer-id="props.jobOffer.id">
      </similar-offers>
    </div>
  </div>
  <div class=" q-px-xs" v-if="showApply">
    <q-card bordered
            class="q-pa-sm row justify-between q-mr-xs"
            style="border-radius: 7px; border-color: lightgray; border-width: 1px">
      <q-btn flat icon="fa-solid fa-circle-left" @click="closeDetailsModal()">

      </q-btn>
      <div>
        <q-btn style="width: 8vh; border-radius: 6px"
               size="md"
               align="center"
               dense
               label="Aplikuj"
               outline
               class=" text-accent q-px-sm q-ml-sm" @click="toggleUserCard()"/>
      </div>
      <div class="q-pr-sm q-pt-xs">
        <q-chip size="12px"
                square
                text-color="primary"
                color="white"
                class="q-ma-none bg-white text-grey-9">
          Ważna jeszcze 25 dni
        </q-chip>
      </div>
    </q-card>
  </div>
  <q-dialog v-model="showUserCard">
    <job-application :job-offer-id="jobOffer.id" :position="jobOffer.title" :questions="jobOffer.questions"
                     @close-modal="closeModal()"></job-application>
  </q-dialog>
  <q-dialog v-model="showApplyConfirmationDialog">
    <q-card style="border-radius: 9px">
      <q-card-section class="row">
        <div class="text-center">
          <q-icon color="positive" name="fa-solid fa-share-from-square" size="30px"/>
        </div>
        <span class="q-ml-md q-pt-xs text-grey-9" style="font-weight: 300; font-size: 13px">Twoje CV zostało wysłane</span>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script setup>
import {ref, defineProps, defineExpose, onMounted, defineEmits} from 'vue';
import Description from "@/components/common/job-offers/job-offer-details/Description";
import IntroCard from "@/components/common/job-offers/job-offer-details/intro-card/IntroCard";
import RequirementsCard from "@/components/common/job-offers/job-offer-details/RequirementsCard";
import OrganizationDescription from "@/components/common/job-offers/job-offer-details/OrganizationDescription";
import JobApplication from "@/components/common/job-offers/applications/JobApplication";
import SimilarOffers from "@/components/common/job-offers/SimilarOffers";
// import ApplicationSentConfirmation from "@/components/organization/applications/ApplicationSentConfirmation";

const showApplyConfirmationDialog = ref(false)

function showAndHideApplyingConfirmationDialog() {
  showApplyConfirmationDialog.value = true;
  setTimeout(() => {
    showApplyConfirmationDialog.value = false;
  }, 2300);
}

const props = defineProps({
  maxHeight: Number,
  jobOffer: Object,
  showApply: Boolean,
  logoInBase64: String,
  paddingTop: {
    type: String,
    default: '50px',
  },
  justifyContent: {
    type: String,
    default: 'justify-center',
  },
});
onMounted(()=>{
})
const showUserCard = ref(false);
// TODO IMPROVE IN DIFFERENT STORY
const toggleUserCard = () => {
  if (props.jobOffer.jobApplicationInfo.applicationType === 'EXTERNAL') {
    window.open(props.jobOffer.jobApplicationInfo.externalApplicationLink, '_blank')
  } else {
    showUserCard.value = !showUserCard.value;
  }
};

function closeModal() {
  showUserCard.value = false;
  showAndHideApplyingConfirmationDialog();
}

import ScrollUtils from "@/services/common/ScrollUtils";

function scrollToTop() {
  // this.$refs.scrollArea.scrollTop = 0;
  ScrollUtils.scrollToTop('scrollArea')
}

defineExpose({
  scrollToTop
})

function getContracts() {
  if (props.jobOffer.publication === null || props.jobOffer.publication === undefined) {
    return props.jobOffer.contracts;
  } else {
    return props.jobOffer.publication.contracts;
  }
}

const emit = defineEmits(['closeJobOfferDetailsDialog'])

function closeDetailsModal(){
  emit('closeJobOfferDetailsDialog')
}
</script>

<style lang="scss" scoped>
.q-chip {
  background: linear-gradient(45deg, $primary, $primary)
}

.q-footer {
  background: linear-gradient(45deg, $secondary, $primary)
}

my-text {
  color: $accent
}


.q-chip {
  margin-left: 0px;
}

.fixed-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.fixedElementTop {
  background-color: rgb(226,231,239);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

.fixedElementBottom {
  background-color: rgb(226,231,239);
  //position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100;
}

html, body {
  background-color: rgb(226,231,239);
  height: 100vh;
}

.scrollable-area {
  height: auto;
  overflow-y: auto;
}


.bg-gradient {
  background: linear-gradient(20deg, $primary, $light-blue-11); /* Use your primary and accent colors here */
  color: white; /* Text color */
}


::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-button {
  background: #ffffff
}

::-webkit-scrollbar-track-piece {
  background: #ffffff
}

::-webkit-scrollbar-thumb {
  background: #8e959a
}
</style>