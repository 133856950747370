<template>
  <q-layout>
    <q-page-container>
      <q-page style="background-color: rgb(217,225,238);">
        <menu-bar class="q-pl-sm q-pr-sm"></menu-bar>
        <div class="login-center">
          <q-card style="width: 400px; border-radius: 9px">
            <q-card-section class="row justify-center">
              <my-text class="text-center text-primary q-mb-none" color="white"
                       style="font-weight: 500; font-size: 18px">
                Szukaj pracowników z ByteJob
                <!--          <q-icon class="q-ml-sm" name="fa-solid fa-people-group" size="23px"></q-icon>-->
              </my-text>
            </q-card-section>
            <q-form class="q-mx-sm" @submit.prevent="login()" greedy>
              <q-card-section class="q-pb-none q-mb-none q-pt-sm">
                <q-input
                    dense
                    outlined
                    v-model="email"
                    label="E-mail pracowniczy"
                    id="email"
                    :rules="[val => !!val || 'E-mail jest wymagany']"
                ></q-input>
                <q-input
                    outlined
                    color="primary"
                    type="password"
                    dense
                    class="q-pt-sm"
                    v-model="password"
                    label="Hasło"
                    id="password"
                    :rules="[val => !!val || 'Hasło jest wymagane']"
                ></q-input>
                <q-card-section class="q-pt-none q-pb-xs">
                  <div class="text-center q-pt-none q-pb-none"> <!-- Add the text-center class to this div -->
                    <q-btn size="11px" flat color="accent" @click="router.push({ name: 'OrganizationRegistration' });"
                           icon="diversity_3"
                           class="q-pt-none"
                           no-caps
                           label="Nie masz konta? - Dołącz"/>
                  </div>
                </q-card-section>
              </q-card-section>
              <q-card-actions class="text-center q-pl-md q-pr-md q-pt-sm q-gutter-md" align="center">
                <q-btn outline style="border-radius: 7px" padding="4px xl" type="submit" color="primary" no-caps
                       label="Logowanie" :loading="loading"></q-btn>
              </q-card-actions>
              <q-card-actions class="text-center q-pt-none" align="center">
                <div class="text-center q-ma-none q-pa-none"> <!-- Add the text-center class to this div -->
                  <q-btn size="11px" flat color="primary" @click="router.push({ name: 'OrganizationPasswordReset' });"
                         no-caps
                         label="Resetuj hasło"/>
                </div>
              </q-card-actions>
            </q-form>
          </q-card>
        </div>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script setup>
import AuthenticationService from '@/services/AuthenticationService';
import MenuBar from "@/components/common/MenuBar";
import {useQuasar} from 'quasar'
import {ref} from 'vue'
import {useRouter} from "vue-router";
import {currentUser} from "@/global-variables/currentUser";

const $q = useQuasar();
const router = useRouter()
const email = ref('')
const password = ref('')
const loading = ref(false)

async function login() {
  loading.value = true;
  try {
    const response = await AuthenticationService.loginToOrganization(email.value, password.value);
    if (response.status === 201) {
      localStorage.setItem("isAuthenticated", "true")
      const json = await response.json();
      Object.assign(currentUser, json);
      let redirectPath = sessionStorage.getItem('redirectPath');
      if (redirectPath) {
        router.push(redirectPath)
        sessionStorage.removeItem('redirectPath');
      } else {
        router.push({name: 'OrganizationJobOffersBoard'});
      }
    } else if (response.status === 404) {
      $q.notify({
        type: 'negative',
        message: 'Błędny login lub hasło.'
      })
    } else if (response.status === 401) {
      const errorCode = await response.json().then(body => {
        return body.errorCode
      })
      if (errorCode === 'ACCOUNT_TEMPORARILY_LOCKED') {
        $q.notify({
          type: 'negative',
          message: 'Konto zablokowane na 5 minut - zbyt wiele błędnych prób logowania.'
        })
      } else {
        $q.notify({
          type: 'negative',
          message: 'Błędny login lub hasło.'
        })
      }
    }
  } catch (error) {
    console.error('Error during login:', error);
    throw error; // or handle error as you see fit
  } finally {
    loading.value = false;
  }
}

</script>
<style>
.login-center {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -30%);
}

</style>