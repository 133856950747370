<template>
  <div
      style="min-width: 168px; max-width: 168px;min-height: 168px; max-height: 168px">
    <q-card v-ripple class="cursor-pointer q-hoverable full-width full-height"
            style="border-radius: 9px; min-width: 168px; max-width: 168px;min-height: 168px; max-height: 168px;"
            @mouseover="handleMouseOver"
            @mouseout="handleMouseOut"
            @click="changeAvatar()">
      <div v-if="StringUtils.isStringEmpty(organizationData.logoUrl)"
           style="min-width: 168px; max-width: 168px;min-height: 168px; max-height: 168px">
        <q-card-section v-if="isMouseOnCard" class="text-center">
          <q-icon name="add_photo_alternate" size="100px" class="text-secondary q-mt-sm"></q-icon>
        </q-card-section>
        <q-card-section v-else class="text-center">
          <q-icon name="add_photo_alternate" size="100px" class="text-grey-7 q-mt-sm"></q-icon>
        </q-card-section>
      </div>
      <div v-if="!StringUtils.isStringEmpty(organizationData.logoUrl)" class="q-pa-none full-width full-height"
           style=" display: flex;
  flex-direction: column;
  justify-content: center;  /* Centering y-axis */
  align-items :center">
        <q-img
            style="border-radius: 9px;"
            :ratio="4/4"
            :key="logoUrl"
            :src="logoUrl"
            fit="contain"/>
      </div>
      <span class="q-focus-helper"></span>
    </q-card>
  </div>
</template>

<script setup>


import {computed, defineEmits, defineProps, onMounted, ref} from "vue";
import NotificationService from "@/services/notification/NotificationService";
import {useQuasar} from "quasar";
import OrganizationDataService from "@/services/organization/OrganizationDataService";
import StringUtils from "@/services/common/StringUtils";

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  formTemplateData: Object,
  modelValue: Object
})

const organizationData = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val),
});

const logoUrl = ref(null);
const isMouseOnCard = ref(false)

function handleMouseOver() {
  isMouseOnCard.value = true;
}

function handleMouseOut() {
  isMouseOnCard.value = false;
}

onMounted(() => {
  logoUrl.value = organizationData.value.logoUrl
})

const $q = useQuasar()

function changeAvatar() {
  let input = document.createElement('input');
  input.type = 'file';
  input.accept = '.jpg, .jpeg, .png, .gif, .webp, .svg'; // Specify accepted file extensions
  input.onchange = () => {
    let files = Array.from(input.files);
    if (files.length > 0) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = () => {
        var parts = reader.result.split(',');
        let base64Logo = parts[1].trim(); //
        if (file.size > 5 * 1024 * 1024) {
          NotificationService.showNotification($q, 'negative', 'Maksymalny rozmiar pliku - 5 MB.');
        } else {
          OrganizationDataService.changeLogo(base64Logo).then(response => {
            if (!response.ok) {
              NotificationService.showNotification($q, 'negative', 'Błąd podczas dodawania pliku - skontaktuj sie z supportem.');
            } else {
              response.json().then(body => {
                console.info(body.logoUrl)
                logoUrl.value = body.logoUrl
              })
            }
          })
        }
      };
      reader.readAsDataURL(file);
    }
  }
  input.click();
}

</script>