<template>
  <div class="q-pa-xs col row column">
    <div class="q-mb-md row q-px-md q-mt-md full-width justify-between">
      <div class="q-gutter-sm">
        <q-btn class="bg-white" :class="getColorForIsActiveButton()" label="Aktywne" no-caps
               @click="fetchAccounts(true)"></q-btn>
        <q-btn class="bg-white" :class="getColorForIsInactiveButton()" label="Nieaktywne" @click="fetchAccounts(false)"
               no-caps></q-btn>
      </div>
      <organization-account-create @add:user="fetchAccounts(showActive.value)"></organization-account-create>
    </div>
    <div class="full-width q-scrollarea col q-px-sm q-mb-md"
         style="overflow-y: auto; display: flex; justify-content: center;">
      <div class="q-ma-md" style="width: 70%;"> <!-- Adjust width as needed -->
        <organization-account-overview
            class="q-ma-md"
            v-for="(account, index) in accounts"
            :key="account.id"
            @account:deactivate="removeDeactivated"
            v-model:account="accounts[index]"
            @admin:pass="(a,b) => showAndHidePassAdminConfirmationDialog(a,b)"
        ></organization-account-overview>
      </div>
    </div>
    <q-dialog v-model="passAdminConfirmationDialog">
      <q-card style="width: 300px; border-radius: 9px;">
        <q-card-section class="row justify-center q-px-md q-pt-md q-pb-md">
          <div class="text-h7 text-grey-9 text-center">
            <div class="q-pt-none q-pb-sm q-mb-none text-center">
              <q-icon size="md" name="fa-regular fa-circle-question" color="positive" class=""></q-icon>
            </div>
            <div>
              <span class="text-center" style="font-size: 13px"> <p class="q-ml-sm q-mb-none text-grey-8">Czy na pewno chcesz przekazać uprawnienia admina do <span
                  style="color: #3bb6c4;font-size: 13px">{{ passAdminTo }}</span>? Potwierdzając wyrażasz zgodę na
              wylogowanie.</p></span>
            </div>
          </div>
        </q-card-section>
        <div class="text-center q-mb-md">
          <q-btn @click="passAdmin()" style="border-radius: 7px"
                 class="q-px-md q-mr-sm" dense label="Ok"
                 no-caps
                 color="primary"></q-btn>
          <q-btn @click="passAdminConfirmationDialog = false" outline style="border-radius: 7px"
                 v-close-popup
                 class="q-px-md" dense label="Powrót"
                 no-caps
                 color="primary"></q-btn>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import OrganizationAccountOverview from "@/components/organization/organization-accounts/OrganizationAccountOverview";
import OrganizationUsersService from "@/services/organization/OrganizationUsersService";
import OrganizationAccountCreate from "@/components/organization/organization-accounts/OrganizationAccountCreate";
import {useRouter} from "vue-router/dist/vue-router";

const showActive = ref(true)
const accounts = ref([]);
const selectedAccounts = ref({});
const passAdminTo = ref('');

// Fetch and populate the accounts
onMounted(() => {
  fetchAccounts(showActive.value);
});

function getColorForIsActiveButton() {
  if (showActive.value) {
    return 'text-accent'
  } else {
    return 'text-grey-9'
  }
}

function getColorForIsInactiveButton() {
  if (showActive.value) {
    return 'text-grey-9'
  } else {
    return 'text-accent'
  }
}

function fetchAccounts(isActive) {
  showActive.value = isActive
  OrganizationUsersService.getUsersWithCookie().then((data) => {
    if (isActive) {
      accounts.value = data.content.filter(account => account.active === true && account.emailVerified === true);
    } else {
      accounts.value = data.content.filter(account => account.active === false && account.emailVerified === true || account.active === true && account.emailVerified === false);
    }
    accounts.value.forEach(account => {
      selectedAccounts.value[account.id] = false;
    });
  });
}

function removeDeactivated(id) {
  accounts.value = accounts.value.filter(acc => acc.id !== id);
}

const passAdminConfirmationDialog = ref(false);
const passAdminUserId = ref(null)

function showAndHidePassAdminConfirmationDialog(email, id) {
  passAdminTo.value = email
  passAdminUserId.value = id
  passAdminConfirmationDialog.value = true;
}

const router = useRouter()

function passAdmin() {
  OrganizationUsersService.passAdmin(passAdminUserId.value)
  router.push({name: 'OrganizationLoginPage'});
}
</script>

<style scoped>
</style>
