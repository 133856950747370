<template>
  <q-btn class="q-mb-sm" size="sm" color="primary" flat label="zmień hasło"
         @click="passwordDialog = true"
         icon-right="key"></q-btn>
  <q-dialog v-model="passwordDialog">
    <q-card style="width: 350px; border-radius: 9px">
      <q-form @submit.prevent="goToPasswordConfirmation" class="q-gutter-md" greedy>
        <div class="text-center q-pt-sm">
          <q-icon class="q-pb-xs q-mr-sm" size="xs" color="primary" name="fa-solid fa-key"></q-icon>
          <span class="text-primary" style="font-size: 20px">Zmiana hasła</span>
        </div>
        <q-card-section class="q-px-md q-mb-none q-pt-none">
          <q-input
              class="q-mb-none"
              dense
              outlined
              rounded
              type="password"
              color="secondary"
              v-model="oldPassword"
              label="Aktualne hasło"
              :rules="[val => val.length > 6 || 'Podaj aktualne hasło']"
          ></q-input>
          <div class="q-mx-sm q-mb-lg q-mt-none q-pt-none">
            <q-separator></q-separator>
          </div>
          <q-input
              dense
              outlined
              rounded
              color="secondary"
              type="password"
              v-model="newPassword"
              label="Nowe hasło"
              :rules="[val => val.length > 8 || 'Hasło musi zawierać przynajmniej 8 znaków',
              val => val.length < 20  || 'Hasło musi mieć mniej niż 20 znaków',
              val => /[A-Z]/.test(val)  || 'Hasło musi zawierać wielką literę',
              val => /[a-z]/.test(val)  || 'Hasło musi zawierać małą literę',
              val => /[!@#$%^&*]/.test(val)  || 'Hasło musi zawierać znak specjalny',
              val => /[0-9]/.test(val)  || 'Hasło musi zawierać cyfrę']"

          ></q-input>
          <q-input
              dense
              outlined
              rounded
              color="secondary"
              type="password"
              v-model="confirmPassword"
              label="Potwierdź hasło"
              :rules="[val => val.length > 8 || 'Hasło musi zawierać przynajmniej 8 znaków',
              val => val.length < 20  || 'Hasło musi mieć mniej niż 20 znaków',
              val => /[A-Z]/.test(val)  || 'Hasło musi zawierać wielką literę',
              val => /[a-z]/.test(val)  || 'Hasło musi zawierać małą literę',
              val => /[!@#$%^&*]/.test(val)  || 'Hasło musi zawierać znak specjalny',
              val => /[0-9]/.test(val)  || 'Hasło musi zawierać cyfrę',
              val => newPassword === val || 'Hasła się różnią']"

          ></q-input>
          <div class="row q-mx-xs q-mt-sm justify-center">
            <q-btn type="submit" dense class="q-mr-sm q-px-md" label="Kontynuuj" no-caps color="primary"/>
            <q-btn dense outline class="q-ml-sm q-px-md" label="Anuluj" no-caps color="negative" @click="closeDialog"/>
          </div>
        </q-card-section>
      </q-form>
    </q-card>
  </q-dialog>
  <q-dialog v-model="changePasswordConfirmationDialog">
    <q-card style="width: 350px; border-radius: 9px">
      <q-card-section class="text-center q-pb-xs">
        <div class="row justify-center q-mr-md q-mb-sm">
          <div class="text-center text-h6 text-primary">Zmiana hasła</div>
        </div>
        <div>
          <q-separator></q-separator>
        </div>
        <div class="q-ml-sm q-mt-md text-grey-8" style="font-size: 14px">Po zmianie hasła zostaniesz wylogowany z konta.
          Czy chcesz kontynuować?
        </div>
      </q-card-section>
      <q-card-section class="q-pa-md">
        <div class="row q-mx-xs q-mt-md justify-center">
          <q-btn dense class="q-mr-sm q-px-md" label="Kontynuuj" no-caps color="primary"
                 @click="confirmAndChangePassword"/>
          <q-btn dense outline class="q-ml-sm q-px-md" label="Anuluj" no-caps color="negative" @click="closeDialog"/>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script setup>
import {useQuasar} from 'quasar'
import {defineProps, ref} from 'vue'
import OrganizationUsersService from "@/services/organization/OrganizationUsersService";
import AuthenticationService from "@/services/AuthenticationService";
import {useRouter} from "vue-router/dist/vue-router";
import ApplicantDataService from "@/services/applicant/ApplicantDataService";

const $q = useQuasar()

const props = defineProps({
  user: Object,
})

const passwordDialog = ref(false)

async function confirmAndChangePassword() {
  let isApplicantUser = await AuthenticationService.isAuthenticatedApplicantUser();
  if (isApplicantUser) {
    ApplicantDataService.changePassword(props.user.id, oldPassword.value, newPassword.value).then((response) => {
      if (response.ok) {
        passwordDialog.value = false;
        changePasswordConfirmationDialog.value = false;
        logout()
        $q.notify({
          color: 'positive',
          textColor: 'white',
          icon: 'cloud_done',
          message: 'Hasło zostało zmienione'
        })
      } else {
        $q.notify({
          color: 'red-4',
          textColor: 'white',
          icon: 'cloud_done',
          message: 'Podaj poprawne aktualne hasło'
        })
        passwordDialog.value = true;
        newPassword.value = "";
        oldPassword.value = ""
        confirmPassword.value = "";
      }
    })
  } else {
    OrganizationUsersService.changePassword(props.user.id, oldPassword.value, newPassword.value).then((response) => {
      if (response.ok) {
        passwordDialog.value = false;
        changePasswordConfirmationDialog.value = false;
        logout()
        $q.notify({
          color: 'positive',
          textColor: 'white',
          icon: 'cloud_done',
          message: 'Hasło zostało zmienione'
        })
      } else {
        $q.notify({
          color: 'red-4',
          textColor: 'white',
          icon: 'cloud_done',
          message: 'Podaj poprawne aktualne hasło'
        })
        passwordDialog.value = true;
        newPassword.value = "";
        oldPassword.value = ""
        confirmPassword.value = "";
      }
    })
  }
}

//password change
const changePasswordConfirmationDialog = ref(false);
const oldPassword = ref("");
const newPassword = ref("");
const confirmPassword = ref("");

// const openDialog = () => {
//   changePasswordDialog.value = true;
// };

const closeDialog = () => {
  passwordDialog.value = false
  changePasswordConfirmationDialog.value = false;
  newPassword.value = "";
  oldPassword.value = ""
  confirmPassword.value = "";
};

const goToPasswordConfirmation = () => {
  passwordDialog.value = false;
  changePasswordConfirmationDialog.value = true;
};


function logout() {
  AuthenticationService.logout();
  localStorage.clear();
  toJobOffers()
}

const router = useRouter()

function toJobOffers() {
  location.reload()
  router.push({name: 'JobOffersMainBoard'})
}

</script>
