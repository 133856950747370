<template>
  <q-card class="row justify-between" style="border-radius: 9px;">
    <q-card-section class="row q-pr-xs col">
      <div class="col" style="display: flex; align-items: center;">
        <span style="font-size: 12px" class="text-primary q-mr-xs">Nr:</span>
        <span style="font-size: 12px" class="text-primary q-mr-md"><strong>{{
            props.invoice.number
          }}</strong></span>
        <q-chip outline
                class="text-primary q-ma-none"
                size="sm"
                :label="getPriceLabel()"
                style="border-radius: 5px"
        >
        </q-chip>
      </div>
      <div class="col text-center" >
        <span style="font-size: 12px" class="text-grey-9 q-mr-sm">Data płatności:</span>
        <span>{{ invoice.paymentDate }}</span>
      </div>
    </q-card-section>
    <q-card-section class="q-gutter-sm q-pr-sm col-4">
      <div class="row justify-end">
        <q-btn class="q-pa-sm q-pr-md" size="11px" dense no-caps color="primary" flat label="Pobierz" icon="fa-solid fa-file-export"
               @click="OrganizationInvoiceService.downloadInvoice(props.invoice.id)"
        ></q-btn>
      </div>
    </q-card-section>
  </q-card>
</template>


<script setup>
import {defineProps, onMounted} from 'vue';
// import {useQuasar} from "quasar";
import OrganizationInvoiceService from "@/services/organization/payments/OrganizationInvoiceService";

onMounted(() => {
})


const props = defineProps({
  invoice: Object
})


// const $q = useQuasar();

function getPriceLabel() {
  return props.invoice.grossPrice.amount + " " + props.invoice.grossPrice.currency
}
</script>


<style lang="scss" scoped>
.my-card {
  width: 100%
}

.avatar-border {
  border: 2px solid $primary;
}
</style>




