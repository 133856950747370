<template>
  <menu-bar class="q-pl-sm q-pr-sm"></menu-bar>
  <div class="login-center" v-if="successfulPasswordSetup">
    <q-card style="width: 350px;border-radius: 9px">
      <q-card-section class="row justify-center q-pb-none">
        <div class="q-pt-none q-pb-sm">
          <q-icon size="xl" name="task_alt" color="positive"></q-icon>
        </div>
        <div class="text-center text-h7 text-grey-9">Pomyślna rejestracja - przejdź do strony logowania
        </div>
      </q-card-section>
      <q-card-section class="text-center">
        <q-btn outline no-caps padding="3px xl" type="submit" color="primary" label="Ok"
               @click="router.push({ name: 'OrganizationLoginPage' });"></q-btn>
      </q-card-section>
    </q-card>
  </div>
  <div class="login-center" v-else>
    <q-card style="width: 350px; border-radius: 9px">
      <q-card-section class="row justify-center q-pb-sm">
        <div class="q-pt-xs q-pr-sm">
          <q-icon color="primary" size="24px" name="sym_o_person"></q-icon>
        </div>
        <div class="text-center text-h6 text-grey-10 q-pr-md">Dokończ rejestrację</div>
      </q-card-section>
      <q-form @submit="submitForm()" greedy>
        <q-card-section class="q-pb-xs">
          <q-input
              dense
              outlined
              rounded
              color="primary"
              type="password"
              v-model="password"
              label="Hasło"
              id="companyName"
              :rules="[val => val.length > 8 || 'Hasło musi zawierać przynajmniej 8 znaków',
              val => val.length < 20  || 'Hasło musi mieć mniej niż 20 znaków',
              val => /[A-Z]/.test(val)  || 'Hasło musi zawierać wielką literę',
              val => /[a-z]/.test(val)  || 'Hasło musi zawierać małą literę',
              val => /[!@#$%^&*]/.test(val)  || 'Hasło musi zawierać znak specjalny',
              val => /[0-9]/.test(val)  || 'Hasło musi zawierać cyfrę']"

          ></q-input>
          <div class="text-center q-pt-none q-pb-none"> <!-- Add the text-center class to this div -->
            <q-btn size="13px" flat color="secondary" @click="router.push({ name: 'OrganizationLoginPage' });"
                   icon="login"
                   label="Masz konto? - Zaloguj się"
                   no-caps/>
          </div>
        </q-card-section>
        <q-card-actions class="text-center q-pl-md q-pr-md q-pb-md q-pt-sm" align="center">
          <q-btn no-caps padding="3px xl" type="submit" color="primary" label="Dołącz"></q-btn>
        </q-card-actions>
      </q-form>
    </q-card>
  </div>
</template>

<script setup>
import RegistrationService from '@/services/RegistrationService';
import MenuBar from "@/components/common/MenuBar";
import {ref} from 'vue'

import {useQuasar} from 'quasar'
import {useRouter} from "vue-router";

const $q = useQuasar();
const router = useRouter()
const password = ref('')
const successfulPasswordSetup = ref(false)

async function submitForm() {
  const organizationId = router.currentRoute.value.query.organizationId;
  const userId = router.currentRoute.value.query.userId;
  const token = router.currentRoute.value.query.token;

  const response = await RegistrationService.confirmOrganizationRegistration(organizationId, userId, token, password.value);
  if (response.status === 200) {
    successfulPasswordSetup.value = true;
  } else if (response.status === 400) {
    $q.notify({
      type: 'negative',
      message: 'Link weryfikacyjny wygasł lub rejestracja została już potwierdzona.'
    })
  }
}
</script>
<style>
.login-center {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -30%);
}
</style>