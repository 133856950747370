<template>
  <div v-if="step === 1" class="text-center">
      <p class="text-h7 q-mt-md text-grey-8 q-mb-md text-bold">OFERTA</p>
      <p class="text-h5 text-grey-10">Znajdź pracownika z Byte Job. Dodaj ogłoszenie już od <span class="text-bold text-accent">0zł</span></p>
    <p class="text-h7 q-mt-md text-grey-8 q-mt-sm q-mb-sm">Zyskaj dostęp do systemu rekrutacyjnego za darmo</p>
  </div>
  <div id="topDiv" class="row justify-center q-mb-lg scrollableDiv" v-if="formTemplateData !== null"
  :class="calculatedTopMarginAndPadding()">
    <q-form @submit="showConfirmationDialogForPaymentIfNeeded()" greedy :style="calculateWidthBasedOnStep()">
      <q-stepper
          v-model="step"
          header-nav
          ref="stepper"
          color="primary"
          animated
          style="border-radius: 9px"
          class="my-stepper q-mb-lg">
        <q-step
            :name="1"
            title="Wybierz ofertę"
            active-color="accent"
            icon="settings"
            :done="step > 1"
            :header-nav="step > 1"
        >
          <!--          <span class="text-grey-9">Do czasu zakończenia promocji oferty ogłoszeń można dodawać za darmo.</span>-->
          <div class="row justify-between q-mx-md">
            <plan-basic @step:next="nextStep()"
                        :plan="getBasicPlan()"
                        class="q-my-lg"
                        v-model:plan-for-offer="jobOfferData.plan"
                        style="border-width: 1px"
            >
            </plan-basic>

            <plan-basic @step:next="nextStep()"
                        :type="'Standard'"
                        :plan="getStadardPlan()"
                        class="q-my-lg"
                        style="border-width: 1px"
                        v-model:plan-for-offer="jobOfferData.plan">
            </plan-basic>

            <plan-basic @step:next="nextStep()"
                        :type="'Premium'"
                        :plan="getPremiumPlan()"
                        class="q-my-lg"
                        style="border-width: 1px"
                        v-model:plan-for-offer="jobOfferData.plan">

            </plan-basic>
          </div>
        </q-step>

        <q-step
            :name="2"
            active-color="accent"
            title="Dane ogłoszenia"
            icon="fa-regular fa-file-lines"
            :done="step > 2"
            :header-nav="step > 2"
        >
          <job-offer-form :form-template-data="formTemplateData"
                          v-model="jobOfferData"
                          @step:next="nextStep"
                          @step:previous="previousStep"
                          :show-buttons="true"
          >

          </job-offer-form>
        </q-step>
        <q-step
            :name="3"
            title="Podgląd"
            active-color="accent"
            icon="fa-regular fa-eye"
            :done="step > 3"
            :header-nav="step > 3"
        >
          <div id="navigationBar"></div>
          <adding-offer-section-description class="q-mb-lg" title="Podgląd"
                                            description=" Twojego ogłoszenia. Jeśli chcesz poprawić wybrane dane, wróć do poprzedniego kroku."></adding-offer-section-description>
          <div
              class="col row column">
            <job-offer-details
                :is-copy-link-available="false"
                class="col row column"
                :show-apply="false"
                :padding-top="0"
                :done="step > 4"
                :job-offer="copiedJobOfferUiRepresentation">
            </job-offer-details>
          </div>
          <q-stepper-navigation class="q-pt-none">
            <div class="q-my-md">
              <q-separator></q-separator>
            </div>
            <q-btn color="accent" no-caps dense class="q-px-md"
                   label="Dalej"
                   @click="nextStep()"/>
            <q-btn flat @click="previousStep()" color="primary" no-caps dense label="Powrót" class="q-ml-md"/>
          </q-stepper-navigation>
        </q-step>
        <q-step
            :name="4"
            title="Płatność"
            active-color="accent"
            icon="fa-regular fa-wallet"
            :header-nav="step > 4"
        >
          <div class="col row q-mb-md full-width">
            <job-offer-payment
                ref="paymentDataRef"
                :plan="jobOfferData.plan"
                :is-logged-organization-user="true"
                v-model:payment="jobOfferData.payment">
            </job-offer-payment>
          </div>
          <q-stepper-navigation class="q-pt-none">
            <div class="q-mb-md">
              <q-separator></q-separator>
            </div>
            <q-btn type="submit" color="accent" no-caps dense outline class="q-px-md" label="Dodaj ogłoszenie"/>
            <q-btn flat @click="previousStep()" color="primary" no-caps dense label="Powrót" class="q-ml-md"/>
          </q-stepper-navigation>
        </q-step>
      </q-stepper>
      <q-dialog v-model="showPaymentConfirmationDialog">
        <q-card style="width: 300px; border-radius: 9px;">
          <q-card-section class="row justify-center q-px-md q-pt-md q-pb-md">
            <div class="text-h7 text-grey-9 text-center">
              <div class="q-pt-none q-pb-sm q-mb-none text-center">
                <q-icon size="md" name="fa-regular fa-circle-question" color="green-12" class=""></q-icon>
              </div>
              <div>
                <span class="text-center" style="font-size: 13px">Twoja oferta zostanie zapisana.</span>
              </div>
              <div>
                <span class="text-center" style="font-size: 13px">Zapłać za ofertę teraz lub później w portalu pracodawcy, aby była widoczna na tablicy.</span>
              </div>
            </div>
          </q-card-section>
          <div class="q-mb-md row justify-center">
            <div>
              <q-btn @click="addOfferAndGoToPrzelewy()" style="border-radius: 7px"
                     class="q-pa-sm q-mr-sm" dense label="Przejdź do Przelewy24"
                     no-caps
                     size="sm"
                     color="primary"></q-btn>
            </div>
            <div>
              <q-btn @click="addOfferAndGoToMainBoard('Zapisano ofertę')" outline style="border-radius: 7px"
                     class="q-pa-sm" dense label="Zapłać później"
                     no-caps
                     size="sm"
                     color="primary"></q-btn>
            </div>

          </div>
        </q-card>
      </q-dialog>
    </q-form>
  </div>
</template>

<script setup>
import {onMounted, reactive, ref} from "vue"
import JobOfferForm from "@/components/common/add-offer/job-offer/JobOfferForm";
import _ from 'lodash';
import OfferDataTranslator from "@/services/organization/offer/OfferDataTranslator";
import {useRouter} from "vue-router/dist/vue-router";
import OrganizatioOfferManagementService from "@/services/organization/offer/OrganizatioOfferManagementService";
import JobOfferPlansService from "@/services/organization/offer/JobOfferPlansService";
import PlanBasic from "@/components/common/add-offer/plans/PlanBasic";
import JobOfferService from "@/services/JobOfferService";
import JobOfferSkillsTranslator from "@/services/offer/JobOfferSkillsTranslator";
import LocationTranslator from "@/services/LocationTranslator";
import JobOfferDetails from "@/components/common/job-offers/JobOfferDetails";
import JobOfferPayment from "@/components/organization/job-offers/adding/JobOfferPayment";
import organizationService from "@/services/organization/OrganizationDataService";
import JobPositionTranslator from "@/services/offer/JobPositionTranslator";
import ScrollUtils from "@/services/common/ScrollUtils";
import AddingOfferSectionDescription from "@/components/common/add-offer/AddingOfferSectionDescription";
import NotificationService from "@/services/notification/NotificationService";
import {useQuasar} from "quasar";
import JsonBlankFieldsCleaner from "@/services/common/JsonBlankFieldsCleaner";

const router = useRouter()
const $q = useQuasar()

onMounted(() => {
  getFormTemplateData().then(data => {
    formTemplateData.value = data;
  });

  organizationService.getOrganizationInfo().then(response => response.json().then(body => {
    jobOfferData.organization = body;
    //TODO rename to name or company name on API level
    jobOfferData.organization.companyName = body.name
  }))
  let clonedJobOfferId = router.currentRoute.value.params.id;
  if (clonedJobOfferId) {
    JobOfferService.getJobOffer(clonedJobOfferId).then(
        response => {
          response.json().then(jobOffer => {
            jobOfferData.organization.companyName = jobOffer.organization.name
            jobOfferData.title = jobOffer.title;
            jobOfferData.jobPosition = jobOffer.jobPosition;
            jobOfferData.contracts = jobOffer.publication.contracts
            OfferDataTranslator.translateContractsToPolish(jobOffer.publication.contracts);
            jobOfferData.description = jobOffer.description
            jobOfferData.questions.forEach(question => {
              question.answerType = OfferDataTranslator.translateAnswerTypeToPolish(question.answerType);
            });
            jobOfferData.seniority = OfferDataTranslator.translateFromSeniorityToPolish(jobOffer.seniority);
            jobOfferData.languages = OfferDataTranslator.translateArrayLanguagesToPolish(jobOffer.languages);
            jobOfferData.locations = LocationTranslator.getCityArray(jobOffer.locations);
            jobOfferData.skills = JobOfferSkillsTranslator.translateLevelFromEnumToIntegerLevel(jobOffer.skills)
            jobOfferData.operatingModes = jobOffer.operatingModes
            jobOfferData.workTypes = jobOffer.workTypes
            jobOfferData.jobApplicationInfo = jobOffer.jobApplicationInfo
            organizationService.getOrganizationInfo().then(response => {
              response.json().then(body => {
                jobOfferData.payment.billingData = body.billingData
              });
              jobOfferData.payment.email = "some@email.xom"
              //TODO
              jobOfferData.payment.method = "BLIK"
            })
          })
        }
    )
  }
})
const formTemplateData = ref(null)

function getFormTemplateData() {
  const cacheKey = 'formTemplateData';
  const cacheExpiryKey = 'formTemplateDataExpiry';

  // Set cache expiration time (e.g., 1 hour)
  const cacheExpiryTime = 60 * 60 * 1000; // 1 hour
  const now = new Date().getTime();

  // Check if cached data exists and is not expired
  const cachedData = localStorage.getItem(cacheKey);
  const cacheExpiry = localStorage.getItem(cacheExpiryKey);

  if (cachedData && cacheExpiry && now < cacheExpiry) {
    // Return cached data as a promise
    return Promise.resolve(JSON.parse(cachedData));
  }

  // If there's no valid cache, fetch data from the API with Cache-Control headers
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
      "Cache-Control": "max-age=3600, must-revalidate" // Use caching for 1 hour
    },
  };

  return fetch(process.env.VUE_APP_BACKEND_URL + "/job-offers/form-template-data", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // Store the new data and set expiration time
        localStorage.setItem(cacheKey, JSON.stringify(data));
        localStorage.setItem(cacheExpiryKey, (now + cacheExpiryTime).toString());
        return data;
      })
      .catch((error) => {
        console.error("Error fetching form template data:", error);
      });
}

const step = ref(1)
const jobOfferData = reactive({
  title: "",
  seniority: "",
  jobPosition: "",
  contracts: [{type: null, salary: {from: null, to: null, currency: null}, id: 0}],
  questions: [],
  plan: {},
  description: "",
  operatingModes: [],
  skills: [],
  languages: [],
  cities: [],
  locations: [],
  workTypes: [],
  planType: "STANDARD",
  contactEmails: [],
  active: true,
  jobApplicationInfo: {
    externalApplicationLink: null,
    applicationType: ref("INTERNAL")
  },
  payment: {
    email: "",
    payNow: true,
    billingData: {
      companyName: "",
      nip: "",
      address: {
        street: "",
        streetNumber: "",
        flatNumber: "",
        city: "",
        zipCode: ""
      }
    },
    promoCode: null
  }
})

const copiedJobOfferUiRepresentation = ref(null)

function nextStep() {
  step.value = step.value + 1;
  ScrollUtils.scrollToTop('topDiv')
  if (isStepForJobOfferDetails()) {
    let value = mapValuesToRequestModel();
    copiedJobOfferUiRepresentation.value = value
  }
}

function isStepForJobOfferDetails() {
  return step.value === 3
}

function mapValuesToRequestModel() {
  let model = _.cloneDeep(jobOfferData);
  OfferDataTranslator.mapSkillLevelsToModel(model)
  model.seniority = OfferDataTranslator.translateFromPolishToExperienceLevel(model.seniority)
  model.jobPosition = JobPositionTranslator.translateToModel(model.jobPosition)
  mapContractsTypeToModel(model)
  mapLanguagesToModel(model)
  mapQuestionsToModel(model)
  return model
}

function previousStep() {
  step.value = step.value - 1;
}

function calculateWidthBasedOnStep() {
  if (step.value === 3) {
    return 'width: 45%'
  } else if (step.value === 1) {
    return 'width: 70%'
  } else {
    return 'width: 50%'
  }
}

async function sendPostWithJobOffer(message) {
  let isPaymentDataCorrect = await validatePaymentData();
  if (isPaymentDataCorrect) {
    let postJobOfferRepresentation = mapValuesToRequestModel()
    postJobOfferRepresentation.planType = postJobOfferRepresentation.plan.type
    postJobOfferRepresentation.plan = null
    JsonBlankFieldsCleaner.removeEmptyFields(postJobOfferRepresentation)
    try {
      const response = await OrganizatioOfferManagementService.addJobOffer(postJobOfferRepresentation)
      if (response.status === 201) {
        NotificationService.showNotification($q, 'positive', message);
        const body = await response.json();
        if (body.payment.url !== null) {
          paymentUrl.value = body.payment.url;
        }
        return true;
      } else if (response.status === 400) {
        const json = await response.json();
        if (json.message.includes('Wrong file format.')) {
          NotificationService.showNotification($q, 'negative', 'Błąd podczas dodawania oferty - skontaktuj sie z supportem');
        } else if (json.errorCode === 'EMAIL_ALREADY_TAKEN') {
          NotificationService.showNotification($q, 'negative', 'Konto o podanym adresie e-mail już istnieje, wróć do danych organizacji i zmień adres e-mail.');
        } else {
          console.error(json);
          NotificationService.showNotification($q, 'negative', 'Błąd podczas dodawania oferty - skontaktuj się z supportem.');
        }
        return false;
      } else {
        console.error(`Unexpected status code: ${response.status}`);
        NotificationService.showNotification($q, 'negative', 'Wystąpił nieoczekiwany błąd - skontaktuj się z supportem.');
        return false;
      }
    } catch (error) {
      console.error('Fetch error:', error);
      NotificationService.showNotification($q, 'negative', 'Wystąpił nieoczekiwany błąd - skontaktuj się z supportem.');
      return false;
    }
  }
}

function mapContractsTypeToModel(jobOfferData) {
  jobOfferData.contracts.forEach(contract => {
    contract.type = OfferDataTranslator.translateFromPolishToContractType(contract.type)
  })
}

function mapLanguagesToModel(jobOfferData) {
  jobOfferData.languages.forEach(language => {
    language.language = OfferDataTranslator.translateFromPolishToLanguage(language.language)
    language.proficiency = OfferDataTranslator.mapFromLanguageRateToProficiency(language.proficiency)
  })
}

function mapQuestionsToModel(jobOfferData) {
  jobOfferData.questions.forEach(question => {
    question.answerType = OfferDataTranslator.translateFromPolishToQuestionAnswerType(question.answerType);
  })
}

function getBasicPlan() {
  return JobOfferPlansService.getBasicPlan(formTemplateData.value.plans)
}

function getStadardPlan() {
  return JobOfferPlansService.getStandardPlan(formTemplateData.value.plans)
}

function getPremiumPlan() {
  return JobOfferPlansService.getPremiumPlan(formTemplateData.value.plans)
}

const paymentDataRef = ref(null)


async function validatePaymentData() {
  let validate = paymentDataRef.value.validate();
  return await validate
}


const showPaymentConfirmationDialog = ref(false)

async function showConfirmationDialogForPaymentIfNeeded() {
  let isCorrect = await validatePaymentData();
  if (isCorrect) {
    let planPrice = paymentDataRef.value.getFinalPrice();
    if (planPrice === 0) {
      await addOfferAndGoToMainBoard("Dodano ofertę")
    } else {
      showPaymentConfirmationDialog.value = true
    }
  }
}

const paymentUrl = ref(null)

async function addOfferAndGoToPrzelewy() {
  let isAdded = await sendPostWithJobOffer("Zapisano ofertę")
  if (isAdded) {
    window.open(paymentUrl.value, '_blank')
    await router.push({name: 'OrganizationJobOffersBoard'});
  } else {
    showPaymentConfirmationDialog.value = false;
  }

}

async function addOfferAndGoToMainBoard(message) {
  let isAdded = await sendPostWithJobOffer(message)
  if (isAdded) {
    await router.push({name: 'OrganizationJobOffersBoard'});
  } else {
    showPaymentConfirmationDialog.value = false;
  }
}

function calculatedTopMarginAndPadding() {
  if (step.value === 1) {
    return "q-mt-md q-pt-xs"
  } else {
    return "q-mt-lg q-pt-lg"
  }
}
</script>

<style lang="scss" scoped>
//.my-stepper {
//  width: 950px;
//}

.q-chip {
  background: white;
}

html, body {
  height: 100vh;
  background-color: rgb(226,231,239);
}

.scrollableDiv {
  overflow-y: auto;
  width: 100%;
  flex: 1;
}
</style>
