<!--        TYTUL STANOWISKO DOSWIADCZENIE-->
<template>
  <q-form ref="formRef" greedy>
    <div class="row">
      <q-input class="q-pa-xs col"
               color="primary"
               stack-label
               outlined
               v-model="offer.title"
               label="Tytuł ogłoszenia"
               style="width: 17rem"
               :rules="titleRules"
               dense>
        <template v-slot:append>
          <q-avatar>
            <q-icon
                name="sym_o_title"
                size="1rem"/>
          </q-avatar>
        </template>
      </q-input>
      <q-select
          class="col q-pa-xs"
          color="primary"
          stack-label
          outlined
          v-model="offer.jobPosition"
          :options="filteredPosition"
          :rules="jobPositionRules"
          label="Stanowisko"
          :popup-content-style="{height: '300px', overflowY: 'auto'}"
          dense
          @filter="(val, update) => filterAllPositions(val, update)"
      >
      </q-select>
      <q-select class="col-3 q-pa-xs" color="primary" stack-label outlined v-model="offer.seniority"
                :options="OfferDataTranslator.translateExperienceLevelsTypeToPolish()"
                dense
                :rules="seniorityRules"
                label="Poziom stanowiska">
      </q-select>
    </div>
    <div class="q-pt-1rem q-pa-xs">
      <q-field ref="fieldRef" v-model="offer.description" label-slot borderless dense
               :rules="[val => val.length < 4000|| 'Zbyt długi opis - max 4000 znaków.', val => val.length >0|| 'Opis jest wymagany.']">
        <my-text class="full-width">
          <q-editor
              class="full-width"
              v-model="offer.description"
              min-height="13rem"
              max-height="13rem"
              style="border-radius: 9px"
              toolbar-color="accent"
              :toolbar="[ [ 'left', 'center', 'right', 'justify' ],['ordered','unordered'], [ 'bold', 'italic', 'underline', 'strike' ], [ 'undo', 'redo' ] ]"
              :style="fieldRef && fieldRef.hasError ? 'border-color: #C10015' : ''"
          />

        </my-text>

      </q-field>
    </div>
    <div class="q-pt-md">
      <contracts-section ref="contractsComponentRef" v-model="offer.contracts"
                         :form-template-data="formTemplateData"/>
    </div>

    <div class="q-pt-md">
      <div>
        <adding-offer-section-description class="q-mb-md" title="Formularz aplikacyjny"
                                          description=" - zdefiniuj typ formularza."/>
      </div>
      <div class="q-pa-none q-pb-md">
        <q-btn-toggle
            style="border-radius: 7px"
            v-model="offer.jobApplicationInfo.applicationType"
            spread
            no-caps
            toggle-color="primary"
            color="white"
            text-color="grey-9"
            :options="[
          {label: 'Domyślny formularz aplikacyjny', value: 'INTERNAL'},
          {label: 'Zewnętrzny formularz aplikacyjny', value: 'EXTERNAL'}
        ]"
        />
      </div>
      <q-input class="q-pa-xs q-pb-sm" color="primary" stack-label outlined
               v-if="offer.jobApplicationInfo.applicationType==='EXTERNAL'"
               v-model="offer.jobApplicationInfo.externalApplicationLink"
               :rules="[val => val !== null|| 'Podaj adres www zewnętrznego formularza']"
               label="URL do zewnętrznego formularza"
               dense>
        <template v-slot:append>
          <q-avatar>
            <q-icon
                name="sym_o_title"
                size="1rem"
            />
          </q-avatar>
        </template>
      </q-input>
      <questions-section v-if="offer.jobApplicationInfo.applicationType ==='INTERNAL'"
                         ref="childComponentRef" v-model="offer.questions" :form-template-data="formTemplateData"/>
    </div>

    <div class="q-pt-md">
      <skills ref="skillsRef" :form-template-data="formTemplateData" v-model="offer"></skills>
    </div>

    <div class="q-pt-md q-mb-sm">
      <languages-section :form-template-data="formTemplateData" v-model="offer"/>
    </div>

    <div class="q-pt-lg">
      <operating-mode :form-template-data="formTemplateData" v-model="offer"></operating-mode>
    </div>
    <div class=" q-pt-md full-width q-pb-md">
      <adding-offer-section-description title="Biura" description=" - podaj lokalizacje."/>
      <q-select
          dense
          class="q-mt-md"
          color="primary"
          use-chips
          multiple
          v-model="offer.cities"
          :rules="[val => locationsValidationAccordingToPlan(val) || locationPlanRulesMsg(val)]"
          label="Lokalizacje biur"
          clear-icon="cancel"
          use-input
          :options="locationsOptions"
          outlined
          @update:model-value="val => handleModelValueUpdate(val)"
          @filter="(val, update) => filterAllLocations(val, update)"
      >
      </q-select>
      <div v-for="address in offer.locations" :key="address.city">
        <div class="row q-gutter-md q-mb-sm">
          <q-chip color="primary" style="border-radius: 7px; height: 40px" outline :label="address.city"></q-chip>
          <q-input v-model="address.street" rounded label-color="grey-6" outlined dense
                   class="col bg-white text-grey-9"
                   :rules="[ val => !StringUtils.isStringEmpty(val) || 'Pole jest wymagane' ]"
                   label="Ulica">
          </q-input>
          <q-input v-model="address.streetNumber" rounded label-color="grey-6" outlined dense
                   class="col bg-white text-grey-9"
                   :rules="[ val => !StringUtils.isStringEmpty(val) || 'Pole jest wymagane' ]"
                   label="Numer budynku">
          </q-input>
          <q-input v-model="address.flatNumber" rounded label-color="grey-6" outlined dense
                   class="col bg-white text-grey-9"
                   label="Numer lokalu">
          </q-input>
        </div>
      </div>
    </div>
    <div class="q-mb-md">
      <q-separator></q-separator>
    </div>
    <q-stepper-navigation class="q-pa-none q-pt-sm" v-if="showButtons">
      <q-btn size="md" color="accent" no-caps class="q-px-lg" label="Dalej" @click="nextStep()"/>
      <q-btn size="md" flat @click="previousStep" color="primary" no-caps label="Powrót" class="q-ml-md q-px-md"/>
    </q-stepper-navigation>
  </q-form>
</template>

<script setup>
import {computed, defineEmits, defineProps, ref} from "vue"
import LanguagesSection from "@/components/common/add-offer/job-offer/language/LanguagesSection";
import OperatingMode from "@/components/common/add-offer/job-offer/operating-mode/OperatingModeSection";
import Skills from "@/components/common/add-offer/job-offer/skills/Skills";
import ContractsSection from "@/components/common/add-offer/job-offer/contracts/ContractsSection";
import OfferDataTranslator from "@/services/organization/offer/OfferDataTranslator";
import NotificationService from "@/services/notification/NotificationService";
import StringUtils from "@/services/common/StringUtils";
import {useQuasar} from 'quasar'
import QuestionsSection from "@/components/common/add-offer/job-offer/questions/QuestionsSection";
import JobPositionTranslator from "@/services/offer/JobPositionTranslator";
import AddingOfferSectionDescription from "@/components/common/add-offer/AddingOfferSectionDescription";
import JobOfferPlansService from "@/services/organization/offer/JobOfferPlansService";

const props = defineProps({
  formTemplateData: Object,
  title: String,
  modelValue: Object,
  showButtons: Boolean
})

const offer = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val),
});

const emit = defineEmits(['update:modelValue', 'step:next', 'step:previous'])

const filteredPosition = ref(null)

function jobPositionOptions() {
  let options = []
  props.formTemplateData.jobOffer.jobPositions.forEach(jobPosition => {
    options.push(JobPositionTranslator.translateConstant(jobPosition))
  })
  return options;
}

function locationsValidationAccordingToPlan(val) {
  if (offer.value.planType === 'BASIC') {
    return val.length > 0 && val.length <= JobOfferPlansService.getBasicPlan(props.formTemplateData.plans).locationsLimit;
  }
  if (offer.value.planType === 'STANDARD') {
    return val.length > 0 && val.length <= JobOfferPlansService.getStandardPlan(props.formTemplateData.plans).locationsLimit;
  }
  if (offer.value.planType === 'PREMIUM') {
    return val.length > 0 && val.length <= JobOfferPlansService.getPremiumPlan(props.formTemplateData.plans).locationsLimit;
  }
}

function locationPlanRulesMsg() {
  if (offer.value.planType === 'BASIC') {
    return 'Wybrałeś plan Basic, możesz podać od 1 do ' + JobOfferPlansService.getBasicPlan(props.formTemplateData.plans).locationsLimit + ' lokalizacji'
  }
  if (offer.value.planType === 'STANDARD') {
    return 'Wybrałeś plan Standard, możesz podać od 1 do ' + JobOfferPlansService.getStandardPlan(props.formTemplateData.plans).locationsLimit + ' lokalizacji'
  }
  if (offer.value.planType === 'PREMIUM') {
    return 'Wybrałeś plan Premium, możesz podać od 1 do ' + JobOfferPlansService.getPremiumPlan(props.formTemplateData.plans).locationsLimit + ' lokalizacji'
  }
}

const childComponentRef = ref(null)
const contractsComponentRef = ref(null)

const $q = useQuasar();

const titleRules = [
  val => {
    if (!val || val.length === 0) {
      showNotification('negative', 'Podaj tytuł');
      return 'Podaj tytuł ogłoszenia';
    }
    if (val.length > 40) {
      showNotification('negative', 'Podaj tytuł');
      return 'Max. 40 znaków';
    }
    return true;
  }
];

const jobPositionRules = [
  val => {
    if (!val || val.length === 0) {
      showNotification('negative', 'Wybierz stanowisko');
      return 'Podaj stanowisko';
    }
    return true;
  }
];

const seniorityRules = [
  val => {
    if (!val || val.length === 0) {
      showNotification('negative', 'Wybierz poziom stanowiska');
      return 'Podaj stanowisko';
    }
    return true;
  }
];

function showNotification(type, message) {
  $q.notify({
    type: type,
    message: message,
    timeout: 2000, // You can set the timeout as per your requirement
    position: 'bottom'
  });
}

const formRef = ref(null);

async function nextStep() {
  const isFormValid = await formRef.value.validate();
  const isLocationEmpty = offer.value.cities.length < 1;
  if (isLocationEmpty) {
    NotificationService.showNotification($q, 'negative', 'Podaj przynajmniej jedną lokalizację');
  }
  const skillsValid = validateSkills();
  const operatingModesValid = validateOperatingModes();
  const workTypesValid = validateWorkTypes();
  const languageProficiencyValid = validateLanguageProficiency();
  const contractsValid = await validateContracts();
  const questionsValid = validateQuestions();
  const isNonEmptyDescription = validateDescription();
  if (
      isFormValid &&
      skillsValid &&
      operatingModesValid &&
      languageProficiencyValid &&
      contractsValid &&
      questionsValid &&
      workTypesValid &&
      !isLocationEmpty &&
      isNonEmptyDescription
  ) {
    emit('step:next');
  } else {
    console.error('Validation failed. Cannot proceed to the next step.');
  }
}

function previousStep() {
  emit('step:previous')
}


function validateSkills() {
  let skills = offer.value.skills;
  skillsRef.value.validate();
  if (skills.length > 0) {
    for (const skill of skills) {
      if (skill.level === null) {
        NotificationService.showNotification($q, 'negative', 'Podaj wymagany poziom umiejętności dla ' + skill.name);
        return false;
      }
    }

  } else {
    NotificationService.showNotification($q, 'negative', 'Podaj przynajmniej trzy umiejętności');
    return false
  }
  return true;
}

function validateOperatingModes() {
  if (offer.value.operatingModes.length === 0) {
    NotificationService.showNotification($q, 'negative', 'Podaj przynajmniej jeden tryb pracy');
    return false;
  }
  return true;
}

function validateWorkTypes() {
  if (offer.value.workTypes.length === 0) {
    NotificationService.showNotification($q, 'negative', 'Podaj przynajmniej jeden czasowy wymiar pracy');
    return false;
  }
  return true;
}

function validateLanguageProficiency() {
  let languages = offer.value.languages;
  for (const lang of languages) {
    if (lang.proficiency === null) {
      NotificationService.showNotification($q, 'negative', 'Podaj poziom znajomości języka dla: ' + lang.language);
      return false;
    }
  }
  return true;
}

async function validateContracts() {
  if (offer.value.contracts.length > 0) {

    try {
      let hasNotDuplicates = !hasDuplicateContractTypes();
      let componentValid = await contractsComponentRef.value.validateChildComponents();
      const isValid = componentValid && hasNotDuplicates;
      if (isValid) {
        return true;
      } else {
        if (!componentValid && hasNotDuplicates) {
          NotificationService.showNotification($q, 'negative', 'Popraw dane umowy');
        }
        return false; // At least one validation failed
      }
    } catch (error) {
      console.error('Error during contract validation:', error);
      NotificationService.showNotification($q, 'negative', 'Wystąpił błąd podczas walidacji umów.');
      return false; // Handle validation error
    }
  } else {
    return false; // No contracts to validate
  }
}

function hasDuplicateContractTypes() {
  const contractTypeCount = new Map();
  for (const contract of offer.value.contracts) {
    const contractType = contract.type;
    if (contractType) {
      contractTypeCount.set(contractType, (contractTypeCount.get(contractType) || 0) + 1);
      if (contractTypeCount.get(contractType) > 1) {
        NotificationService.showNotification($q, 'negative', 'Popraw typ umowy. Jeden typ umowy zostal użyty więcej niż raz.');
        return true;
      }
    }
  }
  return false;
}


function validateQuestions() {
  if (offer.value.questions.length > 0) {
    return childComponentRef.value.validateChildComponents()
  } else {
    return true
  }
}

function validateDescription() {
  if (offer.value.description.length === 0) {
    NotificationService.showNotification($q, 'negative', 'Opis jest wymagany');
    return false;
  } else {
    return true
  }
}

const skillsRef = ref(null)

function filterAllPositions(val, update) {
  let options = jobPositionOptions()
  update(() => {
    if (val === "") {
      filteredPosition.value = jobPositionOptions()
    } else {
      let needle = val.toLowerCase();
      filteredPosition.value = options.filter(
          v => v.toLowerCase().indexOf(needle) > -1
      );
    }
  });
}

const locationsOptions = ref([])

function filterAllLocations(val, update) {
  let options = OfferDataTranslator.translateCitiesToPolish()
  update(() => {
    if (val === "") {
      locationsOptions.value = options;
    } else {
      let needle = val.toLowerCase();
      locationsOptions.value = options.filter(
          v => v.toLowerCase().indexOf(needle) > -1
      );
    }
  });
}

function addCityToAddresses(city) {
  const cityExists = offer.value.locations.some(address => address.city === city);
  if (!cityExists) {
    offer.value.locations.push({city});
  }
}

function handleModelValueUpdate(val) {
  offer.value.cities = val;
  val.forEach(city => addCityToAddresses(city));
  offer.value.locations.forEach(address => {
    if (!val.includes(address.city)) {
      removeCityFromAddresses(address.city);
    }
  });
}

function removeCityFromAddresses(city) {
  offer.value.locations = offer.value.locations.filter(address => address.city !== city);
}
</script>
<style lang="sass" scoped>
@import "/src/assets/css/main.css"
.q-input
  font-size: 12px

.q-field-target
  font-size: 12px
  color: #581c9b

.q-select
  font-size: 12px

.q-editor
  font-size: 11px

.my-stepper
  width: 65%
</style>
