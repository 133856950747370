<template>
  <q-dialog v-model="accountDeactivateDialog">
    <q-card style="width: 300px; border-radius: 9px;">
      <q-card-section class="row justify-center q-px-md q-pt-md q-pb-md">
        <div class="text-h7 text-grey-9 text-center">
          <div class="q-pt-none q-pb-sm q-mb-none text-center">
            <q-icon size="md" name="fa-regular fa-circle-question" color="pink-5" class=""></q-icon>
          </div>
          <div>
            <span class="text-center" style="font-size: 13px">Czy na pewno chcesz dezaktywować konto?</span>
          </div>
        </div>
      </q-card-section>
      <div class="text-center q-mb-md">
        <q-btn @click="changeUserStatus(false)" style="border-radius: 7px"
               class="q-px-md q-mr-sm" dense label="Ok"
               no-caps
               color="primary"></q-btn>
        <q-btn @click="accountDeactivateDialog = false" outline style="border-radius: 7px"
               class="q-px-md" dense label="Powrót"
               no-caps
               color="primary"></q-btn>
      </div>
    </q-card>
  </q-dialog>
  <q-dialog v-model="accountActivateDialog">
    <q-card style="width: 300px; border-radius: 9px;">
      <q-card-section class="row justify-center q-px-md q-pt-md q-pb-md">
        <div class="text-h7 text-grey-9 text-center">
          <div class="q-pt-none q-pb-sm q-mb-none text-center">
            <q-icon size="md" name="fa-regular fa-circle-question" color="positive" class=""></q-icon>
          </div>
          <div>
            <span class="text-center" style="font-size: 13px">Czy na pewno chcesz aktywować konto?</span>
          </div>
        </div>
      </q-card-section>
      <div class="text-center q-mb-md">
        <q-btn @click="changeUserStatus(true)" style="border-radius: 7px"
               class="q-px-md q-mr-sm" dense label="Ok"
               no-caps
               color="primary"></q-btn>
        <q-btn @click="accountActivateDialog = false" outline style="border-radius: 7px"
               class="q-px-md" dense label="Powrót"
               no-caps
               color="primary"></q-btn>
      </div>
    </q-card>
  </q-dialog>
  <q-card v-if="isReady" class="row justify-between q-mb-md" style="min-height: 120px; border-radius: 9px;">
    <div>
      <div>
        <q-card-section class="row q-pr-xs q-pb-none">
          <q-avatar class="flex items-center justify-center" size="90px">
            <q-img
                v-if="avatar !== null"
                fit="contain"
                :src="'data:'+avatar.contentType +';base64,' + avatar.file"
                class="full-width full-height"
            />
            <div
                v-else
                :class="['row items-center justify-center', DefaultAvatarService.getColorByInitials(account)]"
                style="width: 100%; height: 100%; padding-bottom: 3px"
            >
              <div>{{ DefaultAvatarService.getInitials(account) }}</div>
            </div>
          </q-avatar>

          <div>
            <div>
              <my-text style="font-size: 12px" class="q-pl-md text-primary"><strong>{{ account.email }}</strong>
              </my-text>
            </div>
            <q-chip outline v-if="account.type ==='ORGANIZATION_ADMIN'" class="text-cyan-6 q-ml-md q-mt-sm" size="sm"
                    style="border-radius: 5px"
                    label="Admin">
            </q-chip>
            <q-chip outline
                    v-else-if="account.position"
                    class="text-primary q-ml-md q-mt-sm"
                    size="sm"
                    :label="account.position"
                    style="border-radius: 5px"
            >
            </q-chip>
            <!--              <div>-->
            <!--                <my-text class="q-pl-md">{props.account.name}</my-text>-->
            <!--              </div>-->
            <div>
              <my-text class="q-pl-md"><strong>{{ translateRole() }}</strong></my-text>
            </div>
          </div>
        </q-card-section>
      </div>

      <!--        <q-card-section class="q-pt-none">-->
      <!--          <div>-->
      <!--            <q-chip square>-->
      <!--              <q-avatar color="primary" text-color="white" size="sm">50</q-avatar>-->
      <!--              <my-text>Aktywne CV</my-text>-->
      <!--            </q-chip>-->
      <!--          </div>-->
      <!--          <div>-->
      <!--            <q-chip square>-->
      <!--              <q-avatar color="secondary" text-color="white" size="sm">152</q-avatar>-->
      <!--              <my-text>Sprawdzone CV</my-text>-->
      <!--            </q-chip>-->
      <!--          </div>-->
      <!--          <div>-->
      <!--            <q-chip square>-->
      <!--              <q-avatar color="accent" text-color="white" size="sm">50</q-avatar>-->
      <!--              <my-text>Zaplanowane spotkania</my-text>-->
      <!--            </q-chip>-->
      <!--          </div>-->
      <!--        </q-card-section>-->
    </div>
    <q-card-section class="q-gutter-sm q-pr-sm" style="min-width: 200px">
      <div>
        <q-btn v-if="isReady && isLoggedUserAdmin && !isAdminAccount()" size="11px" color="primary" flat
               label="Przekaż admina" icon="sym_o_key" @click="passAdmin()"></q-btn>
      </div>
      <div>
        <q-btn size="11px" color="primary" flat label="Edytuj dane" icon="sym_o_email"
               @click="resetDataAfterEdit(true)"></q-btn>
      </div>
      <div>
        <q-btn v-if="isReady && !isAdminAccount() && !props.account.active" size="11px" color="accent" flat
               label="Aktywuj" icon="fa-solid fa-square-arrow-up-right"
               @click="accountActivateDialog = true"></q-btn>
        <q-btn v-else-if="currentUser.id !== props.account.id && isReady && !isAdminAccount()&& props.account.active && props.account.emailVerified !== false"
               size="11px" color="pink-5" flat
               label="Dezaktywuj" icon="sym_o_no_accounts"
               @click="accountDeactivateDialog = true"></q-btn>
      </div>
    </q-card-section>
  </q-card>
  <q-dialog v-model="isEditDialogVisible">
    <q-card style="max-width: 100%;border-radius: 9px; width: 500px">
      <q-form greedy @submit="updateUserData()">
        <div class="q-ma-sm">
          <p style="font-size: 15px" class="text-bold q-mb-none q-pl-sm text-primary">Edytuj dane</p>
        </div>
        <div class="q-mx-md q-mb-md">
          <q-separator></q-separator>
        </div>
        <q-card-section class="q-pt-none">
          <q-input color="primary" v-model="accountData.email" disable label="E-mail *" dense outlined/>
        </q-card-section>
        <q-card-section class="row q-gutter-md q-pt-none q-pb-none">
          <q-input
              color="primary"
              class="col"
              v-model="accountData.firstName"
              label="Imię"
              dense
              outlined
              :rules="[
      val => !!val || 'Imię jest wymagane',
      val => val.length <= 100 || 'Max. 100 znaków'
    ]"
          />
          <q-input
              color="primary"
              class="col"
              v-model="accountData.lastName"
              label="Nazwisko"
              dense
              outlined
              :rules="[
      val => !!val || 'Nazwisko jest wymagane',
      val => val.length <= 100 || 'Max. 100 znaków'
    ]"
          />
        </q-card-section>

        <q-card-section class="row q-gutter-md q-pt-none q-pb-none">
          <q-input
              color="primary"
              class="col"
              v-model="accountData.phoneNumber"
              label="Numer telefonu (opcjonalny)"
              dense
              outlined
          />
          <q-input
              color="primary"
              class="col"
              v-model="accountData.position"
              label="Stanowisko"
              dense
              outlined
              :rules="[
      val => !!val || 'Stanowisko jest wymagane',
      val => val.length <= 100 || 'Max. 100 znaków'
    ]"
          />
        </q-card-section>

        <q-card-section v-if="!isAdminAccount()" class="full-width q-mb-none">
          <div>
            <q-table
                flat bordered
                hide-bottom
                :rows="rows"
                :columns="columns"
                row-key="name"
                class="text-primary"
            >
              <template v-slot:body="props">
                <q-tr :props="props">
                  <q-td style="padding-left: 7px" key="name" class="q-pl-none" :props="props">
                    <q-chip style="border-radius: 5px" dense class="text-grey-9" :color="props.row.color"
                            :label="props.row.name"></q-chip>
                  </q-td>
                  <q-td key="tak" :props="props">
                    <q-checkbox size="sm" v-model="props.row.tak" color="primary"
                                @click="checkIfPermissionsAreEmpty(rows)"
                    >
                    </q-checkbox>
                  </q-td>
                </q-tr>
              </template>
            </q-table>
          </div>
        </q-card-section>
        <q-card-actions class="row justify-between q-px-md q-pb-md">
          <q-btn style="border-radius: 5px" outline dense label="Anuluj" color="negative"
                 @click="isEditDialogVisible = false"/>
          <q-btn style="border-radius: 5px" label="Zapisz" dense color="primary" type="submit"/>
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>

  <q-dialog v-model="showConfirmationDialog" persistent>
    <q-card style="border-radius: 9px">
      <q-card-section class="row q-pb-none">
        <div class="q-ml-sm q-mr-md q-pb-md">
          <q-icon name="fa-solid fa-pen-to-square" color="green-5" text-color="white" size="md"/>
        </div>
        <div class="col">
          <p class="q-ml-sm q-mt-sm text-grey-9">Zaktualizowano dane konta.</p>
        </div>
      </q-card-section>
      <div class="q-mx-md q-mb-none">
        <q-separator></q-separator>
      </div>
      <q-card-actions align="right" class="q-pt-xs q-mr-none q-pb-xs">
        <q-btn class="q-mr-sm" outline flat label="Ok" color="primary" v-close-popup/>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>


<script setup>
import {computed, defineEmits, defineProps, onMounted, ref} from 'vue';
import organizationUsersService from "@/services/organization/OrganizationUsersService";
import OrganizationUsersService from "@/services/organization/OrganizationUsersService";
import {useQuasar} from "quasar";
import {currentUser} from '@/global-variables/currentUser.js';
import DefaultAvatarService from "@/services/common/DefaultAvatarService";

const isEditDialogVisible = ref(false);
const isLoggedUserAdmin = ref(false)
const accountData = ref({firstName: "", lastName: "", position: ""})
const isReady = ref(false)
const accountDeactivateDialog = ref(false)
const accountActivateDialog = ref(false)
const avatar = ref(null)
onMounted(() => {
  isCurrentUserAdmin().then((result) => {
    isLoggedUserAdmin.value = result;
  });
  updateTakProperty(account.value.permissions, rows.value)
  resetDataAfterEdit(false)
  OrganizationUsersService.getAvatar(account.value.id).then(response =>{
    if(response.ok){
      response.json().then(body =>{
        avatar.value = body
      })
    }
    isReady.value = true
  })
})

function updateTakProperty(userPermissions, rows) {
  for (const row of rows) {
    row.tak = false;
  }
  for (const row of rows) {
    if (userPermissions.some(permission => permission.name === row.id)) {
      row.tak = true;
    }
    if (userPermissions.some(permission => permission.name === 'OFFERS' && permission.actions !== null && permission.actions.includes('READ') && row.id === 'OFFERS_READ')) {
      row.tak = true;
    }
    if (userPermissions.some(permission => permission.name === 'OFFERS' && permission.actions !== null && permission.actions.includes('CREATE') && row.id === 'OFFERS_MANAGEMENT')) {
      row.tak = true;
    }
  }
}

const props = defineProps({
  account: Object
})
const emit = defineEmits(['account'])

const account = computed({
  get: () => props.account,
  set: (val) => emit('update:account', val),
});

function translateRole() {
  if (account.value.role === 'ROLE_ADMIN') {
    return 'Admin'
  } else if (account.value.role === 'ROLE_OFFER_MANAGER') {
    return 'Rekruter'
  }
}

function isAdminAccount() {
  return account.value.type === 'ORGANIZATION_ADMIN'
}

function checkIfPermissionsAreEmpty(rows) {
  if (rows.every(row => row.tak === false)) {
    $q.notify({
      type: 'negative',
      message: 'Nie można stworzyć użytkownika bez uprawnień.'
    })
  }
}

const $q = useQuasar();

async function changeUserStatus(isActive) {
  if (props.account.type === 'ORGANIZATION_ADMIN') {
    $q.notify({
      type: 'negative',
      message: 'Nie można usunąć konta admina.'
    });
  } else {
    await organizationUsersService.changeUserAccountActivity(isActive, props.account.id)
    emit("account:deactivate", props.account.id)
  }
}

async function isCurrentUserAdmin() {
  return currentUser.type === 'ORGANIZATION_ADMIN'
}

function passAdmin() {
  emit("admin:pass", props.account.email, props.account.id)
}

const rows = ref([
  {
    name: 'Zrządzanie danymi organizacji',
    id: 'ORGANIZATION',
    tak: false,
    color: 'white'
  },
  {
    name: 'Zarządzanie kontami użytkowników',
    tak: false,
    id: 'USERS',
    color: 'white'
  },
  {
    name: 'Zarządzanie i przeglądanie ofert',
    id: 'OFFERS_MANAGEMENT',
    tak: false,
    color: 'white'
  }
])

const columns = ref([
  {
    name: 'name',
    required: true,
    label: 'Uprawnienia',
    align: 'left',
    field: row => row.name,
    format: val => `${val}`,
    sortable: true
  },
  {name: 'tak', align: 'center', sortable: false, field: 'tak'},
])

function showAndHideConfirmationDialog() {
  showConfirmationDialog.value = true;
  setTimeout(() => {
    showConfirmationDialog.value = false; // Close the dialog after 3.7 seconds
  }, 3700);
}

function getPermissionForOrganizationIfAdded() {
  let uiPermission = rows.value.find(permission => permission.id === 'ORGANIZATION');
  if (uiPermission && uiPermission.tak) {
    return ['UPDATE'];
  }
  return [];
}

function getPermissionForUsersIfAdded() {
  let uiPermission = rows.value.find(permission => permission.id === 'USERS');
  if (uiPermission && uiPermission.tak) {
    return ['CREATE', 'READ', 'UPDATE', 'DEACTIVATE'];
  }
  return [];
}

function getPermissionForUsersPermissionsIfAdded() {
  let uiPermission = rows.value.find(permission => permission.id === 'USERS');
  if (uiPermission && uiPermission.tak) {
    return ['UPDATE'];
  }
  return [];
}

function getPermissionForOffersIfAdded() {
  let uiOffersManagementPermission = rows.value.find(permission => permission.id === 'OFFERS_MANAGEMENT');
  let permissions = ['CREATE', 'READ', 'UPDATE', 'DELETE'];
  if (uiOffersManagementPermission && uiOffersManagementPermission.tak) {
    return permissions;
  }
  return [];
}

function getPermissionForApplicationsIfAdded() {
  let uiPermission = rows.value.find(permission => permission.id === 'OFFERS_MANAGEMENT');
  if (uiPermission && uiPermission.tak) {
    return ['READ', 'UPDATE', 'DELETE'];
  }
  return [];
}

function getPermissionForMeetingsIfAdded() {
  let uiPermission = rows.value.find(permission => permission.id === 'MEETINGS');
  if (uiPermission && uiPermission.tak) {
    return ['READ', 'CREATE', 'UPDATE', 'DELETE'];
  }
  return [];
}

function notifyNegative(message) {
  $q.notify({
    type: 'negative',
    message: message
  })
}

function updateUserData() {
  let permissionsArray = [];

  function addPermissionIfAny(name, actions) {
    if (actions && actions.length > 0) {
      permissionsArray.push({
        name: name,
        actions: actions
      });
    }
  }

  addPermissionIfAny('ORGANIZATION', getPermissionForOrganizationIfAdded());
  addPermissionIfAny('USERS', getPermissionForUsersIfAdded());
  addPermissionIfAny('USERS_PERMISSIONS', getPermissionForUsersPermissionsIfAdded());
  addPermissionIfAny('OFFERS', getPermissionForOffersIfAdded());
  addPermissionIfAny('APPLICATIONS', getPermissionForApplicationsIfAdded());
  addPermissionIfAny('MEETINGS', getPermissionForMeetingsIfAdded());

  let permissions = {
    permissions: permissionsArray
  };

  // Now send the permissions object in the correct format
  organizationUsersService.updateUserData({...accountData.value, ...permissions}).then(response => {
    if (response.ok) {
      if (!isAdminAccount()) {
        updatePermissionsForNonAdmin(permissions);
      } else {
        isEditDialogVisible.value = false;
        showAndHideConfirmationDialog()
      }
    } else if (response.status === 400) {
      response.json().then(body => {
        if (body.message.includes("phoneNumber")) {
          notifyNegative('Błędny format numeru kontaktowego. Wspierane formaty (123456789, +48123456789)');
        } else {
          notifyNegative(body.message);
        }
      })
    }
  })
}


function updatePermissionsForNonAdmin(permissions) {
  organizationUsersService.updateUserPermissions(accountData.value.id, permissions).then(response => {
    if (response.status === 204) {
      isEditDialogVisible.value = false;
      showAndHideConfirmationDialog()
    } else {
      $q.notify({
        type: 'negative',
        message: 'Nie można zaktualizować dostępów - skontaktuj się z supportem'
      })
    }
  })
}

function resetDataAfterEdit(isVisibleEdit) {
  organizationUsersService
      .getUser(account.value.id)
      .then((result) => {
        accountData.value = result
        updateTakProperty(result.permissions, rows.value)
        isEditDialogVisible.value = isVisibleEdit;
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
}

const showConfirmationDialog = ref(false);
</script>


<style lang="scss" scoped>
.my-card {
  width: 100%
}

.avatar-border {
  border: 2px solid $primary;
}
</style>




