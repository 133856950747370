<template>
    <q-dialog v-model="showDialog" position="right">
      <q-card style="border-radius: 9px 0 0 9px; max-width: 450px;">
        <div v-for="questionFilter in questionsForFilterModel.questions" :key="questionFilter.questionId"
             class="q-px-md">
          <q-card flat class="q-mt-lg bg-primary" style="border-radius: 9px">
            <q-card-section>
              <my-text class="text-white q-px-sm" style="font-size: 13px">{{ questionFilter.question }}</my-text>
            </q-card-section>
          </q-card>
          <text-answer-filter v-if="isTextTypeAnswer(questionFilter)"
                              :question-filter="questionFilter">
          </text-answer-filter>
          <number-answer v-else-if="isNumberTypeAnswer(questionFilter)"
                         :question-filter="questionFilter">
          </number-answer>
          <boolean-answer-filter v-else-if="isBooleanTypeAnswer(questionFilter)"
                                 :question-filter="questionFilter"
          >

          </boolean-answer-filter>
          <div class="q-mb-md">
            <q-separator></q-separator>
          </div>
        </div>
        <q-card-section class="q-pt-none">
          <div class="row justify-start q-pb-none q-pt-none">
            <q-btn size="sm" class="q-mr-sm" color="primary" no-caps label="Filtruj" @click="emitFilterValues()"
                   style="border-radius: 7px"/>
            <q-btn size="sm" color="dark" outline no-caps label="Zamknij" @click="showDialog=false"
                   style="border-radius: 7px"/>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
</template>

<script setup>
import {computed, defineEmits, defineProps} from "vue";
import NumberAnswer from "@/components/organization/applications/questions/NumberAnswerFilter";
import TextAnswerFilter from "@/components/organization/applications/questions/TextAnswerFilter";
import BooleanAnswerFilter from "@/components/organization/applications/questions/BooleanAnswerFilter";

const props = defineProps({
  checkedAppsCount: String,
  showDialog: Boolean,
  questionsForFilterModel: Object
})

const showDialog = computed({
  get: () => props.showDialog,
  set: (val) => emit('update:showDialog', val),
});

const questionsForFilterModel = computed({
  get: () => props.questionsForFilterModel,
  set: (val) => emit('update:questionsForFilterModel', val),
});

const emit = defineEmits(['update:showDialog'])

function isTextTypeAnswer(question) {
  return question.answerType === "TEXT"
}

function isNumberTypeAnswer(question) {
  return question.answerType === "NUMBER"
}

function isBooleanTypeAnswer(question) {
  return question.answerType === "BOOLEAN"
}

function emitFilterValues() {
  showDialog.value = false
  emit('questionsFilter')
}
</script>