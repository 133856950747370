<!-- LoginDropdown.vue -->
<template style="background-color: rebeccapurple">
  <q-layout>
    <q-page-container>
      <q-page style="background-color: rgb(217,225,238);">
        <mobile-menu v-if="isMobile()"></mobile-menu>
        <menu-bar v-else></menu-bar>
        <div class="login-center">
          <q-card style="width: 350px; border-radius: 9px">
            <q-card-section class="row justify-center q-pb-sm">
              <div class="q-pt-none q-pr-sm">
                <q-icon color="primary" size="32px" name="mdi-account-group-outline"></q-icon>
              </div>
              <div class="text-center text-h6 text-primary">Znajdź pracę z ByteJob</div>
            </q-card-section>
            <q-form @submit.prevent="login()" greedy>
              <q-card-section class="q-pb-none q-mx-sm">
                <q-input
                    dense
                    outlined
                    v-model="email"
                    label="E-mail"
                    id="email"
                    :rules="[val => !!val || 'E-mail jest wymagany']"
                ></q-input>
                <q-input
                    outlined
                    type="password"
                    dense
                    class="q-pt-sm"
                    v-model="password"
                    label="Hasło"
                    id="password"
                    :rules="[val => !!val || 'Hasło jest wymagane']"
                ></q-input>
              </q-card-section>
              <q-card-section class="q-pt-none q-pb-sm">
                <div class="text-center q-pt-none q-pb-none"> <!-- Add the text-center class to this div -->
                  <q-btn size="13px" flat color="accent" @click="router.push({ name: 'EmployeeRegistration' });"
                         icon="diversity_3"
                         label="Nie masz konta? - Dołącz"
                         no-caps/>
                </div>
              </q-card-section>
              <q-card-actions class="text-center q-pl-md q-pr-md q-pb-sm" align="center">
                <q-btn style="border-radius: 9px" padding="3px xl" type="submit" color="primary" no-caps
                       label="Logowanie"
                       :loading="loading"></q-btn>
              </q-card-actions>
              <q-card-actions class="text-center q-pt-none" align="center">
                <div class="text-center q-ma-none q-pa-none"> <!-- Add the text-center class to this div -->
                  <q-btn size="11px" flat color="secondary" @click="router.push({ name: 'ApplicantPasswordReset' });"
                         no-caps
                         label="Resetuj hasło"/>
                </div>
              </q-card-actions>
            </q-form>
          </q-card>
        </div>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script setup>
import AuthenticationService from '@/services/AuthenticationService';
import MenuBar from "@/components/common/MenuBar";
import {useQuasar} from 'quasar'
import {ref} from 'vue'
import {useRouter} from "vue-router";
import MobileMenu from "@/components/mobile/MobileMenu";
import {currentUser} from "@/global-variables/currentUser";

const $q = useQuasar();
const router = useRouter()
const email = ref('')
const password = ref('')
const loading = ref(false)

async function login() {
  loading.value = true;
  try {
    const response = await AuthenticationService.loginToApplicantAccount(email.value, password.value);
    if (await response.status === 201) {
      localStorage.setItem("isAuthenticated", "true")
      response.json().then(body => {
        Object.assign(currentUser, body);
        if (currentUser.isRequiredApplicationDataProvided()) {
          router.push({name: 'JobOffersMainBoard'});
        } else {
          let message = 'Aby aplikować na oferty wymagane jest uzupełnienie profilu. Dodaj imię, naziwsko oraz przynajmniej jedno CV.'
          router.push({name: 'ApplicantProfilePage', query: {infoMessage: message}});
        }
      })

    } else if (response.status === 404) {
      $q.notify({
        type: 'negative',
        message: 'Niepoprawny email lub hasło.'
      })
    } else if (response.status === 401) {
      const errorCode = await response.json().then(body => {
        return body.errorCode
      })
      if (errorCode === 'ACCOUNT_TEMPORARILY_LOCKED') {
        $q.notify({
          type: 'negative',
          message: 'Konto zablokowane na 5 minut - zbyt wiele błędnych prób logowania.'
        })
      } else {
        $q.notify({
          type: 'negative',
          message: 'Błędny login lub hasło.'
        })
      }
    }
  } catch (error) {
    console.error('Error during login:', error);
    throw error;  // or handle error as you see fit
  } finally {
    loading.value = false;
  }
}

function isMobile() {
  return $q.platform.is.mobile
}
</script>
<style>
.login-center {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -30%);
}
</style>