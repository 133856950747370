<template>
  <q-layout style="width: 100%;">
    <q-drawer
        v-model="drawerLeft"
        show-if-above
        @mouseover="miniStateLeft = false"
        @mouseout="miniStateLeft = true"
        :breakpoint="500"
        :width="250"
        class="bg-gradient"
        bordered
    >
      <div>
        <div class="text-center q-mt-lg">
          <q-img fit="contain" :src="logo" style="width: 100px; height: 100px"/>
        </div>

        <div class="text-center">
          <my-text class="text-bold text-white" style="font-size: 30px;">Byte Job</my-text>
        </div>
      </div>

      <q-list class="column q-col-gutter-sm" style="margin-top: 30px">
        <div class="q-mx-md">
          <q-btn
              rounded
              @click="router.push({ name: 'OrganizationAccountPage' });"
              dense
              flat
              style="border-radius: 6px"
              size="15px"
              icon="fa-regular fa-user"
              align="left"
              class="q-mx-lg full-width text-white"
              color="primary"
              label="Mój profil"
              no-caps
          />
        </div>
        <div class="q-mx-md">
          <q-btn
              rounded
              @click="router.push({ name: 'OrganizationJobOffersBoard' });"
              dense
              flat
              style="border-radius: 6px"
              size="15px"
              icon="fa-solid fa-briefcase"
              align="left"
              class="q-mx-lg full-width text-white bg-dark"
              color="primary"
              label="Ogłoszenia"
              no-caps
          />
        </div>
        <div class="q-mx-md">
          <q-btn
              rounded
              @click="router.push({ name: 'OrganizationAccountManagement' });"
              dense
              flat
              style="border-radius: 6px"
              size="15px"
              icon="fa-solid fa-address-book"
              align="left"
              class="q-mx-md full-width text-white"
              color="white"
              label="Konta"
              no-caps
          />
        </div>
        <div class="q-mx-md">
          <q-btn
              rounded
              @click="router.push({ name: 'OrganizationDataPage' });"
              dense
              flat
              style="border-radius: 6px"
              size="15px"
              icon="fa-solid fa-building"
              align="left"
              class="q-mx-md full-width text-white"
              color="white"
              label="Dane firmy"
              no-caps
          />
        </div>
        <div class="q-mx-md">
          <q-btn
              rounded
              @click="router.push({ name: 'InvoicesMainBoard' });"
              dense
              flat
              style="border-radius: 6px"
              size="15px"
              icon="fa-solid fa-file-lines"
              align="left"
              class="q-mx-md full-width text-white"
              color="white"
              label="Faktury"
              no-caps
          />
        </div>
        <div class="q-mx-md">
          <q-btn
              rounded
              @click="AuthenticationService.logout()"
              dense
              flat
              style="border-radius: 6px"
              size="15px"
              icon="fa-solid fa-arrow-right-from-bracket"
              align="left"
              class="q-mx-md full-width text-white q-mt-lg"
              color="primary"
              label="Wyloguj"
              no-caps
          />
        </div>
      </q-list>
    </q-drawer>
    <q-page-container style="background-color: rgb(217,225,238);" class="col row column">
      <q-page class="column col row">
        <!-- Menu bar and other content -->
        <menu-bar></menu-bar>
        <div v-if="isLoading">Loading...</div>
        <div v-else class="column col row">
          <!-- Job offer details section -->
          <div class="q-ml-sm">
            <organization-job-offer-details-nav-bar class="q-ma-xs" :job-offer-title="jobOffer.title"></organization-job-offer-details-nav-bar>
          </div>
          <div class="col row">
            <div class="col row">
              <div class="col row q-mb-md q-ml-md">
                <!-- Existing organization job offer details component -->
                <organization-job-offer-details
                    style="width: 100%"
                    padding-top="0px"
                    class="col row"
                    :show-apply="false"
                    :job-offer="jobOffer"
                    justifyContent="justify-start">
                </organization-job-offer-details>
              </div>
              <!-- Charts section -->
              <div class="q-mb-md q-ml-none q-mr-md column col row" style="width: 100%;">
                <!-- First chart -->
                <div class="q-mb-md col row" style="padding-left: 2px">
                  <apexchart
                      style="border-radius: 9px 9px 9px 9px; background-color: white;"
                      type="line"
                      class="q-ma-none col row"
                      height="98%"
                      width="100%"
                      :options="applicationsChartOptions"
                      :series="applicationsSeries"
                  />
                </div>
                <!-- Second chart -->
                <div class="col row" style="width: 100%">
                  <apexchart
                      style="border-radius: 9px 9px 9px 9px; background-color: white; "
                      type="line"
                      height="98%"
                      width="100%"
                      class="q-ma-none col row"
                      :options="viewsChartOptions"
                      :series="viewsSeries"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </q-page>
    </q-page-container>
  </q-layout>
</template>
<script setup>
import {onMounted, ref} from 'vue'

const router = useRouter()

const drawerLeft = ref(false)
const miniStateLeft = ref(true)

import {useRouter} from 'vue-router';
import MenuBar from "@/components/common/MenuBar";
import viewsChartService from "@/services/ViewsChartService";
import jobOfferService from "@/services/JobOfferService";
import applicationsChartService from "@/services/ApplicationsChartService";
import AuthenticationService from "@/services/AuthenticationService";
import OrganizationJobOfferDetailsNavBar
  from "@/components/organization/job-offers/details/OrganizationJobOfferDetailsNavBar";
import OrganizationJobOfferDetails from "@/components/organization/job-offers/details/OrganizationJobOfferDetails";

const jobOfferId = router.currentRoute.value.params.id

const isLoading = ref(true);
const jobOffer = ref(null);
const isVisible = ref(true)


const fetchData = async () => {
  try {
    const data = await jobOfferService.getJobOffersForOrganization();
    const selectedOffer = data.find(offer => offer.id === jobOfferId);
    if (selectedOffer) {
      jobOffer.value = selectedOffer;
      isVisible.value = selectedOffer.visible
      viewsData.value = viewsChartService.calculateViewsForOneOffer(selectedOffer);
      applicationData.value = applicationsChartService.dailyApplicationData(selectedOffer);
      applicationsChartOptions.value.xaxis.categories = applicationsChartService.get30DaysFromOfferPublicationDateAsArray(selectedOffer);
      viewsChartOptions.value.xaxis.categories = applicationsChartService.get30DaysFromOfferPublicationDateAsArray(selectedOffer);
    } else {
      console.warn("Selected job offer not found.");
    }
  } catch (error) {
    console.error(error);
  } finally {
    isLoading.value = false;
  }
};

onMounted(() => {
  fetchData();
});

const applicationsChartOptions = ref({
  legend: {
    fontSize: '9px'
  },
  chart: {

    toolbar: {
      show: false
    },
    id: 'forecast-chart',
    type: 'bar',
    stacked: true,
  },
  plotOptions: {
    bar: {
      columnWidth: '40%', // Adjust this value to change the bar width
      horizontal: false, // Vertical bars for grouped chart
      group: 'series',   // Group by series
    }
  },
  dataLabels: {
    style: {
      colors: ['#ffffff']
    },
    enabled: false
  },
  stroke: {
    width: 1,
    curve: 'smooth',
    colors: ['#ffffff']
  },
  xaxis: {
    labels: {
      style: {
        fontSize: '11px', // Set your desired font size
        colors: '#868686'
      },
    },
    categories: []
  },
  fill: {
    type: 'gradient',
    gradient: {
      shade: 'dark',
      shadeIntensity: 1,
      type: 'vertical',
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 100, 100, 100],
      gradientToColors: ['#a3e5d3'] // Green and purple colors
    },
  },

  yaxis: {
    labels: {
      formatter: function (value) {
        return Math.floor(value); // Rounds down to remove decimal places
      },
      style: {
        colors: '#868686' // Change this to your desired label color
      }
    },
    title: {
      text: 'Liczba CV dla poszczególnych ofert',
      style: {
        fontStyle: 'Verdana',// Change this to your desired label color
        color: '#4382c9' // Change this to your desired label color
      }
    },
  }
});
const viewsChartOptions = ref({
  legend: {
    fontSize: '9px'
  },
  chart: {
    height: 'auto',

    toolbar: {
      show: false
    },
    id: 'forecast-chart',
    type: 'bar',
    stacked: true,
  },
  dataLabels: {
    enabled: false,
    style: {
      colors: ['#a5ffe7']
    },
  },
  stroke: {
    width: [3],
    curve: ['smooth'],
    colors: ['#a2a8ec']
  },

  xaxis: {
    labels: {
      style: {
        colors: '#868686',
        fontSize: '11px', // Set your desired font size
      },
    },
    categories: [],
  },
  fill: {
    type: 'gradient',
    gradient: {
      shade: 'dark',
      shadeIntensity: 1,
      type: 'vertical',
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 100, 100, 100],
      gradientToColors: ['#a2ece3'] // Green and purple colors
    },
  },

  yaxis:
      {
        labels: {
          style: {
            colors: '#868686' // Change this to your desired label color
          }
        },
        title: {
          text: 'Liczba wyświetleń dla wszystkich ofert',
          style: {
            fontStyle: 'Verdana',
            color: '#4382c9' // Change this to your desired label color
          }
        },
      }
});

const viewsData = ref([])
const viewsSeries = ref([
  {
    name: 'Sumaryczna liczba wyświetleń oferty',
    data: viewsData,
  },
]);

const applicationData = ref([])
const applicationsSeries = ref([
  {
    name: 'Sumaryczna liczba wyświetleń oferty',
    data: applicationData,
    yaxis: 0,
  },
]);
</script>

<style lang="scss">
.bg-my {
  background: linear-gradient(45deg, $primary, $secondary)
}

.bg-my-right {
  background: linear-gradient(180deg, $primary, $secondary)
}

.bg-my-bottom {
  background: linear-gradient(45deg, $secondary, $primary)
}
</style>