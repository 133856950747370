<template>
  <q-dialog v-model="showFavButtonNotAvailable">
    <q-card v-if="currentUser.isApplicant()" style="width: 250px; border-radius: 9px;">
      <q-card-section class="row justify-center q-px-none q-pt-md q-pb-md">
        <div class="text-center text-h7 text-grey-9">
          <div class="q-pt-none q-pb-sm q-mb-xs">
            <q-icon size="md" name="fa-solid fa-circle-info" color="positive"></q-icon>
          </div>
          <span style="font-size: 12px">Aby dodać ofertę do ulubionych</span>
          <div class="row">
            <div class="text-center q-pt-none q-pb-none q-mt-xs q-px-sm"> <!-- Add the text-center class to this div -->
              <q-btn size="13px" flat color="accent" @click="router.push({ name: 'ApplicantLoginPage' });"
                     label="Zaloguj się"
                     dense
                     no-caps/>
            </div>
            <span class="q-mx-xs" style="font-size: 12px; padding-top: 10px">lub</span>
            <q-btn size="13px" flat color="primary" @click="router.push({ name: 'EmployeeRegistration' });"
                   label="Zarejestruj"
                   class="text-center q-pt-none q-pb-none q-mt-xs q-px-sm"
                   dense
                   no-caps/>
          </div>
        </div>
      </q-card-section>
    </q-card>
    <q-card v-else style="width: 280px; border-radius: 9px;">
      <q-card-section class="row justify-center q-px-none q-pt-md q-pb-md">
        <div class="text-center text-h7 text-grey-9">
          <div class="q-pt-none q-pb-sm q-mb-xs">
            <q-icon size="md" name="fa-solid fa-circle-info" color="positive"></q-icon>
          </div>
          <span style="font-size: 12px">Aby dodać ofertę do ulubionych</span>
          <div class="row">
            <div class="text-center q-pt-none q-pb-none q-mt-xs q-px-sm"> <!-- Add the text-center class to this div -->
              <q-btn size="13px" flat color="accent" @click="router.push({ name: 'ApplicantLoginPage' });"
                     label="Zaloguj się"
                     dense
                     no-caps/>
            </div>
            <span class="q-mx-xs" style="font-size: 12px; padding-top: 10px">lub</span>
            <q-btn size="13px" flat color="primary" @click="router.push({ name: 'EmployeeRegistration' });"
                   label="zarejestruj"
                   class="text-center q-pt-none q-pb-none q-mt-xs q-px-sm"
                   dense
                   no-caps/>
          </div>
          <span style="font-size: 12px">używając konta aplikanta</span>

        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
  <q-card bordered class="q-mt-none q-mb-md"
          style="border-width: 0 0 2px 0; border-radius: 9px;"
          :style="calculateBorderColor()"
          @mouseover="toggleMouseOverCard(true)"
          @mouseout="toggleMouseOverCard(false)"
          :class="{ 'q-hoverable': !isMouseOverFavBtn, 'cursor-pointer': !isMouseOverFavBtn, 'my-border': !isSelected && !isMouseOverFavBtn }"
          @click="returnId(props.jobOffer.id)">

    <span class="row col" :class="{ 'q-focus-helper': !isMouseOverFavBtn}" style="color: #3e799b">
    </span>
    <q-card-section class="q-px-none q-pt-none q-ml-sm q-pb-none">
      <div class="row">
        <div class="q-pa-xs" style=" display: flex;   flex-direction: column;
  justify-content: center;
  align-items :center"
        >
          <q-img
              class="bg-white"
              style="width: 45px;height: 45px; border-radius: 6px;"
              v-bind:src="props.jobOffer.organization.logoUrl"
              fit='fill'
              :ratio="1"
          />
        </div>
        <div class="col">
          <div class="row col">
            <div class="col q-pl-xs q-pt-sm q-pr-xs">
              <div class="justify-between col">
                <div class="q-pa-none row justify-between q-mr-md">
                  <div class="row col-auto q-pr-sm">
                    <p :class="getTextColorBasedOnIfSelected()"
                       style="font-size: 16px; z-index: 10"
                       id="title" ref="jobTitle"
                       class="q-pl-sm q-mb-none ellipsis col"
                       :style="getFontWeightBasedOnIfSelected()">
                      <q-tooltip v-if="jobTitle !== null && isEllipsis" class="bg-white text-black">
                        {{ props.jobOffer.title }}
                      </q-tooltip>
                      {{ props.jobOffer.title }}
                    </p>
                  </div>
                  <div class="col">
                    <q-icon
                        v-if="jobOffer.highlighted"
                        name="fa-solid fa-fire"
                        class="col"
                        size="18px"
                        style="border-radius: 6px"
                        outline color="accent"
                    >
                    </q-icon>
                  </div>
                </div>
              </div>
            </div>

            <div class="q-mr-sm q-mb-none row q-ml-md" style="margin-top: 11px">
              <p class="q-mb-none text-weight-bold" v-if="isSelected"
                 style="border-width: 1px; max-height: 23px; font-size: 14px; font-weight: 800"
                 :class="getSalaryColor()">
                {{ salaryToString() }}
              </p>
              <p class="q-mb-none" v-else
                 style="border-width: 1px; max-height: 23px; font-size: 14px; font-weight: 400"
                 :class="getSalaryColor()">
                {{ salaryToString() }}
              </p>
            </div>
            <div style="z-index: 10000" class="q-mt-xs">
              <q-btn v-if="isFav" flat @click="addObservedOfferForLoggedUser(currentUser.id)"
                     @mouseover="toggleMouseOverFavBtn(true)"
                     @mouseout="toggleMouseOverFavBtn(false)"
                     no-caps
                     class="q-mt-none q-mx-xs q-pa-xs q-mr-sm q-ml-xs"
                     color="accent">
                <q-icon size="xs" name="fa-solid fa-star" class="q-py-none q-px-sm q-pl-none"/>
              </q-btn>
              <q-btn id="myElement" v-else flat @click="addObservedOfferForLoggedUser(currentUser.id)"
                     class="q-mt-none q-mx-xs q-pa-xs q-mr-sm q-ml-xs"
                     @mouseover="toggleMouseOverFavBtn(true)"
                     @mouseout="toggleMouseOverFavBtn(false)"
                     no-caps
                     color="grey-5">
                <q-icon size="xs" name="fa-solid fa-star" class="q-py-none q-px-sm q-pl-none"/>
              </q-btn>
            </div>
          </div>
          <div class="col-auto row q-pl-sm col">
            <!--            <div class="col row">-->
            <!--              <q-icon class="q-pt-xs" name="fa-regular fa-building" :color="getColorBasedOnIfSelected()" size="16px"/>-->
            <!--            </div>-->
            <div class="col-auto row">
              <p class="q-pl-xs q-px-xs q-pt-none q-ma-none ellipsis col"
                 :class="getGreyTextColorBasedOnIfSelected()"
                 style="font-size: small;">
                {{ props.jobOffer.organization.name }}</p>
              <locations-info-dropdown
                  class="q-ml-md col"
                  :is-selected="isSelected"
                  style="padding-top: 0px"
                  :locations="props.jobOffer.locations">
              </locations-info-dropdown>
            </div>
          </div>
        </div>
      </div>
    </q-card-section>
    <q-card-section class="q-pa-none">
    </q-card-section>
    <q-separator/>
    <q-card-actions class="justify-between q-mx-xs q-pt-sm q-py-xs">
      <div class="q-gutter-sm">
        <q-badge v-for="(skill) in props.jobOffer.skills.slice(0, 6)"
                 :key="skill.name"
                 :label="skill.name"
                 outline
                 :color="getColorBasedOnIfSelected()"
                 style="font-weight: 400;border-radius: 4px; font-size: 10px"/>
      </div>

      <div v-if="isSelected" class="q-pa-none q-gutter-sm">
        <q-badge :label="props.jobOffer.seniority"
                 outline color="grey-9"
                 style="font-weight: 800;border-radius: 4px; font-size: 10px"/>
      </div>
      <div v-else class="q-pa-none q-gutter-sm ">
        <q-badge :label="props.jobOffer.seniority" outline color="grey-6"
                 style="font-weight: 400;border-radius: 4px; font-size: 10px"/>
      </div>
    </q-card-actions>
  </q-card>
</template>

<script setup>
import {defineEmits, defineProps, onMounted, onUnmounted, ref} from "vue";
import applicantObservedOffers from "@/services/applicant/ApplicantObservedOffers";
import LocationsInfoDropdown from "@/components/common/job-offers/LocationsInfoDropdown";
import {currentUser} from "@/global-variables/currentUser";
import {useRouter} from "vue-router/dist/vue-router";
import OrganizationUsersService from "@/services/organization/OrganizationUsersService";

const props = defineProps({
  jobOffer: Object,
  isSelected: Boolean,
  borderColor: String,
  isFav: Boolean
})

const router = useRouter()
const isEllipsis = ref(false)
const resizeDetector = ref(true)
const jobTitle = ref(null);
const showFavButtonNotAvailable = ref(false)
onMounted(() => {
  window.addEventListener("resize", myEventHandler);
  isFav.value = props.isFav
  isEllipsis.value = isEllipsisActive()
})

onUnmounted(() => {
  window.removeEventListener("resize", myEventHandler);
})

function myEventHandler() {
  isEllipsis.value = isEllipsisActive()
}

function isEllipsisActive() {
  let e = jobTitle.value
  if (e != null) {
    return e.offsetWidth < e.scrollWidth && resizeDetector.value
  } else {
    return false;
  }
}

const emit = defineEmits(['jobOffer:id', 'update:fav'])

function returnId(id) {
  if (!isMouseOverFavBtn.value) {
    emit('jobOffer:id', id)
  }
}

function salaryToString() {
  if (props.jobOffer.salary !== null) {
    const from = props.jobOffer.salary.from.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    const to = props.jobOffer.salary.to.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return from + ' - ' + to + ' ' + props.jobOffer.salary.currency;
  } else {
    return 'Nie podano stawki';
  }
}


function getSalaryColor() {
  if (props.isSelected) {
    return 'text-accent'
  } else {
    return 'text-grey-6'
  }
}

const isFav = ref(false)

function addObservedOfferForLoggedUser(userId) {
  if (currentUser.isAuthenticated()) {
    if (currentUser.isApplicant()) {
      if (isFav.value) {
        isFav.value = false;
        applicantObservedOffers.deleteObservedOffers(userId, props.jobOffer.id)
        currentUser.favoriteOffersIds = currentUser.favoriteOffersIds.filter(offerId => offerId !== props.jobOffer.id);
      } else {
        isFav.value = true;
        applicantObservedOffers.addObservedOffers(userId, props.jobOffer.id)
        currentUser.favoriteOffersIds.push(props.jobOffer.id)
      }
    } else if (currentUser.isOrganizationUser()) {
      if (isFav.value) {
        isFav.value = false;
        OrganizationUsersService.deleteObservedOffers(currentUser, props.jobOffer.id)
        currentUser.favoriteOffersIds = currentUser.favoriteOffersIds.filter(offerId => offerId !== props.jobOffer.id);
      } else {
        isFav.value = true;
        OrganizationUsersService.addObservedOffers(currentUser, props.jobOffer.id)
        currentUser.favoriteOffersIds.push(props.jobOffer.id)
      }
    }

  } else {
    showFavButtonNotAvailable.value = true
  }
}

const isMouseOverFavBtn = ref(false)

function toggleMouseOverFavBtn(val) {
  isMouseOverFavBtn.value = val;
}

const isMouseOverCard = ref(false)

function toggleMouseOverCard(val) {
  isMouseOverCard.value = val;
}

function getColorBasedOnIfSelected() {
  if (props.isSelected) {
    return 'grey-9'
  } else {
    return 'grey-6'
  }
}

function getGreyTextColorBasedOnIfSelected() {
  if (props.isSelected) {
    return 'text-grey-9'
  } else {
    return 'text-grey-6'
  }
}

function getTextColorBasedOnIfSelected() {
  if (props.jobOffer.highlighted && !props.isSelected) {
    return 'text-accent'
  } else if (props.isSelected) {
    return 'text-primary'
  } else {
    return 'text-grey-6'
  }
}

function calculateBorderColor() {
  if (props.isSelected) {
    return 'border-color: #103279'
  } else {
    return 'border-color: #ffffff'
  }
}

function getFontWeightBasedOnIfSelected() {
  if (props.isSelected || props.jobOffer.highlighted) {
    return 'font-weight: 600'
  } else {
    return 'font-weight: 300'
  }
}
</script>

<style lang="scss" scoped>
.my-border {
  color: #666;
  position: relative;
}

.my-border:after {
  position: absolute;
  left: 0%;
  top: 4%;
  content: '';
  border-radius: 9px;
  height: 100%;
  transition: all 0.3s linear;
  width: 100%;
  border-bottom: 2px solid #151273;
  transform: scaleX(0);
}

.shrinkable {
  flex: 1 1 auto; /* Allow the column to shrink */
  min-width: 0; /* Prevents content from pushing the column to a wider width */
}

.my-border:hover:after {
  transform: scale(1);
}
</style>