<template>
  <q-layout>
    <q-drawer
        v-model="drawerLeft"
        show-if-above
        @mouseover="miniStateLeft = false"
        @mouseout="miniStateLeft = true"
        :breakpoint="500"
        :width="250"
        class="bg-gradient"
        bordered
    >
      <div>
        <div class="text-center q-mt-lg">
          <q-img fit="contain" :src="logo" style="width: 100px; height: 100px"/>
        </div>

        <div class="text-center">
          <my-text class="text-bold text-white" style="font-size: 30px;">Byte Job</my-text>
        </div>
      </div>

      <q-list class="column q-col-gutter-sm" style="margin-top: 30px">
        <div class="q-mx-md">
          <q-btn
              rounded
              @click="router.push({ name: 'OrganizationAccountPage' });"
              dense
              flat
              style="border-radius: 6px"
              size="15px"
              icon="fa-regular fa-user"
              align="left"
              class="q-mx-lg full-width text-white"
              color="primary"
              label="Mój profil"
              no-caps
          />
        </div>
        <div class="q-mx-md">
          <q-btn
              rounded
              @click="router.push({ name: 'OrganizationJobOffersBoard' });"
              dense
              flat
              style="border-radius: 6px"
              size="15px"
              icon="fa-solid fa-briefcase"
              align="left"
              class="q-mx-lg full-width text-white"
              color="primary"
              label="Ogłoszenia"
              no-caps
          />
        </div>
        <div class="q-mx-md">
          <q-btn
              rounded
              @click="router.push({ name: 'OrganizationAccountManagement' });"
              dense
              flat
              style="border-radius: 6px"
              size="15px"
              icon="fa-solid fa-address-book"
              align="left"
              class="q-mx-md full-width text-white"
              color="white"
              label="Konta"
              no-caps
          />
        </div>
        <div class="q-mx-md">
          <q-btn
              rounded
              @click="router.push({ name: 'OrganizationDataPage' });"
              dense
              flat
              style="border-radius: 6px"
              size="15px"
              icon="fa-solid fa-building"
              align="left"
              class="q-mx-md full-width text-white"
              color="white"
              label="Dane firmy"
              no-caps
          />
        </div>
        <div class="q-mx-md">
          <q-btn
              rounded
              @click="router.push({ name: 'InvoicesMainBoard' });"
              dense
              flat
              style="border-radius: 6px"
              size="15px"
              icon="fa-solid fa-file-lines"
              align="left"
              class="q-mx-md full-width text-white"
              color="white"
              label="Faktury"
              no-caps
          />
        </div>
        <div class="q-mx-md">
          <q-btn
              rounded
              @click="AuthenticationService.logout()"
              dense
              flat
              style="border-radius: 6px"
              size="15px"
              icon="fa-solid fa-arrow-right-from-bracket"
              align="left"
              class="q-mx-md full-width text-white q-mt-lg"
              color="primary"
              label="Wyloguj"
              no-caps
          />
        </div>
      </q-list>
    </q-drawer>
    <q-page-container class="column col row" style="background-color: rgb(217,225,238);">
      <q-page class="column">
        <menu-bar></menu-bar>
        <q-dialog v-model="unauthorizedDialog" @before-hide="redirectToPreviousPageOrAvailableIfNotPossible()">
          <q-card style="width: 300px; border-radius: 9px;">
            <q-card-section class="row justify-center q-px-md q-pt-md q-pb-md">
              <div class="text-h7 text-grey-9 text-center">
                <div class="q-pt-none q-pb-sm q-mb-none text-center">
                  <q-icon size="md" name="fa-solid fa-fingerprint" color="pink-5" class=""></q-icon>
                </div>
                <div>
                  <span class="text-center" style="font-size: 13px">Nie masz uprawnień do tego panelu.</span>
                </div>
                <div>
                  <span class="text-center" style="font-size: 13px">Skontaktuj się z adminem aby otrzymać</span>
                </div>
                <div>
                  <span class="text-center" style="font-size: 13px">dostęp.</span>
                </div>
              </div>
            </q-card-section>
            <div class="text-center q-mb-md">
              <q-btn @click="redirectToPreviousPageOrAvailableIfNotPossible()" outline style="border-radius: 7px"
                     class="q-px-md" dense label="Powrót"
                     no-caps
                     color="primary"></q-btn>
            </div>
          </q-card>
        </q-dialog>
      </q-page>
    </q-page-container>
  </q-layout>
</template>
<script setup>
import MenuBar from "@/components/common/MenuBar";
import AuthenticationService from "@/services/AuthenticationService";
import {useRouter} from "vue-router/dist/vue-router";
import {onMounted, ref} from "vue";
import {currentUser} from "@/global-variables/currentUser";

const router = useRouter()
const unauthorizedDialog = ref(true)
const previousPage = ref(null)
onMounted(() => {
  if (router.currentRoute.value.query.rootPath === null
      || router.currentRoute.value.query.rootPath === undefined
      || isRedirectionDirectlyAfterLogin()) {
    toFirstAvailablePage(currentUser)
  } else {
    previousPage.value = router.currentRoute.value.query.rootPath;
  }
})

function isRedirectionDirectlyAfterLogin() {
  return router.currentRoute.value.query.rootPath === '/organization/login';
}

function toPrevPage() {
  router.push(previousPage.value)
}

const availableRoutes = [
  {"path": "/organization/offers", "permissionName": "OFFERS"},
  {"path": "/organization/accounts", "permissionName": "USERS"},
  {"path": "/organization/data", "permissionName": "ORGANIZATION"}
];

function redirectToPreviousPageOrAvailableIfNotPossible() {
  if (userHasAccessToPreviousPage()) {
    toPrevPage()
  } else {
    toFirstAvailablePage()
  }
}

function userHasAccessToPreviousPage() {

  let route = getRouteByPath(previousPage.value)
  return hasAccessToPage(route)
}

function toFirstAvailablePage() {

  for (const route of availableRoutes) {
    if (hasAccessToPage(route)) {
      router.push(route.path);
      return;
    }
  }
  router.push({name: 'OrganizationAccountPage'});
}

function hasAccessToPage(route) {
  if (route.path === '/organization/profile') {
    return true
  } else {
    return currentUser.hasAccessTo(route.permissionName)
  }
}

function getRouteByPath(path) {
  const route = availableRoutes.find(route => route.path === path);
  console.info(route)
  if (!route) {
    return {"path": "/organization/profile", "permissionName": "CURRENT_USER_PROFILE"};
  }
  return route;
}


</script>
