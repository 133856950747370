<template>
  <div class="q-pa-xs col row column" >
    <div class="full-width q-px-sm q-mt-sm" style="overflow-y: auto; display: flex; justify-content: center;">
      <div style="width: 70%" class="row justify-end">
        <div class="q-mx-md">
          <q-select dense
                    standout="bg-primary"
                    style="font-size: 10px;border-radius: 7px;width: fit-content"
                    transition-show="scale"
                    transition-hide="scale"
                    v-model="sorting"
                    class="q-ma-sm bg-white"
                    :popup-content-style="{borderRadius: '0 0 7px 7px', fontSize: '12px' }"
                    :options="sortingOptions"
                    :options-dense="true"
                    @update:model-value="fetchInvoices()"/>
        </div>
      </div>
    </div>

    <div class="full-width q-scrollarea col q-px-sm" style="overflow-y: auto; display: flex; justify-content: center;">
      <div class="q-mx-lg q-mb-lg q-mt-none"
           v-if="invoicesReady"
           style="width: 70%;"> <!-- Adjust width as needed -->
        <invoice-overview
            class="q-ma-lg"
            v-for="(account, index) in invoices"
            :key="account.id"
            @account:deactivate="removeDeactivated"
            v-model:invoice="invoices[index]"
            @admin:pass="showAndHidePassAdminConfirmationDialog"
        ></invoice-overview>
      </div>
    </div>

    <q-dialog v-model="showConfirmationDialog">
      <q-card>
        <q-card-section class="row q-pb-sm">
          <div class="q-pr-md q-mt-xs">
            <q-avatar icon="key" color="accent" text-color="white"/>
          </div>
          <div class="col">
            <p class="q-ml-sm q-mb-none text-grey-8">Czy na pewno chcesz przekazać uprawnienia admina do <span
                style="color: #3bb6c4">{{ passAdminTo }}</span>? Potwierdzając wyrażasz zgodę na wylogowanie.</p>
          </div>
        </q-card-section>
        <div class="q-mx-md q-mb-sm">
          <q-separator></q-separator>
        </div>
        <q-card-actions align="right" class="q-pt-none q-mr-none">
          <q-btn class="q-mr-sm" outline flat label="Nie" color="primary" v-close-popup/>
          <q-btn class="q-mr-sm" flat label="Tak" color="primary" v-close-popup @click="passAdmin"/>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>


<script setup>
import {onMounted, ref} from "vue";
import InvoiceOverview from "@/components/organization/invoices/InvoiceOverview";
import OrganizationInvoiceService from "@/services/organization/payments/OrganizationInvoiceService";

const passAdminTo = ref('')
const invoices = ref([]); // Using ref to make it reactive
const invoicesReady = ref(false)
// Fetch and populate the accounts
onMounted(() => {
  async function init() {
    await fetchInvoices();
  }

  init();
});

async function fetchInvoices() {
  invoices.value = []
  const firstPageData = await OrganizationInvoiceService.getInvoices(0, mapSortingOptionToConstant(sorting.value));
  const totalPages = firstPageData.page.totalPages;
  invoices.value.push(...firstPageData.content); // Update the value of ref directly
  for (let currentPage = 1; currentPage < totalPages; currentPage++) {
    const data = await OrganizationInvoiceService.getInvoices(currentPage, mapSortingOptionToConstant(sorting.value));
    invoices.value.push(...data.content); // Concatenate data to the end of the array
  }
  invoicesReady.value = true
}


function removeDeactivated(id) {
  invoices.value = invoices.value.filter(acc => acc.id !== id);
}

const showConfirmationDialog = ref(false);
const passAdminUserId = ref(null)

function showAndHidePassAdminConfirmationDialog(email, id) {
  passAdminTo.value = email
  passAdminUserId.value = id
  showConfirmationDialog.value = true;
}

const sorting = ref('Najnowsze');
const sortingOptions = ref([
  'Najnowsze', 'Najstarsze',
])

function mapSortingOptionToConstant(sortingOption) {
  switch (sortingOption) {
    case 'Najnowsze':
      return 'NEWEST';
    case 'Najstarsze':
      return 'OLDEST';
  }
}

</script>

<style scoped>
</style>
