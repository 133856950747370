<template>
  <div style="height: 100vh">
    <q-layout view="hHh Lpr lff" container>
      <q-drawer
          v-model="drawerLeft"
          show-if-above
          @mouseover="miniStateLeft = false"
          @mouseout="miniStateLeft = true"
          :breakpoint="500"
          :width="250"
          class="bg-gradient"
          bordered
      >
        <div>
          <div class="text-center q-mt-lg">
            <q-img fit="contain" :src="logo" style="width: 100px; height: 100px"/>
          </div>

          <div class="text-center">
            <my-text class="text-bold text-white" style="font-size: 30px;">Byte Job</my-text>
          </div>
        </div>

        <q-list class="column q-col-gutter-sm" style="margin-top: 30px">
          <div class="q-mx-md">
            <q-btn
                rounded
                @click="router.push({ name: 'OrganizationAccountPage' });"
                dense
                flat
                style="border-radius: 6px"
                size="15px"
                icon="fa-regular fa-user"
                align="left"
                class="q-mx-lg full-width text-white"
                color="primary"
                label="Mój profil"
                no-caps
            />
          </div>
          <div class="q-mx-md">
            <q-btn
                rounded
                @click="router.push({ name: 'OrganizationJobOffersBoard' });"
                dense
                flat
                style="border-radius: 6px"
                size="15px"
                icon="fa-solid fa-briefcase"
                align="left"
                class="q-mx-md full-width text-white"
                color="primary"
                label="Ogłoszenia"
                no-caps
            />
          </div>
          <div class="q-mx-md">
            <q-btn
                rounded
                @click="router.push({ name: 'OrganizationAccountManagement' });"
                dense
                flat
                style="border-radius: 6px"
                size="15px"
                icon="fa-solid fa-address-book"
                align="left"
                class="q-mx-md full-width text-white"
                color="white"
                label="Konta"
                no-caps
            />
          </div>
          <div class="q-mx-md">
            <q-btn
                rounded
                @click="router.push({ name: 'OrganizationDataPage' });"
                dense
                flat
                style="border-radius: 6px"
                size="15px"
                icon="fa-solid fa-building"
                align="left"
                class="q-mx-lg full-width text-white bg-dark"
                color="white"
                label="Dane firmy"
                no-caps
            />
          </div>
          <div class="q-mx-md">
            <q-btn
                rounded
                @click="router.push({ name: 'InvoicesMainBoard' });"
                dense
                flat
                style="border-radius: 6px"
                size="15px"
                icon="fa-solid fa-file-lines"
                align="left"
                class="q-mx-md full-width text-white"
                color="white"
                label="Faktury"
                no-caps
            />
          </div>
          <div class="q-mx-md q-mt-md">
            <q-btn
                rounded
                @click="AuthenticationService.logout()"
                dense
                flat
                style="border-radius: 6px"
                size="15px"
                icon="fa-solid fa-arrow-right-from-bracket"
                align="left"
                class="q-mx-md full-width text-white q-mt-sm"
                color="primary"
                label="Wyloguj"
                no-caps
            />
          </div>
        </q-list>
      </q-drawer>
      <q-page-container class="col row column" style="background-color: rgb(217,225,238);">
        <q-page class="column col row">
          <menu-bar></menu-bar>
          <div class="col row justify-center q-scrollarea" style="overflow-y: auto">
            <q-card v-if="organizationData && formTemplateData" class="q-ma-lg column"
                    style="border-radius: 9px; max-width: 800px">
              <q-card-section class="col q-pb-none">
                <basic-organization-data v-if="organizationData && formTemplateData"
                                         :form-template-data="formTemplateData"
                                         v-model="organizationData"></basic-organization-data>
              </q-card-section>
              <q-card-section class="q-mx-sm q-mt-sm">
                <div>
                  <invoice-organization-data v-if="organizationData && formTemplateData"
                                             :form-template-data="formTemplateData"
                                             v-model="organizationData"></invoice-organization-data>
                </div>

              </q-card-section>
              <q-card-section class="q-pl-none q-pt-none">
                <div class="justify-start q-mx-lg">
                  <q-btn flat style="width: auto" size="md" icon="send" align="center" no-caps
                         label="Aktualizuj dane"
                         class="q-mr-md bg-primary text-white q-mt-md" @click="checkDataAndShowConfirmationDialog()"/>
                  <q-btn flat style="width: auto;" size="md" icon="clear" align="center" no-caps
                         label="Cofnij edycje danych"
                         class="bg-dark text-primary q-mt-md" @click="initOrganizationInfoData()"/>
                </div>
              </q-card-section>
            </q-card>
            <q-dialog v-model="showConfirmationDialog">
              <q-card style="border-radius: 9px">
                <q-card-section class="row q-pb-sm">
                  <div class="q-pt-none q-pb-sm q-mb-xs">
                    <q-icon size="md" name="fa-solid fa-circle-info" color="primary"></q-icon>
                  </div>
                  <div class="col q-mt-xs q-ml-sm">
                    <p class="q-ml-sm q-mb-none text-grey-8">Czy na pewno chcesz zaktualizować dane organizacji?</p>
                  </div>
                </q-card-section>
                <div class="q-mx-md q-mb-sm">
                  <q-separator></q-separator>
                </div>
                <q-card-actions align="right" class="q-pt-none q-mr-none">
                  <q-btn dense class="q-mr-sm" flat label="Nie" color="primary" v-close-popup/>
                  <q-btn dense class="q-mr-sm" label="Tak" color="primary" v-close-popup
                         @click="updateOrganizationData(organizationData)"/>
                </q-card-actions>
              </q-card>
            </q-dialog>
          </div>
        </q-page>
      </q-page-container>
    </q-layout>
  </div>
</template>
<script setup>
import {onMounted, reactive, ref} from "vue";
import {useRouter} from "vue-router/dist/vue-router";
import MenuBar from "@/components/common/MenuBar";
import BasicOrganizationData from "@/components/organization/data/BasicOrganizationData";
import {useQuasar} from "quasar";
import OrganizationDataService from "@/services/organization/OrganizationDataService";
import organizationService from "@/services/organization/OrganizationDataService";
import NotificationService from "@/services/notification/NotificationService";
import InvoiceOrganizationData from "@/components/organization/data/InvoiceOrganizationData";
import AuthenticationService from "@/services/AuthenticationService";
import OrganizationDataTranslator from "@/services/organization/OrganizationDataTranslator";

onMounted(() => {

})
const drawerLeft = ref(false)
const miniStateLeft = ref(true)
const router = useRouter()

const organizationData = reactive({
  name: "",
  webPageUrl: "",
  branding: {
    primaryColor: "white",
    secondaryColor: "white"
  },
  type: "",
  address: {
    "street": "Ćwiartki",
    "streetNumber": "3",
    "flatNumber": "4",
    "city": "Wrocław",
    "zipCode": "50-449"
  },
  description: '<strong>Dodaj informacje o firmie:</strong> <ul>\n' +
      '  <li>Charakterystyka</li>\n' +
      '  <li>Projekty</li>\n' +
      '  <li>Styl pracy</li>\n' +
      '</ul>',
  industries: [],
  size: "",
  logoUrl: "",
  billingData: {
    companyName: "",
    nip: "",
    address: {
      street: "",
      city: "",
      flatNumber: "",
      streetNumber: "",
      zipCode: ""
    }
  }
});

const formTemplateData = ref(null)

function getFormtTemplateData() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json"
    },
  };
  return fetch(process.env.VUE_APP_BACKEND_URL + "/job-offers/form-template-data", requestOptions)
      .then((response) => {
        return response.json().then((data) => {
          return data
        }).catch(() => {
          // console.log(err);
        })
      })
}

onMounted(() => {
  getFormtTemplateData().then(data => {
    formTemplateData.value = data;
  });
  initOrganizationInfoData();
})
const $q = useQuasar();
const showConfirmationDialog = ref(false);

function checkDataAndShowConfirmationDialog() {
  if (OrganizationDataService.areMandatoryFieldsMissing(organizationData)) {
    NotificationService.showNotification($q, 'negative', 'Dodaj wymagane dane przed publikacją oferty: logo, rodzaj firmy, branża, liczba pracowników')
  } else {
    showConfirmationDialog.value = true
  }
}

function initOrganizationInfoData() {
  organizationService.getOrganizationInfo().then(response => {
    response.json().then(data => {
      organizationData.logoUrl = data.logoUrl;
      organizationData.name = data.name;
      organizationData.description = data.description
      organizationData.type = OrganizationDataTranslator.translateFromCompanyTypeToPolish(data.type)
      organizationData.size = OrganizationDataTranslator.translateSizeToPolish(data.size);
      organizationData.branding = data.branding;
      organizationData.industries = OrganizationDataTranslator.translateIndustryToPolish(data.industries[0]);
      if (data.billingData !== null) {
        organizationData.billingData = data.billingData;
      } else {
        resetBillingData()
      }
      if (OrganizationDataService.areMandatoryFieldsMissing(data)) {
        NotificationService.showNotification($q, 'negative', 'Dodaj wymagane dane przed publikacją oferty: logo, rodzaj firmy, branża, liczba pracowników')
      }
    });
  })
}

function resetBillingData() {
  organizationData.billingData.companyName = "";
  organizationData.billingData.nip = "";
  organizationData.billingData.address.street = "";
  organizationData.billingData.address.city = "";
  organizationData.billingData.address.zipCode = "";
  organizationData.billingData.address.flatNumber = "";
  organizationData.billingData.address.streetNumber = "";
}

function updateOrganizationData(organization) {
  if (isInvoiceFieldsAreMandatory(organization)) {
    const organizationDataForRequest = Object.assign({}, organization);
    if (isBillingDataNotSet(organization)) {
      delete organizationDataForRequest.billingData;
    }
    organizationDataForRequest.type = OrganizationDataTranslator.translateFromPolishToCompanyType(organizationData.type)
    organizationDataForRequest.size = OrganizationDataTranslator.translateFromPolishToCompanySize(organizationData.size);
    //TODO support multiple industries
    organizationDataForRequest.industries = [OrganizationDataTranslator.translateFromPolishToCompanyIndustry(organizationData.industries)];
    if (validatePolishPostalCode(organizationDataForRequest)) {
      organizationService.updateOrganizationInfo(organizationDataForRequest).then(response => {
        if (response.ok) {
          NotificationService.showNotification($q, 'positive', 'Zaktualizowano dane profilu')
        } else {
          response.json().then(body => {
            if (body.errorCode === 'INVALID_NIP') {
              NotificationService.showNotification($q, 'negative', 'Niepoprawny NIP')

            } else {
              NotificationService.showNotification($q, 'negative', 'Błąd podczas zapisu danych: ' + body.message)
            }
          })
        }
      })
    }
  } else {
    NotificationService.showNotification(
        $q,
        'negative',
        'Aby zapisać dane do faktury uzupełnij pola'
    );
  }
}

function isBillingDataNotSet(organization) {
  return isNullOrWhiteSpace(organization.billingData.companyName)
}

function isBlank(str) {
  return /^\s*$/.test(str);
}

function isNullOrWhiteSpace(str) {
  return str === null || str === undefined || isBlank(str);
}

function isInvoiceFieldsAreMandatory(organizationData) {
  const billingData = organizationData.billingData;

  if (
      isNullOrWhiteSpace(billingData.nip) &&
      isNullOrWhiteSpace(billingData.companyName) &&
      isNullOrWhiteSpace(billingData.address.city) &&
      isNullOrWhiteSpace(billingData.address.street) &&
      isNullOrWhiteSpace(billingData.address.streetNumber) &&
      isNullOrWhiteSpace(billingData.address.zipCode)
  ) {
    return true;
  }

  if (
      !isNullOrWhiteSpace(billingData.nip) &&
      !isNullOrWhiteSpace(billingData.companyName) &&
      !isNullOrWhiteSpace(billingData.address.city) &&
      !isNullOrWhiteSpace(billingData.address.street) &&
      !isNullOrWhiteSpace(billingData.address.streetNumber) &&
      !isNullOrWhiteSpace(billingData.address.zipCode)
  ) {
    return true;
  }
  return false;
}

function validatePolishPostalCode(organizationDataRequest) {
  let billingData = organizationDataRequest.billingData;
  if (billingData !== null && billingData !== undefined) {
    var postalCodePattern = /^\d{2}-\d{3}$/;
    if (postalCodePattern.test(billingData.address.zipCode)) {
      return true;
    } else {
      NotificationService.showNotification(
          $q,
          'negative',
          'Niepoprawny format kodu pocztowego.'
      );
      return false;
    }
  } else {
    return true;
  }

}

</script>
