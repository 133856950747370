class OfferDataTranslator {
    constructor() {
        this.experienceLevels = {
            TRAINEE: "Praktykant",
            JUNIOR: "Junior",
            MID: "Mid",
            SENIOR: "Senior",
            ARCHITECT: "Architekt",
        };
        this.skillsLevels = {
            NICE_TO_HAVE: "Podstawowy",
            JUNIOR: "Junior",
            REGULAR: "Regular",
            SENIOR: "Senior",
            ARCHITECT: "Architekt",
        };
        this.contractTypes = {
            UOP: "Umowa o pracę",
            B2B: "B2b",
            UZ: "Umowa zlecenie"
        };

        this.languages = {
            POLISH: "Polski",
            ENGLISH: "Angielski",
            GERMAN: "Niemiecki",
            SPANISH: "Hiszpański",
            FRENCH: "Francuski",
            RUSSIAN: "Rosyjski",
            PORTUGUESE: "Portugalski",
            ITALIAN: "Włoski",
        };

        this.languageProficiencies = {
            BASIC: "Podstawowy",
            COMMUNICATIVE: "Komunikatywny",
            INTERMEDIATE: "Średniozaawansowany",
            ADVANCED: "Zaawansowany",
            FLUENT: "Biegły",
        };

        this.cities = {
            WARSZAWA: "Warszawa",
            POZNAN: "Poznań",
            KRAKOW: "Kraków",
            WROCLAW: "Wrocław",
            GDANSK: "Gdańsk",
            TROJMIASTO: "Trójmiasto",
            KATOWICE: "Katowice",
            SLASK: "Śląsk",
            LODZ: "Łódź",
            BIALYSTOK: "Białystok",
            GDYNIA: "Gdynia",
            LUBLIN: "Lublin",
            RZESZOW: "Rzeszów",
            BYDGOSZCZ: "Bydgoszcz",
            GLIWICE: "Gliwice",
            CZESTOCHOWA: "Częstochowa",
            SZCZECIN: "Szczecin",
            SOPOT: "Sopot",
        };

        this.questionAnswerTypes = {
            TEXT: "tekst",
            NUMBER: "liczba całkowita",
            BOOLEAN: "tak/nie",
        };
    }

    translatePolishExperienceLevel(experienceLevel) {
        return this.experienceLevels[experienceLevel] || "Nieznany poziom";
    }

    translateExperienceLevelsTypeToPolish() {
        return Object.values(this.experienceLevels);
    }

    translateContractTypesToPolish() {
        return Object.values(this.contractTypes);
    }

    translateLanguagesToPolish() {
        return Object.values(this.languages);
    }

    translateCitiesToPolish() {
        return Object.values(this.cities);
    }

    translateLanguageProficienciesToPolish() {
        return Object.values(this.languageProficiencies);
    }

    translateFromPolishToExperienceLevel(translatedValue) {
        for (const key in this.experienceLevels) {
            if (this.experienceLevels[key] === translatedValue) {
                return key;
            }
        }
        return 'UNKNOWN';
    }

    // Translate from Polish to Contract Type
    translateFromPolishToContractType(translatedValue) {
        for (const key in this.contractTypes) {
            if (this.contractTypes[key] === translatedValue) {
                return key;
            }
        }
        return 'UNKNOWN';
    }

    translateFromPolishToLanguage(translatedValue) {
        for (const key in this.languages) {
            if (this.languages[key] === translatedValue) {
                return key;
            }
        }
        return 'UNKNOWN';
    }

    translateFromPolishToCity(translatedValue) {
        for (const key in this.cities) {
            if (this.cities[key] === translatedValue) {
                return key;
            }
        }
        return 'UNKNOWN';
    }

    translateToLanguageProficiency(translatedValue) {
        if (translatedValue === 'BASIC') {
            return 1
        } else if (translatedValue === 'COMMUNICATIVE') {
            return 2
        } else if (translatedValue === 'INTERMEDIATE') {
            return 3
        } else if (translatedValue === 'ADVANCED') {
            return 4
        } else if (translatedValue === 'FLUENT') {
            return 5
        }
    }

    mapFromLanguageRateToProficiency(langLevel) {
        if (langLevel === 1) {
            return 'BASIC'
        } else if (langLevel === 2) {
            return 'COMMUNICATIVE'
        } else if (langLevel === 3) {
            return 'INTERMEDIATE'
        } else if (langLevel === 4) {
            return 'ADVANCED'
        } else if (langLevel === 5) {
            return 'FLUENT'
        }
    }

    translateFromSeniorityToPolish(translatedValue) {
        for (const key in this.experienceLevels) {
            if (key === translatedValue) {
                return this.experienceLevels[key];
            }
        }
        return '';
    }

    convertLevelToNumber(level) {
        switch (level) {
            case "NICE_TO_HAVE":
                return 1;
            case "JUNIOR":
                return 2;
            case "REGULAR":
                return 3;
            case "SENIOR":
                return 4;
            case "ARCHITECT":
                return 5;
            default:
                return 0; // Unknown level
        }
    }

    translateFromSkillToPolish(value) {
        for (const key in this.skillsLevels) {
            if (key === value) {
                return this.skillsLevels[key];
            }
        }
        return '';
    }

    translateArrayLanguagesToPolish(translatedValues) {
        const translatedLanguages = [];
        translatedValues.forEach(value => {
            for (const key in this.languages) {
                if (key === value.language) {
                    translatedLanguages.push({
                        language: this.languages[key],
                        proficiency: this.translateToLanguageProficiency(value.proficiency)
                    });
                }
            }
        });
        return translatedLanguages.length > 0 ? translatedLanguages : [];
    }

    translateArrayLocationsToPolish(translatedValues) {
        const translatedLanguages = [];
        translatedValues.forEach(value => {
            for (const key in this.cities) {
                if (key === value) {
                    translatedLanguages.push(this.cities[key]);
                }
            }
        });
        return translatedLanguages.length > 0 ? translatedLanguages : [];
    }

    async mapSkillLevelsToModel(jobOfferData) {
        jobOfferData.skills.forEach(skill => {
                skill.level = this.convertNumberToLevel(skill.level)
            }
        )
    }

    convertNumberToLevel(number) {
        switch (number) {
            case 1:
                return "NICE_TO_HAVE";
            case 2:
                return "JUNIOR";
            case 3:
                return "REGULAR";
            case 4:
                return "SENIOR";
            case 5:
                return "ARCHITECT";
            default:
                return "Unknown level";
        }
    }


    async mapModelToSkillLevels(jobOfferData) {
        jobOfferData.skills.forEach(skill => {
            switch (skill.level) {
                case 'BASIC':
                    skill.level = 1;
                    break;
                case 'INTERMEDIATE':
                    skill.level = 2;
                    break;
                case 'ADVANCED':
                    skill.level = 3;
                    break;
                case 'EXPERT':
                    skill.level = 4;
                    break;
                default:
                    skill.level = 5; // Or another value representing 'MASTER'
                    break;
            }
        });
    }

    translateContractsToPolish(modelContracts) {
        const translatedLanguages = [];
        modelContracts.forEach(value => {
            for (const key in this.contractTypes) {
                if (key === value.type) {
                    value.type = this.contractTypes[key]
                }
            }
        });
        return translatedLanguages.length > 0 ? translatedLanguages : [];
    }


    translateFromPolishToQuestionAnswerType(translatedValue) {
        for (const key in this.questionAnswerTypes) {
            if (this.questionAnswerTypes[key] === translatedValue) {
                return key;
            }
        }
        return 'UNKNOWN';
    }

    translateAnswerTypeToPolish(type) {
        for (const key in this.questionAnswerTypes) {
            if (key === type) {
                return this.questionAnswerTypes[key];
            }
        }
    }


    translateLanguageToPolish(value) {
        for (const key in this.languages) {
            if (key === value) {
                return this.languages[key];
            }
        }
        return value;
    }

    translateLanguageProficiencyToPolish(value) {
        for (const key in this.languageProficiencies) {
            if (key === value) {
                return this.languageProficiencies[key];
            }
        }
        return value;
    }

    convertLangProficiencyToNumber(level) {
        switch (level) {
            case "BASIC":
                return 1;
            case "COMMUNICATIVE":
                return 2;
            case "INTERMEDIATE":
                return 3;
            case "ADVANCED":
                return 4;
            case "FLUENT":
                return 5;
            default:
                return 0; // Unknown level
        }
    }

    translateContractTypeToPolish(value) {
        for (const key in this.contractTypes) {
            if (key === value) {
                return this.contractTypes[key];
            }
        }
        return value;
    }

}

export default new OfferDataTranslator();

