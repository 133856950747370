<template>
  <div>
    <q-dialog v-model="showColorDialog">
      <q-card class="q-mt-none q-pt-none" style="border-radius: 9px">
        <q-card-section>
          <div class="row q-gutter-md">
            <div>
              <q-color v-model="organization.branding.primaryColor"
                       no-header-tabs
                       no-footer
                       flat
                       style="border-radius: 9px"
                       class="my-picker"/>
            </div>
          </div>
        </q-card-section>
        <q-card-section class="q-pt-none">
          <q-btn class="full-width" style="border-radius: 7px" no-caps size="md" outline color="primary" label="Zamknij"
                 @click="showColorDialog=false"></q-btn>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
  <q-form greedy ref="form">
    <div class="q-mb-md q-mt-sm">
      <adding-offer-section-description
          title="Adres e-mail admina"
          description=" - na ten adres zostanie założone konto pierwszego użytkownika organizacji."/>
    </div>
    <q-input v-model="accountDetails.adminEmail" rounded outlined dense
             class="col bg-white text-grey-9 q-mb-md"
             :rules="[val => EmailValidator.isEmailValid(val) || 'Niepoprawny format adresu e-mail',val => !isBlank(val) || 'Podaj e-mail']"
             label="Adres e-mail">
    </q-input>

    <div class="q-my-lg">
      <q-card flat bordered style="border-radius: 9px">
        <q-card-section class="q-pa-md">
          <div class="row justify-between">
            <div>
              <my-text><span style="font-size: 13px" class="text-bold text-primary">Branding</span>
                <span> - wybierz główny kolor charakterystyczny dla firmy.</span>
              </my-text>
            </div>
            <div class="row q-gutter-md">
              <q-btn @click="showColorDialog=true" size="sm" :style="definePrimaryColorButton()"></q-btn>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </div>

    <q-card style="border-radius: 9px" :style="calculateBackgroundGradient()">
      <div class="row no-wrap q-pa-md">
        <div class="q-mt-sm q-pl-sm q-mr-sm col row"
             style="min-width: 160px; max-width: 160px;min-height: 160px; max-height: 160px">
          <q-card v-ripple class="my-box cursor-pointer q-hoverable my-card  full-width q-mb-sm"
                  @mouseover="handleMouseOver"
                  @mouseout="handleMouseOut"
                  @click="importData()"
                  style="border-radius: 9px">
            <div v-if="isBlank(organization.logoInBase64)">
              <q-card-section v-if="isMouseOnCard" class="text-center">
                <q-icon name="add_photo_alternate" size="100px" class="text-primary"></q-icon>
              </q-card-section>
              <q-card-section v-else class="text-center">
                <q-icon name="add_photo_alternate" size="100px" class="text-grey-7"></q-icon>
              </q-card-section>
            </div>
            <div v-else
                 style=" display: flex;
  flex-direction: column;
  justify-content: center;  /* Centering y-axis */
  align-items :center">
              <q-img
                  style="border-radius: 9px;"
                  :ratio="4/4"
                  :src="organization.logoInBase64"
                  fit="contain"/>
            </div>

            <span class="q-focus-helper">

        </span>
          </q-card>
        </div>
        <div class="full-width">
          <div class="full-width">
            <q-input class="q-pa-sm q-pb-sm" color="primary" stack-label outlined v-model="organization.name"
                     label="Nazwa firmy widoczna w ogłoszeniu"
                     bg-color="white"
                     :rules="[ val => val.length > 2 || 'Podaj nazwę firmy',
                    val => val.length < 40 || 'Zbyt długa nazwa firmy']" dense>
              <template v-slot:append>
                <q-avatar>
                  <q-icon
                      name="sym_o_title"
                      size="1rem"/>
                </q-avatar>
              </template>
            </q-input>
            <q-input class="q-pa-sm q-pb-sm" color="primary" stack-label outlined
                     v-model="organization.webPageUrl"
                     label="Strona firmy (opcjonalne)"
                     bg-color="white"
                     :rules="[ val => val.length <= 40 || 'Zbyt długi adres strony www' ]" dense>
              <template v-slot:append>
                <q-avatar>
                  <q-icon
                      name="sym_o_title"
                      size="1rem"/>
                </q-avatar>
              </template>
            </q-input>
          </div>

          <div class="row">
            <q-select class="col q-pa-sm" color="primary" stack-label outlined
                      v-model="organization.type"
                      bg-color="white"
                      :options="OrganizationDataTranslator.translateCompanyTypeToPolish()"
                      :rules="[ val => val.length >0 || 'Wybierz rodzaj firmy']"
                      label="Rodzaj firmy" dense>
            </q-select>
            <q-select class="col q-pa-sm" color="primary" stack-label outlined
                      v-model="organization.industries"
                      multiple
                      bg-color="white"
                      :options="OrganizationDataTranslator.translateCompanyIndustriesToPolish()"
                      dense
                      :rules="[ val => val.length >0 || 'Wybierz branżę']"
                      label="Branża">
            </q-select>
            <q-select class="col q-pa-sm" color="primary" stack-label outlined
                      v-model="organization.size"
                      bg-color="white"
                      :options="OrganizationDataTranslator.translateCompanySizesToPolish()"
                      dense
                      :rules="[ val => val.length >0 || 'Podaj wielkość firmy']"
                      label="Liczba pracowników">
            </q-select>
          </div>
        </div>
      </div>
    </q-card>
    <div class="q-pt-lg">
      <div class="q-mt-lg">
        <adding-offer-section-description title="Opis firmy"
                                          description=" - dodaj najważniejsze informacje o firmie. (opcjonalne)"/>
      </div>
      <div class="q-pt-1rem">
        <q-field ref="fieldRef" v-model="organization.description" label-slot borderless dense
                 :rules="[val => val.length < 4000|| 'Zbyt długi opis - max 4000 znaków.']">
          <my-text class="full-width">
            <q-editor
                class="full-width"
                v-model="organization.description"
                min-height="13rem"
                max-height="13rem"
                style="border-radius: 9px"
                toolbar-color="accent"
                :toolbar="[ [ 'left', 'center', 'right', 'justify' ],['ordered','unordered'], [ 'bold', 'italic', 'underline', 'strike' ], [ 'undo', 'redo' ] ]"
                :style="fieldRef && fieldRef.hasError ? 'border-color: #C10015' : ''"
            />

          </my-text>

        </q-field>
      </div>
    </div>
    <div class="q-pt-lg q-pb-md q-py-xs q-px-sm">
      <q-separator></q-separator>
    </div>
    <q-stepper-navigation class="q-pa-sm">
      <q-btn class="q-px-md" outline color="accent" dense no-caps label="Dalej" style="border-radius: 7px"
             @click="nextStep()"/>
      <q-btn flat @click="previousStep" color="primary" dense no-caps label="Powrót" class="q-ml-md"
             style="border-radius: 7px"/>
    </q-stepper-navigation>
  </q-form>
</template>

<script setup>
import {computed, defineEmits, defineProps, ref} from 'vue'
import OrganizationDataTranslator from "@/services/organization/OrganizationDataTranslator";
import NotificationService from "@/services/notification/NotificationService";
import {useQuasar} from "quasar";
import EmailValidator from "@/services/common/EmailValidator";
import {lighten} from "polished";
import AddingOfferSectionDescription from "@/components/common/add-offer/AddingOfferSectionDescription";

const showColorDialog = ref(false)

function isBlank(str) {
  return /^\s*$/.test(str);
}

const form = ref(null)
const props = defineProps({
  formTemplateData: Object,
  modelValue: Object,
  accountDetails: Object
})

const organization = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val),
});

const accountDetails = computed({
  get: () => props.accountDetails,
  set: (val) => emit('update:accountDetails', val),
});

const emit = defineEmits(['update:modelValue', 'step:next', 'step:previous'])

async function nextStep() {
  let formValidation = form.value.validate()
  let logoValidation = validateLogo();
  let emailValidation = validateAdminEmail();
  if (logoValidation && emailValidation && formValidation) {
    emit('step:next')
  }
}

const $q = useQuasar()

function validateLogo() {
  if (isStringEmpty(organization.value.logoInBase64)) {
    NotificationService.showNotification($q, 'negative', 'Dodaj logo firmy');
    return false;
  }
  return true;
}

function validateAdminEmail() {
  if (isStringEmpty(accountDetails.value.adminEmail)) {
    NotificationService.showNotification($q, 'negative', 'Podaj adres e-mail');
    return false;
  }
  return true;
}

function previousStep() {
  emit('step:previous')
}

function isStringEmpty(str) {
  const regex = /^\s*$/; // Regular expression for empty or whitespace-only string
  return regex.test(str);
}

const isMouseOnCard = ref(false);

function handleMouseOver() {
  isMouseOnCard.value = true;
}

function handleMouseOut() {
  isMouseOnCard.value = false;
}

const organizationLogo = ref(null);

function importData() {
  let input = document.createElement('input');
  input.type = 'file';
  input.accept = '.jpg, .jpeg, .png, .gif, .webp, .svg'; // Specify accepted file extensions
  input.onchange = () => {
    let files = Array.from(input.files);
    if (files.length > 0) {
      const file = files[0];
      if (file.size > 5 * 1024 * 1024) {
        NotificationService.showNotification($q, 'negative', 'Maksymalny rozmiar pliku - 5 MB.');
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          organizationLogo.value = reader.result;
          organization.value.logoInBase64 = reader.result;
        };
        reader.readAsDataURL(file);
      }
    }
  };

  input.click();
}


function calculateBackgroundGradient() {
  const primaryColor = organization.value.branding.primaryColor;
  const lighterPrimary = lighten(0.25, primaryColor)
  return {
    background: `
      linear-gradient(15deg, ${primaryColor}, ${lighterPrimary})
    `,
    backgroundSize: '100% 100%, 100% 100%',
    backgroundPosition: 'bottom, top',
    backgroundRepeat: 'no-repeat'
  };
}


function definePrimaryColorButton() {
  return 'background:' + organization.value.branding.primaryColor
}
</script>
<style lang="scss" scoped>
.my-picker {
  width: 300px
}

//
//:deep(.q-field--auto-height.q-field--dense.q-field--labeled .q-field__control-container) {
//  padding-top: 3px;
//}
</style>