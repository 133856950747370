<template>
  <div class="row justify-start col">
    <q-btn clickable
           class="text-center bg-transparent q-ml-sm"
           size="12px"
           flat
           dense
           no-caps
           text-color="grey-9"
           @click="router.push({ name: 'OrganizationJobOffersBoard' });"
           label='Ogłoszenia'/>

    <q-icon style="padding-top: 8px" color="grey-9" class="q-px-sm" size="12px"
            name="fa-solid fa-chevron-right"></q-icon>
    <q-btn clickable
           size="12px"
           flat
           dense
           no-caps
           class="text-center text-primary bg-transparent"
           @click="router.push({ name: 'OrganizationJobOfferDetailsBoard', params: { id: router.currentRoute.value.params.id } })"
           :label='props.jobOfferTitle'/>
  </div>
</template>
<script setup>
import {defineProps} from "vue";

const props = defineProps({
  jobOfferTitle: String
})

const router = useRouter()
import {useRouter} from 'vue-router';
</script>
