<template>
  <q-card flat bordered class="q-mx-sm q-mt-lg q-mb-lg full-width"
          style="border-radius: 9px">
    <q-card-section>
      <my-text class="text-white">
        <span class="text-weight-bold text-primary" style="font-size: 13px">Technologie</span>
        <span class="text-grey-8">
                  muszą zawierać przynajmniej trzy umiejętności, każda z wymaganym poziomem znajomości.
        </span>
      </my-text>
    </q-card-section>
  </q-card>
  <q-form ref="formRef" greedy>
    <div class="row full-width">
      <div class="q-pb-none q-pr-sm q-pt-none full-width">
        <q-select
            style="border-color: #ff5f77"
            color="primary"
            dense
            clear-icon="cancel"
            use-chips
            use-input
            multiple
            v-model="offerModelTechValues"
            label="Technologie"
            :options="filteredSkills"
            outlined
            @add="addSkill"
            :rules="[ val => val.length >= 3 || 'Podaj przynajmniej 3 umiejętności', val => val.length <= 20 || 'Możesz podać maksymalnie 20 umiejętności' ]"
            @remove="removeLanguage"
            @filter="(val, update) => filterAll(val, update)"
        >
        </q-select>
      </div>
      <div class="row q-mx-xs q-pt-none">
        <skill v-for="skill in offer.skills" :key="skill.id"
               :v-for-key="skill.id" :name="skill.name"
               v-model="offer" v-model:level="skill.level"
        >
        </skill>
      </div>
    </div>
<!--    <div class="q-my-md full-width">-->
<!--      <q-separator></q-separator>-->
<!--    </div>-->
  </q-form>
</template>
<script setup>
import {
  computed,
  defineProps,
  ref,
  defineEmits,
  onMounted, defineExpose,

} from 'vue'
import Skill from "@/components/common/add-offer/job-offer/skills/Skill";

const offerModelTechValues = ref([]);

const props = defineProps({
  formTemplateData: Object,
  modelValue: Object
})

const offer = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val),
});


onMounted(() => {
  offerModelTechValues.value = initModelTechValues()
})

function initModelTechValues() {
  let modelValues = []
  offer.value.skills.forEach(skill => {
    modelValues.push(skill.name)
  })
  return modelValues
}

const emit = defineEmits(['sendSkills'])

function addSkill(details) {
  offer.value.skills.push({name: details.value, level: null})
}

function skillOptions() {
  let options = []
  props.formTemplateData.jobOffer.skills.forEach(skill => {
    options.push(skill.name)
  })
  return options;
}

function removeLanguage(key) {
  let index = offer.value.skills.findIndex(skill => skill.name === key.value)
  if (index > -1) { // only splice array when item is found
    offer.value.skills.splice(index, 1); // 2nd parameter means remove one item only
  }
}

const filteredSkills = ref([])

function filterAll(val, update) {
  let options = skillOptions()
  update(() => {
    if (val === "") {
      filteredSkills.value = options;
    } else {
      let needle = val.toLowerCase();
      filteredSkills.value = options.filter(
          v => v.toLowerCase().indexOf(needle) > -1
      );
    }
  });
}

defineExpose({
  validate
})
const formRef = ref(null)

async function validate() {
  return await formRef.value.validate()
}
</script>
<style lang="sass" scoped>
@import "/src/assets/css/main.css"
.q-input
  font-size: 12px

.q-field-target
  font-size: 12px
  color: #581c9b

.q-chip
  background: red

.q-select
  font-size: 12px

.q-editor
  font-size: 11px

.my-stepper
  width: 65%
</style>
