<template>
  <div>
    <q-card flat bordered class="q-mb-lg full-width"
            style="border-radius: 9px">
      <q-card-section>
        <my-text class="text-white">
          <span class="text-weight-bold text-primary"
                style="font-size: 15px;">Dane do faktury</span> <span
            class="text-grey-9">
               pozwolą automatycznie uzupełnić formularz do faktury przy procesie zakupu oferty. Wypełnienie tej sekcji jest opcjonalne. Dane do faktury można edytować
          w formularzu płatności bezpośrednio przed zakupem ogłoszenia.
      </span>
        </my-text>
      </q-card-section>
    </q-card>
    <div class="row q-gutter-md text-primary">
      <div class="row full-width q-gutter-md q-mb-none q-mt-none">
        <q-input class="q-pb-sm col text-primary"
                 stack-label
                 standout="bg-white text-primary"
                 bg-color="white"
                 outlined
                 color="primary"
                 label-color="primary"
                 v-model="organizationData.billingData.companyName"
                 label="Pełna nazwa firmy"
                 dense>
        </q-input>
        <q-input class="q-pb-sm col text-primary"
                 stack-label
                 standout="bg-white text-primary"
                 color="primary"
                 label-color="primary"
                 v-model="organizationData.billingData.nip"
                 label="Nip"
                 outlined
                 dense>
        </q-input>
        <q-input class="q-pb-sm col text-primary"
                 stack-label
                 standout="bg-white text-primary"
                 color="primary"
                 label-color="primary"
                 v-model="organizationData.billingData.address.city"
                 label="Miasto"
                 outlined
                 dense>
        </q-input>
      </div>
      <div class="row q-gutter-md q-mt-none full-width">
        <q-input class="q-pb-md col"
                 stack-label
                 standout="bg-white text-white"
                 label-color="primary"
                 v-model="organizationData.billingData.address.street"
                 label="Ulica"
                 outlined
                 dense>
        </q-input>
        <q-input class="q-pb-md col"
                 stack-label
                standout="bg-white text-primary"
                 label-color="primary"
                 v-model="organizationData.billingData.address.streetNumber"
                 label="Numer budynku"
                 outlined
                 dense>
        </q-input>

        <q-input class="q-pb-md col"
                 stack-label
                standout="bg-white text-primary"
                 label-color="primary"
                 v-model="organizationData.billingData.address.flatNumber"
                 label="Numer lokalu"
                 outlined
                 dense>
        </q-input>
        <q-input class="q-pb-md col-2"
                 stack-label
                standout="bg-white text-primary"
                 label-color="primary"
                 outlined
                 v-model="organizationData.billingData.address.zipCode"
                 label="Kod pocztowy"
                 dense>
        </q-input>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, defineEmits, defineProps, onMounted} from "vue";


const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  formTemplateData: Object,
  modelValue: Object
})

const organizationData = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val),
});

onMounted(() => {
})
</script>