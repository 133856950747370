<template>
  <div class="row">
    <div class="q-pt-md q-pl-sm">
      <span style="font-size: 13px">Odpowiedź:</span>
    </div>
    <div class="q-px-md q-pt-sm q-mb-md">
      <q-select dense
                style="font-size: 10px;border-radius: 5px;min-width: 70px"
                transition-show="scale"
                transition-hide="scale"
                standout="bg-accent text-white"
                v-model="questionFilter.search"
                class="text-primary"
                :popup-content-style="{borderRadius: '0 0 7px 7px', fontSize: '12px' }"
                :options="booleanOptions"
      />
    </div>
  </div>
</template>
<script setup>
import {computed, defineEmits, defineProps, ref} from "vue";

const booleanOptions = ref(["tak", "nie"])

const props = defineProps({
  questionFilter: Object,
})

const questionFilter = computed({
  get: () => props.questionFilter,
  set: (val) => emit('update:questionFilter', val),
});

const emit = defineEmits(['update:questionFilter'])

</script>