<template>
  <router-view/>
</template>
<style lang="scss">
.q-field--standout .q-field__control {
  border-radius: 7px !important;
}

.q-field {
  border-radius: 7px !important;
}

//.q-btn{
//  border-radius: 7px !important;
//}

.q-field--outlined.q-field--rounded .q-field__control {
  border-radius: 7px !important;
}

:deep(.q-field){
  border-radius: 7px
}

.q-field--standout.q-field--highlighted .q-field__control {
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
}

.q-chip {
  background: white !important;
}

.q-chip--dense .q-chip__icon {
  font-size: 0.8rem !important;
}

#app > div > div > main > div.row.col.q-pt-none.q-pl-none.column.q-pt-none > div.row.q-mr-md.q-ml-sm > div > label.q-field.row.no-wrap.items-start.q-field--standout.q-select.q-field--auto-height.q-select--with-input.q-select--with-chips.q-select--multiple.q-field--float.q-field--labeled.q-field--dense.col.q-px-md > div > div > div:nth-child(2) > button {
  color: black;
}

#app > div > div > main > div.row.col.q-pt-none.q-pl-none.column.q-pt-none > div.row.q-mr-md.q-ml-sm > div > label.q-field.row.no-wrap.items-start.q-field--standout.q-select.q-field--auto-height.q-select--with-input.q-select--with-chips.q-select--multiple.q-field--float.q-field--labeled.q-field--dense.col.q-px-md > div > div > div:nth-child(3) > i {
  color: #f06292;
}

.q-fab--form-square {
  border-radius: 7px !important
}

input, select {
  color: $input-text-color !important;
  -webkit-text-fill-color: $input-text-color !important;
  -webkit-background-clip: text !important;
  background-clip:  text !important;
}

.q-field__native > span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.q-field--outlined .q-field__control:before {
  border-color: rgba(0, 0, 0, 0.12) !important;
}
</style>
