import {reactive} from 'vue'
import StringUtils from "@/services/common/StringUtils";

export const currentUser = reactive({
    id: null,
    email: null,
    emailVerified: false,
    createdAt: null,
    firstName: null,
    lastName: null,
    phoneNumber: null,
    type: null,
    active: false,
    preferredContactTime: null,
    organizationId: null,
    favoriteOffersIds: [],
    resumes: [],
    permissions: [],

    isAuthenticated() {
        return this.id !== null;
    },

    isApplicant() {
        return this.type === 'APPLICANT'
    },

    isOrganizationUser() {
        return this.type === 'ORGANIZATION_EMPLOYEE' || this.type === 'ORGANIZATION_ADMIN'
    },

    isAdmin() {
        return this.type === 'ORGANIZATION_ADMIN'
    },

    hasAccessTo(givenPermission) {
        return currentUser.permissions.some(permission => {
            return permission.name === givenPermission;
        });
    },

    resetCurrentUser() {
        currentUser.id = null
        currentUser.email = null
        currentUser.emailVerified = null
        currentUser.createdAt = null
        currentUser.firstName = null
        currentUser.lastName = null
        currentUser.phoneNumber = null
        currentUser.type = null
        currentUser.active = false
        currentUser.preferredContactTime = null
        currentUser.organizationId = null
        currentUser.favoriteOffersIds = []
        currentUser.resumes = []
        currentUser.permissions = []
    },

    isRequiredApplicationDataProvided() {
        return !StringUtils.isStringEmpty(currentUser.firstName) && !StringUtils.isStringEmpty(currentUser.lastName) && currentUser.resumes.length > 0;
    }


});