<template>
  <q-form ref="childForm" greedy>
    <div class="row q-mb-sm q-mt-sm justify-between">
      <q-checkbox
          v-model="isSalaryRequired"
          icon="alarm"
          checked-icon="fa-regular fa-square-plus"
          unchecked-icon="fa-regular fa-square-minus"
          size="md"
          color="primary"
          style="font-size: 13px"
          class="text-grey-8 q-mt-none q-mx-none q-mr-sm"
          @click="changeSalaryRequired()"
          label="Stawka"
      />
      <div class="row col">
        <q-select class="col q-pa-sm" color="primary" v-model="typeC"
                  clear-icon="cancel"
                  :options="OfferDataTranslator.translateContractTypesToPolish()" dense
                  label="Umowa" outlined stack-label
                  :rules="[val => val !== null || 'Wybierz typ umowy']"
        >
        </q-select>
        <q-input
            v-if="isSalaryRequired"
            class="col q-pa-sm"
            outlined
            color="primary"
            v-model="salary.from"
            :label="evaluateSalaryLabelFrom()"
            stack-label
            dense
            :rules="[
    val => !isSalaryRequired || val >= 4000 || 'Minimalna stawka wynosi 4000',
    val => !isSalaryRequired || (val !== null && val !== '') || 'Podaj minimalną stawkę'
  ]"
        />

        <q-input
            v-if="isSalaryRequired"
            class="col q-pa-sm"
            color="primary"
            v-model="salary.to"
            :label="evaluateSalaryLabelTo()"
            outlined
            stack-label
            dense
            :rules="[
    val => !isSalaryRequired || Number(val) <= Number(salary.from) * 2 || 'Max. stawka większa 2X od minimalnej stawki',
    val => !isSalaryRequired || (val !== null && val !== '') || 'Podaj maksymalną stawkę',
    val => !isSalaryRequired || Number(val) >= Number(salary.from) || 'Stawka nie może być mniejsza od minimalnej'
  ]"
        />

        <q-select v-if="isSalaryRequired" class="col q-pa-sm" color="primary" v-model="salary.currency"
                  :options="props.formTemplateData.jobOffer.currencies" dense
                  label="Waluta" outlined stack-label
                  :rules="[val =>!isSalaryRequired|| val != null  || 'Wybierz walutę']">
        </q-select>
        <div v-if="props.vForKey === 0" class="q-pa-sm">
          <q-btn color="primary" style="height: 40px; width: 40px; font-size: 10px" @click="emitAddContractInfo()">
            <q-icon
                name="sym_o_add"
                size="1.5rem"
            />
          </q-btn>
        </div>
      </div>
      <div v-if="props.vForKey !== 0" class="q-pa-sm">
        <q-btn color="primary" outline style="height: 40px; width: 40px; font-size: 10px"
               @click="emitRemoveContractInfo">
          <q-icon
              name="sym_o_remove"
              size="2rem"
          />
        </q-btn>
      </div>

    </div>
  </q-form>
</template>

<script setup>
import {computed, defineEmits, defineExpose, defineProps, onMounted, ref} from 'vue'
import OfferDataTranslator from "@/services/organization/offer/OfferDataTranslator";

const props = defineProps({
  formTemplateData: Object,
  vForKey: String,
  type: String,
  salary: Object,
  contracts: Object
})

const typeC = computed({
  get: () => props.type,
  set: (val) => emit('update:type', val),
});

const salary = computed({
  get: () => props.salary,
  set: (val) => emit('update:salary', val),
});

onMounted(() => {
  isSalaryRequired.value = props.salary !== null && props.salary.from !== null
  if (props.salary === null) {
    salary.value = {from: 0, to: 0, currency: 'PLN'}
  }
})
const isSalaryRequired = ref(false)


const emit = defineEmits(['addContract', 'update:type', 'update:salaryFrom', 'update:salaryTo', 'update:currency'])

function emitAddContractInfo() {
  emit('addContract')
}

function emitRemoveContractInfo() {
  emit('removeContractInfo', props.vForKey)
}

defineExpose({
  validate
})

const childForm = ref(null)

function validate() {
  return childForm.value.validate().then(function (resolvedValue) {
    return resolvedValue;
  }).catch(function (error) {
    throw error;
  });
}

function changeSalaryRequired() {
  salary.value.from = null
  salary.value.to = null
}

function evaluateSalaryLabelFrom() {
  if (props.type === 'B2b') {
    return 'Stawka(netto) od'

  } else if (props.type === 'Umowa o pracę') {
    return 'Stawka(brutto) od'

  } else {
    return 'Stawka(netto) od'
  }
}

function evaluateSalaryLabelTo() {
  if (props.type === 'B2b') {
    return 'Stawka(netto) do'

  } else if (props.type === 'Umowa o pracę') {
    return 'Stawka(brutto) do'

  } else {
    return 'Stawka(netto) do'
  }
}


</script>