// services/ApplicantDataService.js

import CookieService from "@/services/common/CookieService";
import FetchWrapper from "@/services/common/FetchWrapper";

class ApplicantDataService {
    constructor(url) {
        this.url = url;
    }

    async updateData(id, firstName, lastName, phoneNumber, contactFrom, contactTo) {
        try {
            const requestOptions = {
                credentials: "include",
                method: 'PUT',
                headers: {
                    "Content-Type": "application/json",
                    'X-XSRF-TOKEN': CookieService.getCookieValue("XSRF-TOKEN"),
                },
                body: JSON.stringify({
                    firstName: firstName,
                    lastName: lastName,
                    phoneNumber: phoneNumber,
                    preferredContactTime: {
                        from: contactFrom,
                        to: contactTo,
                    },
                }),
            };
            const url = `${this.url}/applicants/${id}`;
            return await FetchWrapper.fetchWithAuth(url, requestOptions);
        } catch (error) {
            console.error('Error during request:', error);
            throw error;
        }
    }

    async changeAvatar(userId, fileInBase64, fileName) {
        try {
            const requestOptions = {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                    'X-XSRF-TOKEN': CookieService.getCookieValue("XSRF-TOKEN"),
                },
                body: JSON.stringify({ fileInBase64, fileName }),
            };
            const url = `${this.url}/applicants/${userId}/avatar`;
            return await FetchWrapper.fetchWithAuth(url, requestOptions);
        } catch (error) {
            console.error('Error during request:', error);
            throw error;
        }
    }

    async changePassword(userId, oldPassword, newPassword) {
        try {
            const requestOptions = {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                    'X-XSRF-TOKEN': CookieService.getCookieValue("XSRF-TOKEN"),
                },
                body: JSON.stringify({ oldPassword, newPassword }),
            };
            const url = `${this.url}/applicants/${userId}/change-password`;
            return await FetchWrapper.fetchWithAuth(url, requestOptions);
        } catch (error) {
            console.error('Error during request:', error);
            throw error;
        }
    }

    async deleteAvatar(userId) {
        try {
            const requestOptions = {
                method: 'DELETE',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'X-XSRF-TOKEN': CookieService.getCookieValue("XSRF-TOKEN"),
                },
            };
            const url = `${this.url}/applicants/${userId}/avatar`;
            return await FetchWrapper.fetchWithAuth(url, requestOptions);
        } catch (error) {
            console.error('Error during request:', error);
            throw error;
        }
    }

    async getAvatar(userId) {
        try {
            const requestOptions = {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            const url = `${this.url}/applicants/${userId}/avatar`;
            return await FetchWrapper.fetchWithAuth(url, requestOptions);
        } catch (error) {
            console.error('Error during request:', error);
            throw error;
        }
    }

    async deleteAccount(userId) {
        try {
            const requestOptions = {
                method: 'DELETE',
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                    'X-XSRF-TOKEN': CookieService.getCookieValue("XSRF-TOKEN"),
                },
            };
            const url = `${this.url}/applicants/${userId}`;
            return await FetchWrapper.fetchWithAuth(url, requestOptions);
        } catch (error) {
            console.error('Error during request:', error);
            throw error;
        }
    }
}

export default new ApplicantDataService(process.env.VUE_APP_BACKEND_URL);
