<template>
  <q-dialog v-model="showDialog" class="row custom-dialog-wrapper">
    <div v-if="application.answers.length !== 0">
      <q-card style="border-radius: 9px" class="q-pt-md">
        <p style="font-size: 17px" class="q-mx-md text-weight-medium text-grey-9"><span
            class="q-ml-sm text-primary text-weight-bold q-ma-none">Odpowiedzi</span> - {{ application.applicantName }}
        </p>
        <q-card-section v-for="answer in application.answers" :key="answer.id">
          <p class="text-grey-9 q-px-sm q-ma-none" style="font-size: 13px">{{ answer.question }}</p>
          <q-card flat class="q-mt-md bg-primary" style="border-radius: 9px">
            <q-card-section>
              <p class="text-white q-ma-none">{{ toAnswer(answer) }}</p>
            </q-card-section>
          </q-card>
        </q-card-section>
      </q-card>
    </div>
  </q-dialog>
</template>
<script setup>
import {computed, defineEmits, defineProps, onMounted} from 'vue'


const props = defineProps({
  modelValue: Object,
  id: String,
  showDialog: Boolean
})

const showDialog = computed({
  get: () => props.showDialog,
  set: (val) => emit('update:showDialog', val),
});

const emit = defineEmits(['modelValue', 'accepted'])

const application = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val),
});

function toAnswer(answer) {
  if (answer.answer === true) {
    return "tak"
  } else if (answer.answer === false) {
    return "nie"
  } else {
    return answer.answer
  }
}

onMounted(() => {

})

// async function closeAnswers() {
//   showDialog.value = false
// }
</script>

<style lang="scss" scoped>

</style>