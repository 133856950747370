<template>
  <q-layout>
    <q-header class="bg-white">
    </q-header>
    <q-drawer
        v-model="drawerLeft"
        show-if-above
        @mouseover="miniStateLeft = false"
        @mouseout="miniStateLeft = true"
        :breakpoint="500"
        :width="250"
        class="bg-gradient"
        bordered
    >
      <div>
        <div class="text-center q-mt-lg">
          <q-img fit="contain" :src="logo" style="width: 100px; height: 100px"/>
        </div>

        <div class="text-center">
          <my-text class="text-bold text-white" style="font-size: 30px;">Byte Job</my-text>
        </div>
      </div>

      <q-list class="column q-col-gutter-sm" style="margin-top: 30px">
        <div class="q-mx-md">
          <q-btn
              rounded
              @click="router.push({ name: 'OrganizationAccountPage' });"
              dense
              flat
              style="border-radius: 6px"
              size="15px"
              icon="fa-regular fa-user"
              align="left"
              class="q-mx-lg full-width text-white bg-dark"
              color="primary"
              label="Mój profil"
              no-caps
          />
        </div>
        <div class="q-mx-md">
          <q-btn
              rounded
              @click="router.push({ name: 'OrganizationJobOffersBoard' });"
              dense
              flat
              style="border-radius: 6px"
              size="15px"
              icon="fa-solid fa-briefcase"
              align="left"
              class="q-mx-lg full-width text-white"
              color="primary"
              label="Ogłoszenia"
              no-caps
          />
        </div>
        <div class="q-mx-md">
          <q-btn
              rounded
              @click="router.push({ name: 'OrganizationAccountManagement' });"
              dense
              flat
              style="border-radius: 6px"
              size="15px"
              icon="fa-solid fa-address-book"
              align="left"
              class="q-mx-md full-width text-white"
              color="white"
              label="Konta"
              no-caps
          />
        </div>
        <div class="q-mx-md">
          <q-btn
              rounded
              @click="router.push({ name: 'OrganizationDataPage' });"
              dense
              flat
              style="border-radius: 6px"
              size="15px"
              icon="fa-solid fa-building"
              align="left"
              class="q-mx-md full-width text-white"
              color="white"
              label="Dane firmy"
              no-caps
          />
        </div>
        <div class="q-mx-md">
          <q-btn
              rounded
              @click="router.push({ name: 'InvoicesMainBoard' });"
              dense
              flat
              style="border-radius: 6px"
              size="15px"
              icon="fa-solid fa-file-lines"
              align="left"
              class="q-mx-md full-width text-white"
              color="white"
              label="Faktury"
              no-caps
          />
        </div>
        <div class="q-mx-md">
          <q-btn
              rounded
              @click="AuthenticationService.logout()"
              dense
              flat
              style="border-radius: 6px"
              size="15px"
              icon="fa-solid fa-arrow-right-from-bracket"
              align="left"
              class="q-mx-md full-width text-white q-mt-lg"
              color="primary"
              label="Wyloguj"
              no-caps
          />
        </div>
      </q-list>
    </q-drawer>
    <q-page-container style="background-color: rgb(217,225,238);">
      <q-page class="column items-center">
        <menu-bar></menu-bar>
        <organization-account-data ref="organizationDataRef"
                        v-if="user.id !== null"
                        v-model="user" style="width: 65%; margin-top: 35px"
                        class="q-mx-lg q-mt-lg"></organization-account-data>
        <div class="col row" style="width: 65%; max-height: 60px">
          <q-card-section class="q-pl-none q-pb-none q-pt-none row justify-between full-width" style="max-height: 10px">
            <q-btn size="13px" color="primary"
                   icon="login"
                   style="max-height: 15px; border-radius: 6px"
                   @click="updateData()"
                   label="Zapisz zmiany"
                   no-caps/>
          </q-card-section>
        </div>
      </q-page>
    </q-page-container>
  </q-layout>
</template>


<script setup>
import {onMounted, reactive, ref} from 'vue'
import MenuBar from "@/components/common/MenuBar";
import AuthenticationService from "@/services/AuthenticationService";

import {useRouter} from "vue-router/dist/vue-router";
const router = useRouter()

onMounted(() => {
  initAuthenticatedUserData()
  initUserData()
})

function initUserData() {
  AuthenticationService.getAuthenticatedUser().then(data => {
    data.json().then(body => {
      user.firstName = body.firstName;
      user.id = body.id;
      user.email = body.email;
      user.lastName = body.lastName;
      user.phoneNumber = body.phoneNumber;
      user.position = body.position
    })
  });
}

function initAuthenticatedUserData() {
  AuthenticationService.getAuthenticatedUser()
      .then(response => {
        if (response.status === 200) {
          response.json().then((data) => {
            user.value = data
          })
        } else {
          throw new Error("Cannot get user profile data - user not authenticated")
        }
      });
}

const user = reactive({
  id: null,
  email: null,
  firstName: null,
  lastName: null,
  phoneNumber: null,
  position: null,
});

import NotificationService from "@/services/notification/NotificationService";
import {useQuasar} from "quasar";
import OrganizationAccountData from "@/components/organization/organization-accounts/profile/OrganizationAccountData";
import OrganizationUsersService from "@/services/organization/OrganizationUsersService";

const $q = useQuasar()

async function updateData() {
  let promise = await validateChildComponents();
  if (promise) {
    OrganizationUsersService.updateUserData(user)
        .then(response => {
          if (response.ok) {
            response.json().then(json =>{
              Object.assign(currentUser, json);
            })
            NotificationService.showNotification($q, 'positive', 'Zaktualizowano dane profilu')
          }
        })
  }
}

const organizationDataRef = ref(null);

async function validateChildComponents() {
  return organizationDataRef.value.validate()
}
import {currentUser} from "@/global-variables/currentUser";
</script>
<style lang="scss">
.q-btn--dense .on-left {
  margin-right: 22px;
  margin-left: 8px;
}
</style>