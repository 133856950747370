<template>
  <menu-bar class="q-pl-sm q-pr-sm"></menu-bar>
  <div class="login-center" v-if="successfulPasswordSetupDialog">
    <q-card style="width: 350px; border-radius: 9px">
      <q-card-section class="row justify-center q-pb-none">
        <div class="q-pt-none q-pb-sm q-mr-sm">
          <q-icon color="positive" name="fa-regular fa-circle-check q-pt-sm q-pr-sm" size="40px"/>
        </div>
        <div class="q-pt-md text-center text-h7 text-grey-9 q-pb-sm">Pomyślnie ustawiono nowe hasło
        </div>
      </q-card-section>
      <q-card-section class="text-center q-pt-xs">
        <q-btn outline no-caps padding="3px xl" type="submit" color="primary" label="Ok"
               @click="router.push({ name: 'ApplicantLoginPage' });"></q-btn>
      </q-card-section>
    </q-card>
  </div>
  <div class="login-center" v-else-if="successfulReset">
    <q-card style="width: 350px; border-radius: 9px">
      <q-card-section class="row justify-center q-pb-none">
        <div class="q-pt-none q-pb-sm q-mr-sm">
          <q-spinner-hourglass
              color="primary"
              size="5em"
              :thickness="1"/>
        </div>
        <div class="q-pt-lg text-center text-h7 text-grey-10 q-pb-none q-mt-sm">Dokończ reset hasła za pomocą maila
        </div>
      </q-card-section>
      <q-card-section class="text-center q-pt-xs">
        <q-btn outline no-caps padding="3px xl" type="submit" color="primary" label="Ok"
               @click="router.push({ name: 'ApplicantLoginPage' });"></q-btn>
      </q-card-section>
    </q-card>
  </div>
  <div v-else-if="showPasswordSetupDialog" class="login-center">
    <q-card style="width: 350px; border-radius: 9px">
      <q-card-section class="row justify-center q-pb-sm">
        <!--        <div class="q-pt-xs q-pr-sm">-->
        <!--          <q-icon color="secondary" size="24px" name="sym_o_person"></q-icon>-->
        <!--        </div>-->
        <div class="text-center text-h6 text-secondary q-pr-md">Dokończ reset hasła</div>
      </q-card-section>
      <q-form @submit.prevent="submitForm" greedy>
        <q-card-section class="q-pb-xs">
          <q-input
              dense
              outlined
              rounded
              color="secondary"
              type="password"
              v-model="newPassword"
              label="Hasło"
              id="companyName"
              :rules="[val => val.length > 8 || 'Hasło musi zawierać przynajmniej 8 znaków',
              val => val.length < 20  || 'Hasło musi mieć mniej niż 20 znaków',
              val => /[A-Z]/.test(val)  || 'Hasło musi zawierać wielką literę',
              val => /[a-z]/.test(val)  || 'Hasło musi zawierać małą literę',
              val => /[!@#$%^&*]/.test(val)  || 'Hasło musi zawierać znak specjalny',
              val => /[0-9]/.test(val)  || 'Hasło musi zawierać cyfrę']"

          ></q-input>
          <div class="text-center q-pt-none q-pb-none"> <!-- Add the text-center class to this div -->
            <q-btn size="13px" flat color="secondary" @click="router.push({ name: 'ApplicantLoginPage' });"
                   icon="login"
                   label="Masz konto? - Zaloguj się"
                   no-caps/>
          </div>
        </q-card-section>
        <q-card-actions class="text-center q-pl-md q-pr-md q-pb-md q-pt-sm" align="center">
          <q-btn no-caps padding="3px xl" type="submit" color="primary" label="Zapisz"
                 @click="setNewPassword()"></q-btn>
        </q-card-actions>
      </q-form>
    </q-card>
  </div>
  <div v-else class="login-center">
    <q-card style="width: 350px; border-radius: 9px">
      <q-card-section class="row justify-center q-pb-sm">
        <div class="text-center text-h6 text-primary">Resetuj hasło</div>
      </q-card-section>
      <q-form @submit.prevent="submitForm" greedy>
        <q-card-section class="q-pb-xs">
          <q-input
              dense
              outlined
              rounded
              v-model="email"
              label="E-mail"
              id="email"
              :rules="[val => EmailValidator.isEmailValid(val) || 'niepoprawny format',val => !isBlank(val) || 'Podaj e-mail']"
          ></q-input>
          <div class="text-center q-pt-none q-pb-none"> <!-- Add the text-center class to this div -->
            <q-btn size="13px" flat color="accent" @click="router.push({ name: 'ApplicantLoginPage' });" icon="login"
                   label="Masz konto? - Zaloguj się"
                   no-caps/>
          </div>
        </q-card-section>
        <q-card-actions class="text-center q-pl-md q-pr-md q-pb-md" align="center">
          <q-btn no-caps rounded padding="3px xl" type="submit" color="primary" label="Resetuj"
                 @click="resetPassword()"></q-btn>
        </q-card-actions>
      </q-form>
    </q-card>
  </div>
</template>

<script setup>
import MenuBar from "@/components/common/MenuBar";
import {onMounted, ref} from 'vue';
import {useRouter} from "vue-router/dist/vue-router";
import {useQuasar} from 'quasar'
import ApplicantPasswordResetService from "@/services/applicant/ApplicantPasswordResetService";
import EmailValidator from "@/services/common/EmailValidator";

const email = ref('')

const $q = useQuasar();

const successfulReset = ref(false)
const router = useRouter()

const userId = ref(null)
const token = ref(null)
const newPassword = ref('')
const showPasswordSetupDialog = ref(false)
const successfulPasswordSetupDialog = ref(false)

onMounted(() => {
  userId.value = router.currentRoute.value.query.userId
  token.value = router.currentRoute.value.query.token
  if (userId.value !== undefined && token.value !== undefined) {
    showPasswordSetupDialog.value = true;
  } else {
    showPasswordSetupDialog.value = false;
  }
})


async function resetPassword() {
  const response = await ApplicantPasswordResetService.resetPassword(email.value);
  if (response.status === 200) {
    successfulReset.value = true;
  } else if (response.status === 400) {
    const json = await response.json()
    if (json.message.includes('Validation failed on fields: [email].')) {
      $q.notify({
        type: 'negative',
        message: 'Konto o podanym adresie e-mail jest już zarejestrowane w systemie.'
      })
    } else if (json.message.includes('email=must be a well-formed email address')) {
      $q.notify({
        type: 'negative',
        message: 'Niepoprawny format adresu e-mail'
      })
    }
  } else if (response.status === 429) {
    $q.notify({
      type: 'negative',
      message: 'Zbyt wiele prób resetu hasła'
    })
  } else {
    $q.notify({
      type: 'negative',
      message: 'Błąd podczas logowania - skontaktuj się z supportem.'
    })
  }
}


async function setNewPassword() {
  const response = await ApplicantPasswordResetService.resetSetupForPassword(userId.value, newPassword.value, token.value)
  if (response.status === 200) {
    successfulPasswordSetupDialog.value = true;
  } else if (response.status === 400) {
    $q.notify({
      type: 'negative',
      message: 'Token wygasł lub jest niepoprawny'
    })
  } else {
    $q.notify({
      type: 'negative',
      message: 'Błąd podczas logowania - skontaktuj się z supportem'
    })
  }
}

function isBlank(str) {
  return str === null || /^\s*$/.test(str);
}

</script>
<style>
.login-center {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -30%);
}
</style>