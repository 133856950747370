<template>
  <q-card bordered class="q-mt-none q-mb-md my-border"
          style="border-width: 0 0 2px 0; border-radius: 9px;"
          @mouseover="toggleMouseOverCard(true)"
          @mouseout="toggleMouseOverCard(false)"
          :class="{ 'q-hoverable': !isMouseOverLocations, 'cursor-pointer': !isMouseOverLocations}"
          @click="toJobOffers(props.application.jobOffer.id)">

    <span class="row col" :class="{ 'q-focus-helper': !isMouseOverLocations}" style="color: #3e799b"></span>
    <q-card-section class="q-px-xs q-pt-xs q-pb-none">
      <div class="row">
        <div class="q-pa-xs" style=" display: flex;   flex-direction: column;
  justify-content: center;
  align-items :center"
        >
          <q-img
              style="width: 65px;height: 65px; border-radius: 6px;"
              v-bind:src="props.application.jobOffer.organization.logoUrl"
              fit='fill'
              :ratio="1"
          />
        </div>
        <div class="col">
          <div class="row col">
            <div class="col q-pl-xs q-pt-sm q-pr-xs">
              <div class="justify-between col">
                <div class="q-pa-none row justify-between q-mr-md">
                  <div class="row col-auto q-pr-sm">
                    <p
                        style="font-size: 16px; z-index: 10"
                        id="title" ref="jobTitle"
                        class="q-pl-sm q-mb-sm ellipsis col">
                      <q-tooltip v-if="jobTitle !== null && isEllipsis" class="bg-white text-black">
                        {{ props.application.jobOffer.title }}
                      </q-tooltip>
                      {{ props.application.jobOffer.title }}
                    </p>
                  </div>
                  <div class="col">
                    <q-icon
                        name="fa-solid fa-fire"
                        class="col"
                        size="20px"
                        style="border-radius: 6px"
                        outline color="accent"
                    >
                    </q-icon>
                  </div>
                </div>
              </div>
            </div>

            <div class="q-mr-none q-mb-none row q-mr-sm q-mt-sm">
              <p class="q-mb-none text-accent q-mr-sm"
                 style="border-width: 1px; max-height: 23px; font-size: 14px; font-weight: 500"
              >
                {{ salaryToString() }}
              </p>
              <p v-if="props.application.jobOffer.salary!==null" class="q-mb-none text-grey-9"
                 style="border-width: 1px; max-height: 23px; font-size: 14px; font-weight: 400"
              >
                {{ props.application.jobOffer.salary.currency }}
              </p>
            </div>
          </div>
          <div class="col-auto row q-pl-sm col">
            <!--            <div class="col row">-->
            <!--              <q-icon class="q-pt-xs" name="fa-regular fa-building" :color="getColorBasedOnIfSelected()" size="16px"/>-->
            <!--            </div>-->
            <div class="col-auto row q-pr-sm">
              <p class="q-pl-xs q-pa-xs q-ma-none ellipsis col"
                 style="font-size: small;">
                {{ props.application.jobOffer.organization.name }}</p>
                <locations-info-dropdown
                    @mouseover="toggleMouseOverLocations(true)"
                    @mouseout="toggleMouseOverLocations(false)"
                    class="q-ml-md"
                    :is-selected="true"
                    style="padding-top: 2px; z-index: 100000"
                    :locations="props.application.jobOffer.locations"
                >
                </locations-info-dropdown>
            </div>
          </div>
        </div>
      </div>
    </q-card-section>
    <q-card-section class="q-pa-none">
    </q-card-section>
    <q-separator/>
    <q-card-actions class="justify-between q-xa-sm q-pt-sm q-py-xs">
      <div class="row">
        <q-chip outline class="q-ml-xs q-mb-none" style="border-radius: 5px" size="sm" color="grey-10"
                text-color="white"
                icon="fa-solid fa-paper-plane">
          {{ props.application.appliedAt }}
        </q-chip>
        <q-chip outline style="border-radius: 5px" size="sm" color="grey-10" text-color="white"
                icon="fa-regular fa-file">
          {{ props.application.resume.fileName }}
        </q-chip>
        <q-chip outline style="border-radius: 5px" size="sm" color="grey-10" text-color="white"
                icon="fa-solid fa-phone">
          kontakt od  {{ props.application.preferredContactTime.from }} do {{props.application.preferredContactTime.to}}
        </q-chip>
      </div>
      <div class="row">
        <div class="q-gutter-sm q-mr-sm">
          <q-badge v-for="(skill) in props.application.jobOffer.skills.slice(0, 3)"
                   :key="skill.name"
                   :label="skill.name"
                   color="grey-10"
                   outline
                   style="font-weight: 400;border-radius: 4px; font-size: 10px"/>
        </div>
        <div class="q-pa-none q-gutter-sm">
          <q-badge :label="props.application.jobOffer.seniority"
                   outline color="grey-9"
                   style="font-weight: 800;border-radius: 4px; font-size: 10px"/>
        </div>
      </div>
    </q-card-actions>
  </q-card>
</template>

<script setup>
import {defineProps, onMounted, ref} from "vue";
import {useRouter} from 'vue-router'
import LocationsInfoDropdown from "@/components/common/job-offers/LocationsInfoDropdown";
import NotificationService from "@/services/notification/NotificationService";
import {useQuasar} from "quasar";
const $q = useQuasar();

const router = useRouter()
const isEllipsis = ref(false)
const resizeDetector = ref(true)
const jobTitle = ref(null);
const props = defineProps({
  application: Object,
  borderColor: String,
})

function myEventHandler() {
  isEllipsis.value = isEllipsisActive()
}

function isEllipsisActive() {
  let e = jobTitle.value
  if (e != null) {
    return e.offsetWidth < e.scrollWidth && resizeDetector.value
  } else {
    return false;
  }
}

onMounted(() => {
  window.addEventListener("resize", myEventHandler);
  isEllipsis.value = isEllipsisActive()
})

function toJobOffers(id) {
  if(!props.application.jobOffer.active){
    NotificationService.showNotification($q,"negative","Oferta wygasła.")
  }else if (isMouseOverCard.value && !isMouseOverLocations.value) {
    router.push({name: 'JobOffersMainBoard', params: {id}});
  }
}

function salaryToString() {
  if (props.application.jobOffer.salary !== null) {
    return props.application.jobOffer.salary.from.toString() + ' - ' + props.application.jobOffer.salary.to.toString() + ' '
  } else {
    return 'BRAK STAWKI'
  }
}

const isMouseOverCard = ref(false)
const isMouseOverLocations = ref(false)

function toggleMouseOverLocations(val) {
  isMouseOverLocations.value = val;
}
function toggleMouseOverCard(val) {
  isMouseOverCard.value = val;
}

</script>

<style lang="scss" scoped>
.my-border {
  color: #666;
  position: relative;

}

.my-border:after {
  position: absolute;
  left: 0%;
  top: 2%;
  content: '';
  border-radius: 9px;
  height: 100%;
  transition: all 0.3s linear;
  width: 100%;
  border-bottom: 2px solid #151273;
  transform: scaleX(0);
}

.shrinkable {
  flex: 1 1 auto; /* Allow the column to shrink */
  min-width: 0; /* Prevents content from pushing the column to a wider width */
}

.my-border:hover:after {
  transform: scale(1);
}
</style>