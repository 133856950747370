import script from "./OrganizationAccountPage.vue?vue&type=script&setup=true&lang=js"
export * from "./OrganizationAccountPage.vue?vue&type=script&setup=true&lang=js"

import "./OrganizationAccountPage.vue?vue&type=style&index=0&id=2f757242&lang=scss"

const __exports__ = script;

export default __exports__
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QList from 'quasar/src/components/item/QList.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QPage from 'quasar/src/components/page/QPage.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QLayout,QHeader,QDrawer,QImg,QList,QBtn,QPageContainer,QPage,QCardSection});
