// services/ApplicationsService.js

import CookieService from "@/services/common/CookieService";
import FetchWrapper from "@/services/common/FetchWrapper";
import {currentUser} from "@/global-variables/currentUser";

class ApplicationsService {
    constructor(url) {
        this.url = url;
    }

    // TODO: Merge this method with OrganizationApplicationService

    async getApplications(params) {
        const requestOptions = {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "X-XSRF-TOKEN": CookieService.getCookieValue("XSRF-TOKEN"), // Add the CSRF token to the headers
            },
            body: JSON.stringify(params), // Add the params to the body
        };

        const url = `${process.env.VUE_APP_BACKEND_URL}/organizations/${currentUser.organizationId}/applications`;

        try {
            const response = await FetchWrapper.fetchWithAuth(url, requestOptions);
            const data = await response.json();
            return data;
        } catch (error) {
            console.error("Error fetching applications:", error);
            throw error;
        }
    }
}

export default new ApplicationsService(process.env.VUE_APP_BACKEND_URL);
