import script from "./ObservedApplicationsPage.vue?vue&type=script&setup=true&lang=js"
export * from "./ObservedApplicationsPage.vue?vue&type=script&setup=true&lang=js"

import "./ObservedApplicationsPage.vue?vue&type=style&index=0&id=c4a70e58&lang=scss"

const __exports__ = script;

export default __exports__
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QList from 'quasar/src/components/item/QList.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QPage from 'quasar/src/components/page/QPage.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QLayout,QDrawer,QImg,QList,QBtn,QPageContainer,QPage});
