<template>
  <div class="full-width" v-if="isDataInitialized">
    <q-form greedy ref="formRef">
      <div>
        <adding-offer-section-description
            title="Dane do faktury"
            description=" - są automatycznie pobierane z danych organizacji, jednak istnieje możliwość ich aktualizacji dla tego ogłoszenia.
">
        </adding-offer-section-description>


        <q-card flat class="q-mt-md q-mb-none full-width" style="border-radius: 9px">
          <q-card-section class="q-px-none q-pb-none">
            <div class="row q-gutter-md">
              <q-input rounded v-model="payment.billingData.companyName"
                       label-color="grey-5"
                       outlined
                       dense
                       :rules="[val => !StringUtils.isStringEmpty(val) || 'Podaj nazwę firmy']"
                       class="col bg-white text-grey-9"
                       label="Pełna nazwa firmy">
              </q-input>

              <q-input rounded
                       label-color="grey-5"
                       outlined
                       dense
                       class="col bg-white text-grey-9"
                       label="NIP"
                       :rules="[val => !StringUtils.isStringEmpty(val) || 'Podaj NIP']"
                       v-model="payment.billingData.nip">
              </q-input>

              <q-input rounded
                       v-model="payment.billingData.address.city"
                       label-color="grey-5"
                       outlined
                       dense
                       class="col bg-white text-grey-9"
                       :rules="[val => !StringUtils.isStringEmpty(val) || 'Podaj miasto']"
                       label="Miasto">
              </q-input>

              <q-input rounded
                       v-model="payment.email"
                       label-color="grey-5"
                       outlined dense
                       class="col bg-white text-grey-9"
                       :rules="[val => !StringUtils.isStringEmpty(val) || 'Podaj e-mail']"
                       label="e-mail">
              </q-input>
            </div>
          </q-card-section>
          <q-card-section class="q-px-none q-pt-sm">
            <div class="row q-gutter-md">
              <q-input v-model="payment.billingData.address.street"
                       rounded
                       label-color="grey-5"
                       outlined
                       dense
                       :rules="[val => !StringUtils.isStringEmpty(val) || 'Podaj ulicę']"
                       class="col bg-white text-grey-9"
                       label="Ulica">
              </q-input>

              <q-input v-model="payment.billingData.address.streetNumber"
                       rounded
                       label-color="grey-5"
                       outlined
                       dense
                       class="col bg-white text-grey-9"
                       :rules="[val => !StringUtils.isStringEmpty(val) || 'Podaj numer budynku']"
                       label="Numer budynku">
              </q-input>

              <q-input v-model="payment.billingData.address.flatNumber"
                       rounded
                       label-color="grey-5"
                       outlined
                       dense
                       class="col bg-white text-grey-9"
                       label="Numer lokalu">
              </q-input>

              <q-input v-model="payment.billingData.address.zipCode"
                       rounded
                       label-color="grey-5"
                       outlined
                       dense
                       class="col bg-white text-grey-9"
                       :rules="[val => !StringUtils.isStringEmpty(val) || 'Podaj kod pocztowy']"
                       label="Kod pocztowy">
              </q-input>
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div>
        <adding-offer-section-description title="Płatność" description=" - możesz zrealizować standardowo przy pomocy portalu Przelewy24 lub zapłacić za pomocą kodu."/>
        <div class="q-mt-lg row q-gutter-sm">
          <q-input
              autofocus="autofocus"
              ref="codeInputRef"
              v-model="payment.promoCode"
              rounded style="border-radius: 9px"
              label-color="grey-5"
              outlined
              :reactive-rules="true"
              lazy-rules
              dense class="col bg-white text-grey-9"
              label="Podaj kod do zrealizowania płatności">
          </q-input>
          <q-btn class="col-2" color="primary" no-caps dense style="max-height: 40px;border-radius: 7px" label="Weryfikuj"
                 @click="getPromoCode(payment.promoCode)">
          </q-btn>
        </div>
      </div>
      <q-card style="border-radius: 9px" class="q-mt-md">
        <q-card-section>
          <div class="row justify-between">
            <div>
              <q-chip outline class="q-mr-sm" no-caps style="border-radius: 7px" color="grey-9" text-color="white" icon="event">
                {{ props.plan.type }}
              </q-chip>
            </div>
            <div>
              <my-text class="text-grey-5" style="font-size: 20px;">
                <span class="text-green-7 text-bold">&nbsp;{{ planPrice }} zł&nbsp;</span>
                <span style="font-size: 14px; font-weight: lighter" class="text-grey-7">netto/ogłoszenie</span>
              </my-text>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-form>
  </div>
</template>

<script setup>
import {computed, defineEmits, defineExpose, defineProps, onMounted, ref} from "vue";
import {useQuasar} from "quasar";

import JobOfferPaymentsService from "@/services/payments/JobOfferPaymentsService";
import NotificationService from "@/services/notification/NotificationService";
import OrganizationDataService from "@/services/organization/OrganizationDataService";
import PromoCodeWrongTypeError from "@/services/payments/PromoCodeWrongTypeError";
import organizationUsersService from "@/services/organization/OrganizationUsersService";
import StringUtils from "@/services/common/StringUtils";
import AddingOfferSectionDescription from "@/components/common/add-offer/AddingOfferSectionDescription";

const emit = defineEmits(['update:payment'])

const props = defineProps({
  plan: Object,
  isLoggedOrganizationUser: Boolean,
  payment: Object
});


const payment = computed({
  get: () => props.payment,
  set: (val) => emit('update:payment', val),
});

onMounted(() => {
  planPrice.value = findInitialPrice(props.plan)
  if (props.isLoggedOrganizationUser) {
    initInvoiceDataIfPresent();
  } else {
    isDataInitialized.value = true;
  }
});

const isDataInitialized = ref(false)
const planPrice = ref(0)

function findInitialPrice(plan) {
  if (plan.promotionalPrice === null || plan.promotionalPrice === undefined) {
    return plan.originalPrice.amount
  } else {
    return plan.promotionalPrice.amount
  }
}

function initInvoiceDataIfPresent() {
  OrganizationDataService.getOrganizationInfo().then(response => {
    response.json().then(body => {
      if (body.billingData !== null) {
        payment.value.billingData.companyName = body.billingData.companyName;
        payment.value.billingData.nip = body.billingData.nip;
        payment.value.billingData.address.city = body.billingData.address.city;
        payment.value.billingData.address.street = body.billingData.address.street;
        payment.value.billingData.address.streetNumber = body.billingData.address.streetNumber;
        payment.value.billingData.address.flatNumber = body.billingData.address.flatNumber;
        payment.value.billingData.address.zipCode = body.billingData.address.zipCode;
        organizationUsersService.getCurrentUser().then(response => {
          payment.value.email = response.email
        });
      }
      isDataInitialized.value = true
    })
  });
}

const $q = useQuasar();
const isCodeCorrect = ref(true);

async function getPromoCode(code) {
  try {
    return await JobOfferPaymentsService.evaluatePlanPrice(code, props.plan.type).then(amount => {
      planPrice.value = amount;
      NotificationService.showNotification($q, 'positive', 'Pozytywnie zweryfikowano kod do płatności');
      isCodeCorrect.value = true;
    });
  } catch (e) {
    if (e instanceof PromoCodeWrongTypeError) {
      NotificationService.showNotification($q, "negative", e.message);
      isCodeCorrect.value = false;
      validateCodeInput();
    } else {
      NotificationService.showNotification($q, 'negative', 'Niepoprawny kod do płatności');
      isCodeCorrect.value = false;
      validateCodeInput();
    }
  }
}

const codeInputRef = ref(null);

function validateCodeInput() {
  codeInputRef.value.validate();
}

const formRef = ref(null)

function validate() {
  return formRef.value.validate().then(function (resolvedValue) {
    return resolvedValue;
  }).catch(function (error) {
    console.error('Validation error:', error);
  });
}

function getFinalPrice(){
  return planPrice.value
}
defineExpose({
  validate,
  getFinalPrice
})
</script>

<style lang="sass">
.q-input--outlined.q-input--rounded .q-input__control
  border-radius: 9px
</style>
