import CookieService from "@/services/common/CookieService";
import FetchWrapper from "@/services/common/FetchWrapper";
import {currentUser} from "@/global-variables/currentUser";

class OrganizationDataService {
    constructor(url) {
        this.url = url;
    }

    async getOrganizationInfo() {
        const url = `${this.url}/organizations/${currentUser.organizationId}`;
        try {
            const requestOptions = {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
            };

            const response = await FetchWrapper.fetchWithAuth(url, requestOptions);
            return response; // Return the raw Response object
        } catch (error) {
            console.error("Error during organization GET method:", error);
            throw error;
        }
    }

    async updateOrganizationInfo(organizationData) {
        const url = `${this.url}/organizations/${currentUser.organizationId}`;
        try {
            const requestOptions = {
                method: "PUT",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                    "X-XSRF-TOKEN": CookieService.getCookieValue("XSRF-TOKEN"),
                },
                body: JSON.stringify(organizationData),
            };

            const response = await FetchWrapper.fetchWithAuth(url, requestOptions);
            return response; // Return the raw Response object
        } catch (error) {
            console.error("Error during organization update PUT method:", error);
            throw error;
        }
    }

    async changeLogo(logoInBase64) {
        const url = `${this.url}/organizations/${currentUser.organizationId}/logo`;
        try {
            const requestOptions = {
                method: "PUT",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                    "X-XSRF-TOKEN": CookieService.getCookieValue("XSRF-TOKEN"),
                },
                body: JSON.stringify({ logoInBase64 }),
            };

            const response = await FetchWrapper.fetchWithAuth(url, requestOptions);
            return response; // Return the raw Response object
        } catch (error) {
            console.error("Error during organization logo update PUT method:", error);
            throw error;
        }
    }

    areMandatoryFieldsMissing(organizationData) {
        const isTypeMissing = !organizationData.type;
        const isIndustryMissing =
            !organizationData.industries || organizationData.industries.length <= 0;
        const isSizeMissing = !organizationData.size;
        const isLogoUrlEmpty = this.isStringEmpty(organizationData.logoUrl);

        return isTypeMissing || isIndustryMissing || isSizeMissing || isLogoUrlEmpty;
    }

    isStringEmpty(str) {
        return /^\s*$/.test(str);
    }
}

export default new OrganizationDataService(process.env.VUE_APP_BACKEND_URL);
