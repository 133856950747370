<template>
  <q-card :bordered="isSelected" class="q-mt-none q-mb-md"
          style="border-width: 0 0 1px 0; border-radius: 9px;"
          :style="calculateBorderColor()"
          @mouseover="toggleMouseOverCard(true)"
          @mouseout="toggleMouseOverCard(false)"
          :class="{ 'q-hoverable': !isMouseOverFavBtn, 'cursor-pointer': !isMouseOverFavBtn, 'my-border': !isSelected && !isMouseOverFavBtn }"
          @click="returnId(props.jobOffer.id)">

    <span :class="{ 'q-focus-helper': !isMouseOverFavBtn}" style="color: #3e799b"></span>
    <q-card-section class="q-px-xs q-pt-xs q-pb-none">
      <div class="row">
        <div class="q-pa-xs" style=" display: flex;   flex-direction: column;
  justify-content: center;
  align-items :center">
          <q-img
              style="width: 35px;height: 35px; border-radius: 6px;"
              v-bind:src="props.jobOffer.organization.logoUrl"
              fit="fill"
          />
        </div>
        <div class="col q-pl-xs q-pt-sm q-pr-none">
          <div class="justify-between">
            <div class="q-pa-none row justify-between">
              <div class="row col">
                <p :class="getTextColorBasedOnIfSelected()"
                   class="q-mb-sm ellipsis col-8"
                   style="font-size: 11px"
                   :style="getFontWeightBasedOnIfSelected()"
                >
                  {{ props.jobOffer.title }}
                </p>
                <q-icon
                    v-if="jobOffer.highlighted"
                    name="fa-solid fa-fire"
                    class="q-ml-sm q-mt-none text-weight-bold" size="16px"
                    style="border-radius: 6px"
                    outline color="accent">
                </q-icon>
              </div>
              <div class="q-mr-sm q-mb-none row col-4">
                <p class="q-mb-none text-weight-bold" v-if="isSelected"
                   style="border-width: 1px; max-height: 23px; font-size: 8px; font-weight: 800"
                   :class="getSalaryColor()">
                  {{ salaryToString() }}
                </p>
                <p class="q-mb-none" v-else
                   style="border-width: 1px; max-height: 23px; font-size: 8px; font-weight: 400"
                   :class="getSalaryColor()">
                  {{ salaryToString() }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <q-btn v-if="isFav" flat @click="addOrRemoveFromObserved()"
                 @mouseover="toggleMouseOverFavBtn(true)"
                 @mouseout="toggleMouseOverFavBtn(false)"
                 no-caps
                 class="q-mt-none q-px-xs q-pr-xs q-pl-none q-mr-none q-ml-none"
                 color="accent">
            <q-icon size="xs" name="fa-solid fa-star" class="q-py-none q-pr-none q-mr-sm q-pl-none"/>
          </q-btn>
          <q-btn id="myElement" v-else flat @click="addOrRemoveFromObserved()"
                 class="q-mt-none q-pr-xs q-pl-none q-mr-none q-ml-xs"
                 @mouseover="toggleMouseOverFavBtn(true)"
                 @mouseout="toggleMouseOverFavBtn(false)"
                 no-caps
                 color="grey-5">
            <q-icon size="xs" name="fa-solid fa-star" class="q-py-none q-pr-none q-mr-sm q-pl-none"/>
          </q-btn>
        </div>
      </div>
    </q-card-section>

    <q-separator/>
    <q-card-actions class="justify-between q-pa-xs q-ml-xs">
      <div class="q-gutter-xs">
        <q-chip v-for="(skill) in props.jobOffer.skills.slice(0, 3)"
                :key="skill.name"
                :label="skill.name"
                outline
                class="q-mb-none"
                :color="getColorBasedOnIfSelected()"
                size="7px"
                style="font-weight: 400;border-radius: 4px;"/>
      </div>

      <div v-if="isSelected" class="q-pa-none q-gutter-sm">
        <q-chip :label="props.jobOffer.seniority"
                outline color="primary"
                class="q-mb-none"
                size="7px"
                style="font-weight: 400;border-radius: 4px;"/>
      </div>
      <div v-else class="q-pa-none q-gutter-sm ">
        <q-chip :label="props.jobOffer.seniority"
                outline color="grey-6"
                class="q-mb-none"
                size="7px"
                style="font-weight: 400;
                 border-radius: 4px"/>
      </div>
    </q-card-actions>
  </q-card>
</template>

<script setup>
import {defineProps, defineEmits, ref, onMounted} from "vue";

const props = defineProps({
  jobOffer: Object,
  isSelected: Boolean,
  borderColor: String,
  isFav: Boolean
})

onMounted(() => {
  isFav.value = props.isFav
})
const emit = defineEmits(['jobOffer:id', 'update:fav'])

function returnId(id) {
  if (!isMouseOverFavBtn.value) {
    emit('jobOffer:id', id)
  }
}

function salaryToString() {
  if (props.jobOffer.salary !== null) {
    const from = props.jobOffer.salary.from.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    const to = props.jobOffer.salary.to.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return from + ' - ' + to + ' ' + props.jobOffer.salary.currency;
  } else {
    return 'Nie podano stawki';
  }
}


function getSalaryColor() {
  if (props.isSelected) {
    return 'text-primary'
  } else {
    return 'text-grey-6'
  }
}

import applicantObservedOffers from "@/services/applicant/ApplicantObservedOffers";
import {currentUser} from "@/global-variables/currentUser";

const isFav = ref(false)

function addOrRemoveFromObserved() {
  if (currentUser.isAuthenticated()) {
    addObservedOfferForLoggedUser(currentUser.id)
  } else {
    if (isFav.value) {
      isFav.value = false;
      emit('fav:remove', props.jobOffer.id)
    } else {
      isFav.value = true;
      emit('fav:add', props.jobOffer.id)
    }
  }

}

function addObservedOfferForLoggedUser(userId) {
  if (isFav.value) {
    applicantObservedOffers.deleteObservedOffers(userId, props.jobOffer.id)
        .then(response => {
          if (response.status === 204) {
            isFav.value = false;
            emit('fav:remove', props.jobOffer.id)
          }
        })
  } else {
    applicantObservedOffers.addObservedOffers(userId, props.jobOffer.id)
        .then(response => {
          if (response.status === 204) {
            isFav.value = true;
            emit('fav:add', props.jobOffer.id)
          }
        })
  }
}

const isMouseOverFavBtn = ref(false)

function toggleMouseOverFavBtn(val) {
  isMouseOverFavBtn.value = val;
}

const isMouseOverCard = ref(false)

function toggleMouseOverCard(val) {
  isMouseOverCard.value = val;
}

function getColorBasedOnIfSelected() {
  if (props.isSelected) {
    return 'primary'
  } else {
    return 'grey-6'
  }
}

function getTextColorBasedOnIfSelected() {
  if (props.isSelected) {
    return 'text-primary'
  } else {
    return 'text-grey-6'
  }
}

function calculateBorderColor() {
  if (props.isSelected) {
    return 'border-color: #103279'
  } else {
    return 'border-color: #ffffff'
  }
}

function getFontWeightBasedOnIfSelected() {
  return props.isSelected ? 'font-weight: 600' : 'font-weight: 300'
}
</script>

<style lang="scss" scoped>
.my-border {
  color: #666;
  position: relative;
}

.my-border:after {
  position: absolute;
  left: 0%;
  top: 0%;
  content: '';
  border-radius: 9px;
  height: 100%;
  transition: all 0.3s linear;
  width: 100%;
  border-bottom: 2px solid #151273;
  transform: scaleX(0);
  bottom: -2px;
}

.my-border:hover:after {
  transform: scale(1);
}
</style>