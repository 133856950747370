<template>
  <q-card square class="full-width row justify-between q-pl-none  bg-gradient-menu"
          style="padding-top: 17px; padding-bottom: 17px">
    <div class="row q-pl-none">
      <div>
        <q-btn
            style="border-radius: 7px;"
            class="q-px-lg q-ml-md text-white"
            flat
            size="md"
            icon="fa-solid fa-briefcase"
            label="Oferty pracy"
            no-caps
            dense
            @click="toJobOffers()"/>
      </div>
      <add-job-offer class="q-pl-md"></add-job-offer>
      <div class="q-pl-md q-pr-lg">
        <q-btn rounded align="left"
               label="Dla pracodawcy"
               class="q-px-md text-white"
               dense
               no-caps
               @click="toEmployerOfferBoardOrOrganizationLogin()"/>
      </div>
    </div>
    <div class="row float-right q-mr-xs">
      <div v-if="registrationAvailable">
        <registration></registration>
      </div>
      <div class="q-pb-none q-mr-sm">
        <login v-if="!currentUser.isAuthenticated()"></login>
        <q-btn-dropdown
            v-else
            dense
            outline
            padding="3px md"
            :label=currentUser.email
            active-icon="account_circle"
            vertical-actions-align="right"
            color="white"
            no-caps
            flat
            square
            @click="initUserAvatar()"
            style="border-radius: 7px"
            icon="account_circle"
            direction="down"
        >
          <div class="row q-pa-sm justify-start">
            <div class="col-auto">
              <q-avatar class="text-center q-mt-none q-ma-xs q-mr-md" size="85px" style="background-color: #c9d7ec">
                <q-img
                    v-if="userAvatar && userAvatar.contentType && userAvatar.file"
                    fit="fill"
                    :src="'data:' + userAvatar.contentType + ';base64,' + userAvatar.file"
                    style="width: 100%; height: 100%;"
                />
                <div
                    v-else
                    class="row items-center justify-center"
                    :class="DefaultAvatarService.getColorByInitials(currentUser)"
                    style="width: 100%; height: 100%; padding-bottom: 3px"
                >
                  <div>{{ DefaultAvatarService.getInitials(currentUser) }}</div>
                </div>
              </q-avatar>
            </div>

            <q-separator vertical></q-separator>
            <div class="col column q-pl-sm items-center">
              <q-btn class="q-mb-xs" size="sm" flat color="primary" label="profil"
                     icon-right="person" @click="toUserProfile()"></q-btn>
              <password-change :user="currentUser"></password-change>

              <q-btn class="" size="sm" color="primary" @click="logout()"
                     icon-right="logout" label="wyloguj"/>
            </div>
          </div>
        </q-btn-dropdown>
      </div>
    </div>
  </q-card>
</template>

<script setup>
import Login from "@/components/common/login/LoginDropdown";
import Registration from "@/components/common/registration/Registration";
import AddJobOffer from "@/components/common/add-offer/verification/AddJobOfferButton";
import {useRouter} from 'vue-router'
import {defineEmits, onMounted, ref} from "vue";
import AuthenticationService from '@/services/AuthenticationService';
import PasswordChange from "@/components/organization/organization-accounts/PasswordChange";
import ApplicantDataService from "@/services/applicant/ApplicantDataService";
import {currentUser} from '@/global-variables/currentUser.js';
import DefaultAvatarService from "@/services/common/DefaultAvatarService";

const router = useRouter()
onMounted(() => {
  isRegistrationAvailable();
})

function toJobOffers() {
  router.push({name: 'JobOffersMainBoard'})
}

function toEmployerOfferBoardOrOrganizationLogin() {
  // TODO ADD DIFFERENT BEHAVIOUR FOR ORGANIZATION USER AND REGULAR USER
  if (currentUser.isAuthenticated()) {
    router.push({name: 'OrganizationJobOffersBoard'})
  } else {
    router.push({name: 'OrganizationLoginPage'});
  }
}

const emit = defineEmits(['logout'])


async function logout() {
  await AuthenticationService.logout();
  await localStorage.clear();
  emit('logout');
  currentUser.resetCurrentUser()
  isRegistrationAvailable()
}

function toUserProfile() {
  if (currentUser.isApplicant()) {
    router.push({name: 'ApplicantProfilePage'});
  } else {
    router.push({name: 'OrganizationAccountPage'});

  }
}

const userAvatar = ref(null)

function initUserAvatar() {
  ApplicantDataService.getAvatar(currentUser.id).then(response => {
    response.json().then(json => {
      userAvatar.value = json
    });
  })
}


const registrationAvailable = ref(false);

async function isRegistrationAvailable() {
  try {
    registrationAvailable.value = !currentUser.isAuthenticated()
  } catch (error) {
    console.error("Error:", error);
    throw error
  }
}
</script>

<style lang="scss" scoped>
//.bg-my {
//  background: linear-gradient(45deg, white, $secondary)
//}
//.q-icon .on-left .mdi .mdi-briefcase-edit-outline {
//  margin-right: 8px;
//  margin-left: 8px;
//}

.bg-gradient-menu {
  background: linear-gradient(45deg, black, lighten($primary, 20%));
  color: white; /* Text color */
}

//:deep(.q-btn--dense .on-left) {
//  margin-left: 5px !important;
//  margin-right: 10px !important;
//}


</style>

