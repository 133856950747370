<template>
  <div class="full-width">
    <adding-offer-section-description title="Tryb pracy" description=" - określ miejsce wykonywania pracy."/>
    <div class="row q-pb-sm q-mt-xs">
      <q-checkbox
          v-model="offer.operatingModes" label="Praca stacjonarna"
          :val="props.formTemplateData.jobOffer.operatingModes[0]"
          checked-icon="fa-regular fa-square-plus"
          unchecked-icon="fa-regular fa-square-minus"
          color="accent"
          class="q-pr-lg text-grey-8"
      />
      <q-checkbox
          v-model="offer.operatingModes" label="Praca hybrydowa"
          :val="props.formTemplateData.jobOffer.operatingModes[1]"
          checked-icon="fa-regular fa-square-plus"
          color="accent"
          unchecked-icon="fa-regular fa-square-minus"
          class="q-pr-lg text-grey-8"
      />
      <q-checkbox
          v-model="offer.operatingModes" label="Praca zdalna" :val="props.formTemplateData.jobOffer.operatingModes[2]"
          checked-icon="fa-regular fa-square-plus"
          color="accent"
          unchecked-icon="fa-regular fa-square-minus"
          class="q-pr-lg text-grey-8"
      />
    </div>

    <div class="q-pt-md">
      <adding-offer-section-description title="Zatrudnienie" description=" - określ czasowy wymiar pracy."/>
      <div class="row q-pb-sm q-mt-xs">
        <q-checkbox
            v-model="offer.workTypes" label="Pełny etat" val="FULL_TIME"
            checked-icon="fa-regular fa-square-plus"
            unchecked-icon="fa-regular fa-square-minus"
            color="accent"
            class="q-pr-lg text-grey-8"
        />
        <q-checkbox
            v-model="offer.workTypes" label="Część etatu" val="PART_TIME"
            checked-icon="fa-regular fa-square-plus"
            color="accent"
            unchecked-icon="fa-regular fa-square-minus"
            class="q-pr-lg text-grey-8"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, defineEmits, defineProps} from 'vue'
import AddingOfferSectionDescription from "@/components/common/add-offer/AddingOfferSectionDescription";

const props = defineProps({
  formTemplateData: Object,
  modelValue: Object
})

const offer = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val),
});

const emit = defineEmits(['update:modelValue'])

</script>