<template>
  <q-dialog v-model="showApplicationNotAvailableForOrganizationUser">
    <q-card style="width: 280px; border-radius: 9px;">
      <q-card-section class="row justify-center q-px-none q-pt-md q-pb-md">
        <div class="text-center text-h7 text-grey-9">
          <div class="q-pt-none q-pb-sm q-mb-xs">
            <q-icon size="md" name="fa-solid fa-circle-info" color="positive"></q-icon>
          </div>
          <span style="font-size: 14px;font-weight: 400">Aby zaaplikować na ofertę</span>
          <div class="row">
            <div class="text-center q-pt-none q-pb-none q-mt-xs"> <!-- Add the text-center class to this div -->
              <q-btn size="13px" flat color="accent" @click="router.push({ name: 'ApplicantLoginPage' });"
                     label="Zaloguj się"
                     class="q-px-sm"
                     dense
                     no-caps/>
            </div>
            <span class="q-mx-xs" style="font-size: 12px; padding-top: 10px">lub</span>
            <q-btn size="13px" flat color="primary" @click="router.push({ name: 'EmployeeRegistration' });"
                   label="zarejestruj"
                   class="text-center q-pt-none q-pb-none q-mt-xs q-px-sm"
                   dense
                   no-caps/>
          </div>
          <span style="font-size: 12px">używając konta aplikanta</span>

        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
  <div id="scrollArea" ref="scrollArea" :style="{ paddingTop: paddingTop }"
       class="col row q-px-xs full-width column" style="overflow-y: auto;"
       :class="getClassForAddingOfferPageDetails()">
    <div :class="justifyContent" class="full-width">
      <intro-card
          :id="props.jobOffer.id"
          :branding="props.jobOffer.organization.branding"
          :color-branding-available="props.jobOffer.colorBrandingAvailable"
          :company-name="props.jobOffer.organization.name"
          :contracts="getContracts()"
          :facebook-page-url="props.jobOffer.organization.facebookPageUrl"
          :instagram-page-url="props.jobOffer.organization.instagramPageUrl"
          :job-position="props.jobOffer.jobPosition"
          :logo="props.jobOffer.organization.logoUrl"
          :logo-in-base64="props.logoInBase64"
          :operating-modes="props.jobOffer.operatingModes"
          :seniority-level-name="props.jobOffer.seniority"
          :title="props.jobOffer.title"
          :web-page-url="props.jobOffer.organization.webPageUrl"
          :work-types="props.jobOffer.workTypes"
          :is-copy-available="props.isCopyLinkAvailable"
      >
      </intro-card>
      <requirements-card :job="props.jobOffer" :skills="props.jobOffer.skills" class="q-pt-md"/>
      <description :description="props.jobOffer.description" class="q-pt-md"></description>
      <organization-description
          v-if="props.jobOffer.organization.description"
          :description="props.jobOffer.organization.description"
          class="q-pt-md">
      </organization-description>
      <similar-offers v-if="showApply"
                      :job-offer-id="props.jobOffer.id"
                      class="q-pt-md">
      </similar-offers>
    </div>
  </div>
  <div v-if="showApply" class=" q-px-xs">
    <q-card bordered class=" q-pa-sm row justify-between q-mr-xs"
            style="border-radius: 7px; border-color: lightgray; border-width: 1px">
      <div>
        <q-btn align="center" class="bg-accent text-white q-pr-md" icon="fa-solid fa-paper-plane" dense label="Aplikuj"
               size="md"
               style=" border-radius: 6px" @click="toggleUserCard()">
        </q-btn>
      </div>
      <div class="q-pr-sm">
        <q-chip class="q-ma-none bg-white" color="white"
                icon="alarm" size="15px" square
                text-color="accent">
          Ważna jeszcze {{ daysToPublicationEnd(jobOffer.publication.publishEndAt) }} dni
        </q-chip>
      </div>
    </q-card>
  </div>
  <q-dialog v-model="showApplicationDialog">
    <job-application :job-offer-id="jobOffer.id" :position="jobOffer.title" :questions="jobOffer.questions"
                     @close-modal="closeModal()"></job-application>
  </q-dialog>
  <q-dialog v-model="showApplyConfirmationDialog">
    <q-card style="border-radius: 9px">
      <q-card-section class="q-pa-md q-px-lg">
        <div class="text-center q-mb-sm q-mt-xs">
          <q-icon color="positive" name="fa-regular fa-circle-check" size="50px"/>
        </div>
        <div class="q-mb-xs text-center">
              <span class="q-pt-xs text-grey-9 "
                    style="font-weight: 400; font-size: 15px">Twoje CV zostało przesłane
        </span>
        </div>
        <div class="text-center">
          <span class=" text-grey-9"
                style="font-weight: 300; font-size: 13px">{{ jobOffer.title }}
        </span>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
  <q-dialog v-model="provideCurrentUserMissingDataDialog">
    <q-card style="border-radius: 9px">
      <q-card-section class="q-pa-sm q-px-lg">
        <div class="q-pt-none q-pb-xs q-mb-xs text-center q-mt-xs">
          <q-icon size="md" name="fa-solid fa-circle-info" color="positive"></q-icon>
        </div>
        <div class="q-mb-xs">
              <span class="q-pt-xs text-grey-9 text-center"
                    style="font-weight: 400; font-size: 15px">Aby aplikować na oferty pracy uzupełnij dane profilu
        </span>
        </div>
        <div class="text-center q-mb-xs">
          <span class=" text-grey-9"
                style="font-weight: 300; font-size: 13px">{{ jobOffer.title }}
        </span>
        </div>
        <q-card-section class="text-center q-pa-sm">
          <q-btn class="q-mr-sm" no-caps padding="3px xl" color="primary" label="Ok"
                 @click="toApplicantProfileWithMissingDataMsg()"></q-btn>
          <q-btn outline no-caps padding="3px xl" color="primary" label="Później"
                 @click="provideCurrentUserMissingDataDialog.value = false"></q-btn>
        </q-card-section>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script setup>
import {defineExpose, defineProps, ref} from 'vue';
import Description from "@/components/common/job-offers/job-offer-details/Description";
import IntroCard from "@/components/common/job-offers/job-offer-details/intro-card/IntroCard";
import RequirementsCard from "@/components/common/job-offers/job-offer-details/RequirementsCard";
import OrganizationDescription from "@/components/common/job-offers/job-offer-details/OrganizationDescription";
import JobApplication from "@/components/common/job-offers/applications/JobApplication";
import SimilarOffers from "@/components/common/job-offers/SimilarOffers";
import ScrollUtils from "@/services/common/ScrollUtils";
import {currentUser} from '@/global-variables/currentUser.js';
import {useRouter} from "vue-router/dist/vue-router";

const showApplyConfirmationDialog = ref(false)
const showApplicationNotAvailableForOrganizationUser = ref(false)

function showAndHideApplyingConfirmationDialog() {
  showApplyConfirmationDialog.value = true;
  setTimeout(() => {
    showApplyConfirmationDialog.value = false;
  }, 2300);
}

const props = defineProps({
  maxHeight: Number,
  jobOffer: Object,
  showApply: Boolean,
  logoInBase64: String,
  paddingTop: {
    type: String,
    default: '50px',
  },
  justifyContent: {
    type: String,
    default: 'justify-center',
  },
  isCopyLinkAvailable: {
    type: Boolean,
    required: true
  }
});

const showApplicationDialog = ref(false);
const provideCurrentUserMissingDataDialog = ref(false);
const toggleUserCard = () => {
  if (props.jobOffer.jobApplicationInfo.applicationType === 'EXTERNAL') {
    window.open(props.jobOffer.jobApplicationInfo.externalApplicationLink, '_blank')
  } else {
    if (currentUser.isAuthenticated() && !currentUser.isApplicant()) {
      showApplicationNotAvailableForOrganizationUser.value = true
    } else if (!currentUser.isAuthenticated() || currentUser.isAuthenticated() && currentUser.isApplicant() && currentUser.isRequiredApplicationDataProvided()) {
      showApplicationDialog.value = !showApplicationDialog.value;
    } else {
      provideCurrentUserMissingDataDialog.value = true;
    }

  }
};


function closeModal() {
  showApplicationDialog.value = false;
  showAndHideApplyingConfirmationDialog();
}

function scrollToTop() {
  ScrollUtils.scrollToTop('scrollArea')
}

defineExpose({
  scrollToTop
})

function getContracts() {
  if (props.jobOffer.publication === null || props.jobOffer.publication === undefined) {
    return props.jobOffer.contracts;
  } else {
    return props.jobOffer.publication.contracts;
  }
}

function daysToPublicationEnd(publishEndAtStr) {
  // Parse the date strings to Date objects
  const publishEndAt = new Date(publishEndAtStr);
  const currentDate = new Date();
  const differenceInTime = publishEndAt - currentDate;
  return Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));
}

const router = useRouter()

function toApplicantProfileWithMissingDataMsg() {
  provideCurrentUserMissingDataDialog.value = false
  let message = 'Aby aplikować na oferty wymagane jest uzupełnienie profilu. Dodaj imię, naziwsko oraz przynajmniej jedno CV.'
  router.push({name: 'ApplicantProfilePage', query: {infoMessage: message}});

}

function getClassForAddingOfferPageDetails() {
  if (props.showApply) {
    return 'q-scrollarea'
  } else {
    return ''
  }
}
</script>

<style lang="scss" scoped>
.q-chip {
  background: linear-gradient(45deg, $primary, $primary)
}

.q-footer {
  background: linear-gradient(45deg, $secondary, $primary)
}

my-text {
  color: $accent
}


.q-chip {
  margin-left: 0px;
}

.fixed-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.fixedElementTop {
  background-color: rgb(226,231,239);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

.fixedElementBottom {
  background-color: rgb(226,231,239);
  //position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100;
}

html, body {
  background-color: rgb(226,231,239);
  height: 100vh;
}

.scrollable-area {
  height: auto;
  overflow-y: auto;
}


.bg-gradient {
  background: linear-gradient(20deg, $primary, $light-blue-11); /* Use your primary and accent colors here */
  color: white; /* Text color */
}


::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-button {
  background: #ffffff
}

::-webkit-scrollbar-track-piece {
  background: #ffffff
}

::-webkit-scrollbar-thumb {
  background: #8e959a
}
</style>