<template>
  <q-layout>
    <q-drawer
        v-model="drawerLeft"
        show-if-above
        @mouseover="miniStateLeft = false"
        @mouseout="miniStateLeft = true"
        :breakpoint="500"
        :width="250"
        class="bg-gradient"
        bordered
    >
      <div>
        <div class="text-center q-mt-lg">
          <q-img fit="contain" :src="logo" style="width: 100px; height: 100px"/>
        </div>

        <div class="text-center">
          <my-text class="text-bold text-white" style="font-size: 30px;">Byte Job</my-text>
        </div>
      </div>

      <q-list class="column q-col-gutter-sm" style="margin-top: 30px">
        <div class="q-mx-md">
          <q-btn
              rounded
              @click="router.push({ name: 'OrganizationAccountPage' });"
              dense
              flat
              style="border-radius: 6px"
              size="15px"
              icon="fa-regular fa-user"
              align="left"
              class="q-mx-lg full-width text-white"
              color="primary"
              label="Mój profil"
              no-caps
          />
        </div>
        <div class="q-mx-md">
          <q-btn
              rounded
              @click="router.push({ name: 'OrganizationJobOffersBoard' });"
              dense
              flat
              style="border-radius: 6px"
              size="15px"
              icon="fa-solid fa-briefcase"
              align="left"
              class="q-mx-lg full-width text-white bg-dark"
              color="primary"
              label="Ogłoszenia"
              no-caps
          />
        </div>
        <div class="q-mx-md">
          <q-btn
              rounded
              @click="router.push({ name: 'OrganizationAccountManagement' });"
              dense
              flat
              style="border-radius: 6px"
              size="15px"
              icon="fa-solid fa-address-book"
              align="left"
              class="q-mx-md full-width text-white"
              color="white"
              label="Konta"
              no-caps
          />
        </div>
        <div class="q-mx-md">
          <q-btn
              rounded
              @click="router.push({ name: 'OrganizationDataPage' });"
              dense
              flat
              style="border-radius: 6px"
              size="15px"
              icon="fa-solid fa-building"
              align="left"
              class="q-mx-md full-width text-white"
              color="white"
              label="Dane firmy"
              no-caps
          />
        </div>
        <div class="q-mx-md">
          <q-btn
              rounded
              @click="router.push({ name: 'InvoicesMainBoard' });"
              dense
              flat
              style="border-radius: 6px"
              size="15px"
              icon="fa-solid fa-file-lines"
              align="left"
              class="q-mx-md full-width text-white"
              color="white"
              label="Faktury"
              no-caps
          />
        </div>
        <div class="q-mx-md">
          <q-btn
              rounded
              @click="AuthenticationService.logout()"
              dense
              flat
              style="border-radius: 6px"
              size="15px"
              icon="fa-solid fa-arrow-right-from-bracket"
              align="left"
              class="q-mx-md full-width text-white q-mt-lg"
              color="primary"
              label="Wyloguj"
              no-caps
          />
        </div>
      </q-list>
    </q-drawer>
    <q-page-container style="background-color: #eaf1f6;">
      <q-page class="column">
        <div>
          <quesion-filter v-model:show-dialog="showQuestionFilterDialog"
                          :questions-for-filter-model="questionsForFilterModel"
                          @questions-filter="updateQuestionFiltering()">
          </quesion-filter>
          <multiple-emails-dialog v-model:show-dialog="showMultipleEmailsDialog" @show:mails="handleOpenMails">
          </multiple-emails-dialog>
          <multiple-download-dialog-confirmation
              v-model:show-download-dialog="showDownloadsDialog"
              v-model:checked-apps-count="checkedApps"
              @download:applications="downloadAllCheckedCV">
          </multiple-download-dialog-confirmation>
          <multiple-application-removal-dialog-confirmation
              v-model:show-dialog="showDeleteDialog"
              v-model:checked-apps-count="checkedApps"
              @delete:applications="deleteAllCheckedCV()">
          </multiple-application-removal-dialog-confirmation>
          <export-application-data-confirmation-dialog
              v-model:show-dialog="showExportDialog"
              v-model:checked-apps-count="checkedApps"
              @export:applications="exportSelectedMailsToCsv()">
          </export-application-data-confirmation-dialog>
        </div>
        <menu-bar></menu-bar>
        <div class="row justify-between q-ml-sm" style="max-height: fit-content">
          <div class="row justify-start q-ma-sm items-center">
            <div>
              <q-btn clickable
                     class="bg-transparent"
                     size="sm"
                     text-color="grey-9"
                     no-caps
                     dense
                     flat
                     @click="router.push({ name: 'OrganizationJobOffersBoard' });"
                     label='Ogłoszenia'/>
              <q-icon color="grey-5" class="q-pt-none q-px-sm" size="10px" name="fa-solid fa-chevron-right"></q-icon>
            </div>
            <div>
              <q-btn clickable
                     size="sm"
                     flat
                     no-caps
                     dense
                     class=" text-grey-9 bg-transparent"
                     @click="router.push({ name: 'OrganizationJobOfferDetailsBoard', params: {id: router.currentRoute.value.params.id}});"
                     :label='jobOfferTitle'/>
              <q-icon color="grey-5" class="q-pt-none q-px-sm" size="10px" name="fa-solid fa-chevron-right"></q-icon>
            </div>
            <div>
              <q-btn
                  class="text-primary bg-transparent"
                  text-color="primary"
                  flat
                  size="sm"
                  dense
                  no-caps
                  label='Przesłane aplikacje'/>
            </div>
          </div>
          <div class="row q-pb-none q-gutter-md q-mx-lg q-mt-sm">
            <div>
              <q-checkbox
                  size="lg"
                  dense
                  color="positive"
                  v-model="isAccepted"
                  checked-icon="o_thumb_up"
                  unchecked-icon="o_thumb_up"
                  @click="updateAcceptedFilterValue()"
              />
            </div>
            <div>
              <q-checkbox
                  size="lg"
                  dense
                  color="orange"
                  v-model="isNone"
                  checked-icon="o_live_help"
                  unchecked-icon="o_live_help"
                  @click="updateUndecidedFilterValue()"

              />
            </div>
            <div>
              <q-checkbox
                  size="lg"
                  dense
                  class="q-pr-sm"
                  color="warning"
                  v-model="isRejected"
                  checked-icon="o_thumb_down"
                  unchecked-icon="o_thumb_down"
                  @click="updateRejectedFilterValue()"
              />
            </div>

          </div>
          <div>
            <q-input class="q-pr-md text-accent input-box q-my-md" color="primary" outlined
                     v-model="all"
                     label-color="primary"
                     bg-color="white"
                     popup-content-class="limiting"
                     stack-label
                     style="font-size: 5px; width: 100%"
                     use-input
                     dropdown-icon="sym_o_search"
                     clear-icon="cancel"
                     label="Szukaj wśród danych aplikantów i notatkach"
                     clearable
                     multiple
                     use-chips
                     input-debounce="0"
                     dense
                     @update:model-value="(value) => filterApplications(value)"
                     transition-show="scale"
                     transition-hide="scale"
            ></q-input>
          </div>
          <div class="col">
            <q-select class="q-pr-md text-accent input-box q-my-md" color="primary" outlined
                      v-model="sortingType"
                      label-color="primary"
                      bg-color="white"
                      popup-content-class="limiting"
                      stack-label
                      :options="['najnowsze','najstarsze']"
                      style="font-size: 5px;"
                      label="Sortuj"
                      dense
                      @update:model-value="(value) => updateSorting(value)"
                      transition-show="scale"
                      transition-hide="scale"
            ></q-select>
          </div>
          <div v-if="questionsForFilterModel.questions.length >0"
               class="q-mr-md">
            <q-btn class="q-px-md q-mt-md"
                   color="primary"
                   no-caps
                   :disabled="questionsForFilterModel.questions.length ===0"
                   style="height: 40px; border-radius: 7px"
                   v-model="sortingType"
                   label="Filtruj pytania"
                   @click="showQuestionFilterDialog=true"
                   dense
            ></q-btn>
          </div>
          <div class="q-mr-md">
            <q-btn class="q-px-md q-mt-md"
                   no-caps
                   color="accent"
                   style="height: 40px; border-radius: 7px"
                   label="Resetuj filtry"
                   @click="resetFilters()"
                   dense
            ></q-btn>
          </div>
        </div>

        <applications
            v-if="jobOfferId !==null"
            :job-offer-id="jobOfferId"
            v-model:key="applicationsComponentKey"
            v-model:filterText="filterText"
            v-model:sorting-type="sortingType"
            v-model:results-filter="resultFilterValue"
            v-model:questions-filter-params-dto="questionsFilterParamsDto"
            ref="applicationsChild"
            class="justify-center col row">
        </applications>
        <div class="row q-pl-md q-gutter-lg col-1" style="height: auto">
          <q-btn clickable square class="text-center text-white q-my-md" color="primary"
                 text-color="white"
                 icon="fa-solid fa-file-arrow-down"
                 size="11px"
                 no-caps
                 style="border-radius: 5px"
                 @click="showDialogForAppsDownload()"
                 label='Pobierz zaznaczone CV'/>
          <q-btn clickable square class="text-center text-white q-my-md" color="primary"
                 text-color="white"
                 icon="email"
                 size="11px"
                 no-caps
                 style="border-radius: 5px"
                 @click="openMultipleEmailsDialog()"
                 label='Napisz maila do zaznaczonych CV'/>
          <q-btn clickable square class="text-center text-white q-my-md" color="primary"
                 text-color="white"
                 icon="fa-solid fa-file-circle-xmark"
                 size="11px"
                 no-caps
                 style="border-radius: 5px"
                 @click="showDialogForAppsDelete()"
                 label='Usuń zaznaczone CV'/>
          <q-btn clickable square class="text-center text-white q-my-md" color="primary"
                 text-color="white"
                 icon="fa-solid fa-file-export"
                 size="11px"
                 no-caps
                 style="border-radius: 5px"
                 @click="showDialogForAppsExport()"
                 label='Exportuj dane do pliku'/>
        </div>
      </q-page>
    </q-page-container>
  </q-layout>
</template>
<script setup>
import {onMounted, reactive, ref} from 'vue'
import MenuBar from "@/components/common/MenuBar";
import Applications from "@/components/organization/applications/Applications";
import JobOfferService from "@/services/JobOfferService";
import MultipleDownloadDialogConfirmation
  from "@/components/organization/applications/MultipleDownloadDialogConfirmation";
import MultipleApplicationRemovalDialogConfirmation
  from "@/components/organization/applications/MultipleApplicationDeleteDialogConfirmation";
import MultipleEmailsDialog from "@/components/organization/applications/MultipleEmailsDialog";
import {useRouter} from "vue-router/dist/vue-router";
import ExportApplicationDataConfirmationDialog
  from "@/components/organization/applications/ExportApplicationDataConfirmationDialog";
import AuthenticationService from "@/services/AuthenticationService";
import QuesionFilter from "@/components/organization/applications/questions/QuesionFilter";
import questionFilterTranslator from "@/services/offer/questions/QuestionFilterTranslator";

const showQuestionFilterDialog = ref(false)
const isRejected = ref(false)
const isNone = ref(false)
const isAccepted = ref(false)
const sortingType = ref('najnowsze')
const router = useRouter()

const drawerLeft = ref(false)
const miniStateLeft = ref(true)

const jobOfferId = ref(null)
const jobOfferTitle = ref(null)
// const numberOfApplications = ref(null)
const applicationsChild = ref()
const filterText = ref('')
const questionsForFilterModel = reactive({
  questions: []
})
const questionsFilterParamsDto = ref([])

onMounted(() => {
  initPage();
})

function initPage() {
  JobOfferService.getJobOffer(router.currentRoute.value.params.id).then((response) => {
    response.json().then((data) => {
      let questions = []
      jobOfferTitle.value = data.title
      jobOfferId.value = data.id
      data.questions.forEach(question => {
        questions.push({
          questionId: question.id,
          question: question.question,
          answerType: question.answerType,
          operator: '=',
          search: null,

        });
      })
      questionsForFilterModel.questions = questions
    })
  });
}

const resultFilterValue = ref(null)

function updateAcceptedFilterValue() {
  isRejected.value = false
  isNone.value = false
  if (isAccepted.value) {
    resultFilterValue.value = "ACCEPTED"
  } else {
    resetFilters()
  }
  applicationsComponentKey.value = applicationsComponentKey.value + 1;
}

function updateRejectedFilterValue() {
  isAccepted.value = false
  isNone.value = false
  if (isRejected.value) {
    resultFilterValue.value = "REJECTED"
  } else {
    resetFilters()
  }
  applicationsComponentKey.value = applicationsComponentKey.value + 1;
}

function updateUndecidedFilterValue() {
  isAccepted.value = false
  isRejected.value = false
  if (isNone.value) {
    resultFilterValue.value = "UNDECIDED"
  } else {
    resetFilters()
  }
  applicationsComponentKey.value = applicationsComponentKey.value + 1;
}

const showDownloadsDialog = ref(false)

function showDialogForAppsDownload() {
  showDownloadsDialog.value = true
  getCheckedNumbers()
}

const checkedApps = ref(0)

function getCheckedNumbers() {
  return applicationsChild.value.getCheckedNumber().then((data) => {
    checkedApps.value = data;
  });
}

function downloadAllCheckedCV() {
  applicationsChild.value.downloadChecked()
}

const applicationsComponentKey = ref(0);
const showDeleteDialog = ref(false)
const showExportDialog = ref(false)

function showDialogForAppsDelete() {
  showDeleteDialog.value = true
  getCheckedNumbers()
}

function showDialogForAppsExport() {
  showExportDialog.value = true
  getCheckedNumbers()
}

const showMultipleEmailsDialog = ref(false)

function handleOpenMails(mailData) {
  const {subject, greetings, name, editor} = mailData;
  applicationsChild.value.getCheckedApplications()
      .then(data => {
        for (let i = 0; i < data.value.length; i++) {
          setTimeout(function () {
            const mailtoURL = `mailto:${data.value[i].applicantEmail}?subject=${subject}&body=${greetings + name + '\n' + data.value[i].applicantName + editor}`;
            const link = document.createElement('a');
            link.href = mailtoURL;
            link.target = '_blank';
            link.style.display = 'none';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }, 500 * i); // Delay each email client opening by multiplying the timeout with the loop index
        }
      });
}


// const globalEmailSubject = ref('')
// const globalEmailContent = ref('')
// function openMultipleEmailClient() {
//   let checkedApps = applicationsChild.value.getCheckedApplications();
//   for (let app of checkedApps) {
//     window.location.href = `mailto:${app.applicantEmail}?subject=${globalEmailSubject.value}&body=${globalEmailContent.value}`;
//   }
// }

async function deleteAllCheckedCV() {
  await applicationsChild.value.deleteChecked();
  applicationsComponentKey.value = applicationsComponentKey.value + 1;
}

function openMultipleEmailsDialog() {
  showMultipleEmailsDialog.value = true;
}

function filterApplications(value) {
  filterText.value = value
  applicationsComponentKey.value = applicationsComponentKey.value + 1;
}

function updateSorting(value) {
  sortingType.value = value
  applicationsComponentKey.value = applicationsComponentKey.value + 1;
}

function exportSelectedMailsToCsv() {
  applicationsChild.value.exportSelectedMailsToCsv();
}

function updateQuestionFiltering() {
  questionsFilterParamsDto.value = questionsForFilterModel.questions
      .filter(item => item.search !== null && item.search !== '')
      .map(item => {
        const newItem = {...item};
        if (newItem.operator) {
          newItem.operator = questionFilterTranslator.translateToModel(newItem.operator);
        }
        if (newItem.search === "tak") {
          newItem.search = "true"
        }
        if (newItem.search === "nie") {
          newItem.search = "false"
        }
        return newItem;
      });
  applicationsComponentKey.value = applicationsComponentKey.value + 1;

}

function resetFilters() {
  initPage()
  resultFilterValue.value = null
  unsetAllResultButtons();
  questionsFilterParamsDto.value = []
  applicationsComponentKey.value = applicationsComponentKey.value + 1;
}

function unsetAllResultButtons() {
  isAccepted.value = false
  isNone.value = false
  isRejected.value = false
}</script>

<style lang="scss">

.input-box {
  .q-field__control,
  .q-field__marginal {
    border-radius: 7px;
    height: 40px;
    font-size: 16px;
  }
}

.bg-my {
  background: linear-gradient(45deg, $primary, $secondary)
}

.bg-my-right {
  background: linear-gradient(180deg, $primary, $secondary)
}

.bg-my-bottom {
  background: linear-gradient(45deg, $secondary, $primary)
}

.input-box .q-field__control, .input-box .q-field__marginal {
  font-size: 13px;
}
</style>