<template>
  <q-layout>
    <q-drawer
        v-model="drawerLeft"
        show-if-above
        @mouseover="miniStateLeft = false"
        @mouseout="miniStateLeft = true"
        :breakpoint="500"
        :width="250"
        class="bg-gradient"
        bordered
    >
      <div>
        <div class="text-center q-mt-lg">
          <q-img fit="contain" :src="logo" style="width: 100px; height: 100px"/>
        </div>

        <div class="text-center">
          <my-text class="text-bold text-white" style="font-size: 30px;">Byte Job</my-text>
        </div>
      </div>

      <q-list class="column q-col-gutter-sm">
        <div class="q-mx-md q-mt-lg">
          <q-btn
              rounded
              @click="router.push({ name: 'ApplicantProfilePage' });"
              dense
              flat
              style="border-radius: 6px"
              size="15px"
              icon="mdi-account-edit"
              align="left"
              class="q-mx-md full-width text-white"
              color="primary"
              label="Mój profil"
              no-caps
          />
        </div>
        <div class="q-mx-md">
          <q-btn
              rounded
              @click="router.push({ name: 'ApplicantApplications' });"
              dense
              flat
              style="border-radius: 6px"
              size="15px"
              icon="mdi-briefcase-outline"
              align="left"
              class="q-mx-lg full-width text-white bg-dark"
              color="white"
              label="Rekrutacje"
              no-caps
          />
        </div>
        <div class="q-mx-md">
          <q-btn
              rounded
              @click="logout()"
              dense
              flat
              style="border-radius: 6px"
              size="15px"
              icon="mdi-logout"
              align="left"
              class="q-mx-md full-width text-white q-mt-lg"
              color="primary"
              label="Wyloguj"
              no-caps
          />
        </div>
        <div class="q-mx-md absolute-bottom q-mb-md">
          <q-btn
              rounded
              @click="ifRemoveAccount()"
              dense
              flat
              style="border-radius: 6px"
              size="15px"
              icon="fa-solid fa-user-xmark"
              align="left"
              class="q-mx-md full-width text-white q-mt-lg"
              color="primary"
              label="Usuń konto"
              no-caps
          />
        </div>
      </q-list>
    </q-drawer>
    <q-page-container style="background-color: rgb(217,225,238);">
      <q-page class="column items-center">
        <q-dialog v-model="noApplications" @before-hide="router.push({name: 'JobOffersMainBoard'})">
          <q-card style="width: 250px; border-radius: 9px;">
            <q-card-section class="row justify-center q-px-md q-pt-md q-pb-sm">
              <div class="text-center text-h7 text-grey-9">
                <div class="q-pt-none q-pb-sm q-mb-xs">
                  <q-icon size="md" name="fa-solid fa-circle-info" color="primary"></q-icon>
                </div>
                <span style="font-size: 12px">Brak aplikacji do wyświetlenia. Złóż CV na wybrane oferty pracy, aby mieć wgląd do historii aplikacji.</span>
              </div>
            </q-card-section>
            <div class="text-center q-mb-sm">
              <q-btn @click="router.push({name: 'JobOffersMainBoard'})" flat style="border-radius: 7px" class="q-px-sm"
                     dense label="Zobacz oferty pracy" no-caps
                     color="primary"></q-btn>
            </div>
          </q-card>
        </q-dialog>
        <menu-bar></menu-bar>
        <div class="col q-mt-lg row column" style="width: 60%">
          <application-filtering-and-sorting
              v-if="!noApplications"
              class="q-mb-lg q-px-md"
              @change:sorting="receiveFilterOffersEvent"
              @change:values="filterApplications"></application-filtering-and-sorting>
          <div v-if="isReady && applications.length > 0" class="q-scrollarea col q-mb-none"
               style="overflow-y: auto">
            <div v-for="application in applications" :key="application.id" class="full-width q-px-md">
              <application-overview
                  v-cloak
                  style="border-color: #cbcbcb"
                  :application="application"
                  class="bg-white full-width">
              </application-overview>
            </div>
          </div>
        </div>
        <account-removal-confirmation v-model:show-dialog="showAccountRemovalDialog"
                                      @remove:account="removeAccount()"></account-removal-confirmation>
      </q-page>
    </q-page-container>
  </q-layout>
</template>


<script setup>
import {onMounted, ref} from 'vue'

import {useRouter} from "vue-router/dist/vue-router";
import ApplicantDataService from "@/services/applicant/ApplicantDataService";
import AuthenticationService from "@/services/AuthenticationService";
import MenuBar from "@/components/common/MenuBar";
import logo from '@/assets/logo.png'; // Adjust the path as needed
import ApplicationService from "@/services/applicant/ApplicationService";
import ApplicationOverview from "@/components/applicant/applications/ApplicationOverview";
import ApplicationFilteringAndSorting from "@/components/applicant/applications/ApplicationFilteringAndSorting";
import AccountRemovalConfirmation from "@/components/applicant/profile/AccountRemovalConfirmation";
import {currentUser} from "@/global-variables/currentUser";

const router = useRouter()
const applications = ref([])


onMounted(async () => {
  let params = new URLSearchParams({})
  params.append('sort', "NEWEST")
  params.append('size', "30")
  await initAllApp(params)
})

const noApplications = ref(false)
const allApps = ref([])
const isReady = ref(false)
async function initAllApp(params) {
  applications.value = []
  let totalPages = 1;

  // Fetch the first page
  params.set('page', "0");
  let response = await ApplicationService.getApplications(params);
  let body = await response.json();
  totalPages = body.page.totalPages;
  if (body.page.totalElements === 0) {
    noApplications.value = true;
    return
  }
  isReady.value = true
  let allApplications = [];
  applications.value.push(...body.content)
  let promises = [];
  for (let pageNumber = 1; pageNumber < totalPages; pageNumber++) {
    let pageParams = new URLSearchParams(params);
    pageParams.set('page', pageNumber);
    let promise = ApplicationService.getApplications(pageParams)
        .then(response => response.json())
        .then(body => ({pageNumber: pageNumber, content: body.content}));
    promises.push(promise);
  }

  let results = await Promise.all(promises);
  results.forEach(result => {
    allApplications[result.pageNumber] = result.content;
  });
  applications.value.push(...allApplications.flat())
  allApps.value = applications.value;
}

function receiveFilterOffersEvent(sorting) {
  let params = new URLSearchParams({})
  params.append('sort', sorting)
  initAllApp(params)
}

function filterApplications(val) {
  applications.value = allApps.value
  if (!val) {
    applications.value = allApps.value
    return
  }

  val = val.toLowerCase();
  applications.value = applications.value.filter(application => {
    const organizationMatch = application.jobOffer.organization.name.toLowerCase().includes(val);
    const titleMatch = application.jobOffer.title.toLowerCase().includes(val);
    return organizationMatch || titleMatch;
  });
}

const showAccountRemovalDialog = ref(false)

function removeAccount() {
  showAccountRemovalDialog.value = false;
  let userId = currentUser.id
  ApplicantDataService.deleteAccount(userId)
      .then(() => {
        AuthenticationService.logout()
      })
}

function ifRemoveAccount() {
  showAccountRemovalDialog.value = true;
}

function logout() {
  AuthenticationService.logout()
}
</script>
<style lang="scss">
.q-btn--dense .on-left {
  margin-right: 22px;
  margin-left: 8px;
}
</style>